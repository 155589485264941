<template>
   <div class="calculator-frame">

        <h2>Calcul de la mensualité d'un emprunt</h2>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Type de d'emprunt</label>
                <select v-model="body.type">
                    <option v-for="(value, key) in $store.getters.debtTypes" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Mode de remboursement</label>
                <select v-model="body.refund_type">
                    <option v-for="(value, key) in $store.getters.refundTypes" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Montant emprunté</label>
                <input type="number" v-model="body.value">
            </div>

            <div class="datas-section">
                <label>Taux d'emprunt</label>
                <input type="number" v-model="body.rate">
            </div>

            <div class="datas-section">
                <label>Taux d'assurance</label>
                <input type="number" v-model="body.insurance_rate">
            </div>

            <div class="datas-section">
                <label>Durée de l'emprunt (en mois)</label>
                <input type="number" v-model="duration">
            </div>
        </div>

        <div class="separator"></div>

        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Mensualité d'emprunt</th>
                <td>{{ toEuro(computationResult.amortization_table[0]?.monthly_payment) }}</td>
            </tr>

            <tr>
                <th>Coût total du crédit</th>
                <td>{{ toEuro(computationResult.total_loan_cost) }}</td>
            </tr>

            <tr>
                <th>Coût des intérêts</th>
                <td>{{ toEuro(computationResult.interest_cost) }}</td>
            </tr>

            <tr>
                <th>Coût de l'assurance</th>
                <td>{{ toEuro(computationResult.insurance_cost) }}</td>
            </tr>
        </table>

        <h2>Tableau d'amortissement</h2>

        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Mois</th>
                <th>CRD début de période</th>
                <th>Mensualité</th>
                <th>Amortisation</th>
                <th>Intérêts</th>
                <th>Assurance</th>
                <th>CRD fin de période</th>
            </tr>

            <tr v-for="(month, index) in computationResult.amortization_table" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ toEuro(month.crd_before) }}</td>
                <td>{{ toEuro(month.monthly_payment) }}</td>
                <td>{{ toEuro(month.amortization) }}</td>
                <td>{{ toEuro(month.interest) }}</td>
                <td>{{ toEuro(month.insurance) }}</td>
                <td>{{ toEuro(month.crd_after) }}</td>
            </tr>
        </table>
   </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            duration: 240,
            body: {
                type: 'property_loan',
                value: 0,
                refund_type: "amortization",
                start_date: new Date(),
                end_date: undefined,
                rate: 4,
                insurance_rate: 0,
            },
            computationResult: undefined,
        }
    },
    watch: {
        duration() {
            let end_date = new Date()
            end_date.setMonth(end_date.getMonth() + this.duration)

            this.body.end_date = end_date
        },
        body: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-simulation', {
                ...this.body,
                rate: this.body.rate / 100,
                insurance_rate: this.body.insurance_rate / 100
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style scoped>
.calculation-datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.datas-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.datas-section label {
    font-size: 22px;
    font-weight: bold;
}

.datas-section input, select {
    font-size: 22px;
}

.calculation-result {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.calculation-result td, th {
    border: 1px solid lightgrey;
    padding: 10px;
}

</style>