<template>
    <table class="form-table">
        <tr>
            <td>Propriétaire</td>
            <td>Part (%)</td>
            <td>Droit de propriété</td>
        </tr>
        <tr v-for="(part, index) in detention_parts" :key="index">
            <td>
                <select v-model="detention_parts[index].owner_id">
                    <option v-for="person in $store.getters.getAllPersons" :key="person._id" :value="person._id">{{ person.name }}</option>
                </select>
            </td>

            <td>
                <input id="indivision_user" type="number" v-model="detention_parts[index].part"/>
            </td>

            <td>
                <select v-model="detention_parts[index].right">
                    <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
                </select>
            </td>

            <td @click="deleteIndivisionPart(index)">
                <span class="delete-indivision-part">X</span>
            </td>
        </tr>

        <tr>
            <td colspan=7 style="text-align: center">
                <div class="add-indivision-part" @click="addIndivisionPart">Ajouter une part</div>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: ['detention'],
    data() {
        return {
            detention_parts: [],
            receivedDatas: false
        }
    },
    methods: {
        addIndivisionPart() {
            this.detention_parts.push({ owner_id : undefined, right : this.right, part : 0 })
        },
        deleteIndivisionPart(index) {
            this.detention_parts.splice(index, 1)
        },
    },
    watch: {
        detention_parts: {
            deep: true,
            handler() {
                this.$emit('updated', this.detention_parts.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part / 100
                return copy
            }))
            }
        }
    },
    beforeUpdate() {
        if (this.detention && !this.receivedDatas) {
            this.detention_parts = this.detention.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part * 100
                return copy
            })
            this.receivedDatas = true
        }
    },
    beforeMount() {
        if (Object.keys(this.detention).length > 0 && !this.receivedDatas) {
            this.detention_parts = this.detention.map(part => {
                let copy = JSON.parse(JSON.stringify(part))
                copy.part = copy.part * 100
                return copy
            })
            this.receivedDatas = true
        }
    }
}
</script>

<style scoped>

.delete-indivision-part {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(178, 2, 2);
    color: white;
    border-radius: 3px;
}

.delete-indivision-part:hover {
    font-weight: bold;
}

.add-indivision-part {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
    background-color: orange;
    color: white;
    font-weight: bold;
    display: inline-block;
}

.add-indivision-part:hover {
    color: black;
    background-color: white;
}

</style>