<template>
    <section>
        <li>
            <span v-if="!addMode" @click="toggleAddMode">{{ label }}</span>

            <input type="text" v-model="newSectionBody.title" v-if="addMode"/>
            <span v-if="addMode" @click="toggleAddMode">Annuler</span>
            <span v-if="addMode" @click="createMainSection">Ajouter</span>
        </li>
        
    </section>
</template>

<script>
import axios from 'axios'

export default {
    props: ['parent_section'],
    data() {
        return {
            addMode: false,
            newSectionBody: {}
        }
    },
    methods: {
        toggleAddMode() {
            this.addMode = !this.addMode
        },
        async createMainSection() {
            if (!this.newSectionBody.title) {
                alert("Veuillez renseigner un titre");
            } else {
                await axios.post(this.$store.getters.get_api_url +'library/library-section/', this.newSectionBody, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.$emit('loadSections')
                this.toggleAddMode()
            } 
        },
    },
    computed: {
        label() {
            if (this.parent_section) {
                return 'Ajouter une sous-section'
            } else {
                return 'Ajouter un dossier thématique'
            }
        }
    },
    mounted() {
        if (!this.parent_section) {
            this.newSectionBody.title = ''
            this.newSectionBody.isMain = true
            this.newSectionBody.section = undefined
        } else {
            this.newSectionBody.title = ''
            this.newSectionBody.isMain = false
            this.newSectionBody.section = this.parent_section
        }
    }
}
</script>

<style scoped>
li {
    padding: 10px;
    font-size: 20px;
    list-style: none;
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
}

li span {
    cursor: pointer;
}
</style>