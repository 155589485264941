<template>
    <div class="auth-page">
        <div class="img-container">
            <img src="../assets/team-picture.png" alt="Equipe LBF">
        </div>

        <div class="auth-container">
            <div class="auth-box">
                <div class="auth-box-header">
                    Connexion
                </div>

                <div class="auth-box-form">
                    <section>
                        <label for="email">Adresse email :</label>
                        <input id="email" type="email" v-model="email">
                    </section>

                    <section>
                        <label for="password">Mot de passe :</label>
                        <input id="password" :type="passwordReadingMode" v-model="password">
                    </section>

                    <div class="input-options">
                        <label>Visible</label>
                        <input type="checkbox" v-model="show_password">
                    </div>

                    <div class="btn-container">
                        <div class="connexion-btn" @click="login">Entrer</div>
                    </div>

                    <p class="error-msg">{{errMsg}}</p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            email: "",
            password: "",
            errMsg: "",
            show_password: false,
        }
    },
    methods: {
        async login() {
            this.errMsg = ''
            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'admins/login/', {
                    email: this.email,
                    password: this.password,
                })

                axios.get(this.$store.getters.get_api_url + 'admins/getone/' + res.data.adminId, {
                    headers: { authorization : 'Bearer ' + res.data.token }
                }).then(admin => {
                    this.$store.commit('setAdmin', admin.data)
                })

                localStorage.setItem('token', res.data.token)
                localStorage.setItem('adminId', res.data.adminId)

                this.$store.commit('setAuth', res.data)

                this.$router.replace('/client')
            } catch(err) {
                if (err.response?.data?.message) {
                    this.errMsg = err.response.data.message
                } else {
                    this.errMsg = "Erreur lors de l'authentification"
                }
            } 
        }
    },
    computed: {
        passwordReadingMode() {
            if (this.show_password) {
                return "text"
            } else {
                return 'password'
            }
        }
    }
    
}
</script>

<style scoped>
.auth-page {
    display: flex;
    justify-content: space-between;
    height: 92vh;
}

.img-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.auth-container {
    position: absolute;
    top: 10%;
    left: 15%;
    width: 70%;
}

.img-container img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-box {
    position: relative;
    min-width: 50%;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.629);
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.auth-box-header {
    padding: 30px;
    background-color: #0a1c6b;
    color: white;
    font-size: 35px;
}

.auth-box-form {
    padding: 30px;
    font-size: 25px;
}

.auth-box-form section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

input {
    font-size: 25px;
}

.btn-container {
    display: flex;
    justify-content: center;
}
.connexion-btn {
    cursor: pointer;
    background-color: rgb(255, 136, 0);
    padding: 10px 20px;
    border-radius: 50px;
}
.connexion-btn:hover {
    background-color: rgb(249, 156, 50);
}

.input-options {
    margin: 20px;
    font-size: 18px;
}
.error-msg {
    color: red;
    font-size: 14px;
}


</style>