<template>
    <div class="choices-frame">
        <div v-for="(choice, key) in choices_list" :key="key" :class="{'selected-choice': key == selection}" class="choice" @click="selectOne(key)">
            <p>{{choice}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['choices', 'current'],
    emits: ['selectChange'],
    data() {
        return {
            choices_list: {},
            selection: undefined
        }
    },
    methods: {
        selectOne(key) {
            this.selection = key
            this.$emit('selectChange', this.selection)
        }
    },
    mounted() {
        this.choices_list = this.choices
        this.selection = this.current
    }
}
</script>

<style scoped>
.choices-frame {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.choice {
    cursor: pointer;
    margin: 10px;
    min-width: 20%;
    padding: 10px;
    background-color: white;
    color: black;
    text-align: center;
}
.choice:hover {
    background-color: rgba(255, 136, 0, 0.395); 
}
.selected-choice {
    background-color: rgb(255, 136, 0); 
    color: white;
}
.selected-choice:hover {
    background-color: rgb(255, 136, 0); 
    color: white;
}
</style>