<template>
    <div>
        <h2 class="category-title">Charges déductibles du revenu</h2>

        <div class="elements-container">
            <div class="element" v-for="asset in $store.getters.getAllTaxReductions" :key="asset._id">
                <h3>{{ $store.getters.reductionTypes[asset.type] }}</h3>
                <table>
                    <tr>
                        <td class="col1">Taux de réduction</td>
                        <td class="col2">{{ asset.reduction_rate * 100 }} %</td>
                    </tr>
                    <tr>
                        <td class="col1">Montant</td>
                        <td class="col2">{{ toEuro(asset.value) }}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-modify" @click="toggleModifyModale(asset._id)">MODIFIER</div>
                    <div class="btn btn-delete" @click="deleteItem(asset._id)">SUPPRIMER</div>

                    <modale :show="showModifyModale[asset._id]" @toggle="toggleModifyModale(asset._id)">
                        <TaxReduction :import="asset" @closeModale="toggleModifyModale(asset._id)"/>
                    </modale>
                </div>
            </div>

            <div class="add-btn" @click="toggleModale">
                <div>+</div>

                <modale :show="showModale" @toggle="toggleModale">
                    <TaxReduction @closeModale="toggleModale"/>
                </modale>
            </div>
        </div>
    </div>
</template>

<script>
import TaxReduction from '../../../forms/TaxReduction.vue'

export default {
    components: {TaxReduction},
    data() {
        return {
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm("Êtes vous sur de vouloir supprimer cette réduction d'impôt ?")
            if (valid) {
                this.$store.dispatch('delete_tax_reduction', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        }
    }
}
</script>

<style src="../style.css" scoped/>