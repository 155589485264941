<template>
    <div>
        <div class="header">
            <router-link class="btn-return" :to="'/client/' + $route.params.clientId + '/view'">Revenir au dossier</router-link>
        </div>

        <div class="profile-container">
            <div class="profile-menu">
                <div class="menu-category" @click="toggleFamily">Situation familiale</div>
                <ul class="menu-elements" v-if="showFamily">
                    <router-link to="couple"><li><span class="vignette">{{ $store.getters.numberUsers }}</span>Couple</li></router-link>
                    <router-link to="children"><li><span class="vignette">{{ $store.getters.numberChildren }}</span>Enfants</li></router-link>
                    <router-link to="grandchildren"><li><span class="vignette">{{ $store.getters.numberGrandChildren }}</span>Petits-enfants</li></router-link>
                    <router-link to="others"><li><span class="vignette">{{ $store.getters.numberOthers }}</span>Autres</li></router-link>
                </ul>

                <div class="menu-category" @click="toggleWealth">Situation Patrimoniale</div>
                <ul class="menu-elements" v-if="showWealth">
                    <router-link to="societies"><li><span class="vignette">{{ $store.getters.numberSocieties }}</span>Sociétés</li></router-link>
                    <router-link to="properties"><li><span class="vignette">{{ $store.getters.numberProperties }}</span>Immobilier</li></router-link>
                    <router-link to="assets"><li><span class="vignette">{{ $store.getters.numberAssets }}</span>Financier</li></router-link>
                    <router-link to="movables"><li><span class="vignette">{{ $store.getters.numberMovables }}</span>Mobilier</li></router-link>
                    <router-link to="insurances"><li><span class="vignette">{{ $store.getters.numberInsurances }}</span>Assurances</li></router-link>
                    <router-link to="debts"><li><span class="vignette">{{ $store.getters.numberDebts }}</span>Dettes</li></router-link>
                </ul>

                <div class="menu-category" @click="toggleDisposition">Dispositions</div>
                <ul class="menu-elements" v-if="showDisposition">
                    <router-link to="gifts"><li><span class="vignette">{{ $store.getters.numberGifts }}</span>Donations</li></router-link>
                    <router-link to="wills"><li><span class="vignette">{{ $store.getters.numberWills }}</span>Testaments</li></router-link>
                </ul>

                <div class="menu-category" @click="toggleTaxes">Revenus et impôts</div>
                <ul class="menu-elements" v-if="showtaxes">
                    <router-link to="incomes"><li><span class="vignette">{{ $store.getters.numberIncomes }}</span>Revenus</li></router-link>
                    <router-link to="tax-reductions"><li><span class="vignette">{{ $store.getters.numberTaxReductions }}</span>Réductions d'impôt</li></router-link>
                    <router-link to="tax-deductions"><li><span class="vignette">{{ $store.getters.numberTaxDeductions }}</span>Charges déductibles</li></router-link>
                </ul>

                <div class="menu-category" @click="toggleCareer">Carrière</div>
                <ul class="menu-elements" v-if="showCareer">
                    <router-link to="career-user"><li><span class="vignette">{{ $store.getters.numberTaxDeductions }}</span>{{ $store.getters.userName }}</li></router-link>
                    <router-link to="career-spouse"><li><span class="vignette">{{ $store.getters.numberTaxDeductions }}</span>{{ $store.getters.spouseName }}</li></router-link>
                </ul>

                <div class="menu-category" @click="toggleSimulation">Simulations & commande</div>
                <ul class="menu-elements" v-if="showSimulation">
                    <router-link to="legacy-simulation"><li>Simulation de succession</li></router-link>
                    <router-link to="ir-simulation"><li>Simulation d'impôt sur le revenu</li></router-link>
                    <router-link to="ifi-simulation"><li>Simulation d'impôt sur la fortune</li></router-link>
                </ul>

                <router-link to="simulation"><div class="menu-category" @click="toggleKyc"></div></router-link>
            </div>

            <div class="profile-view">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showFamily: false,
            showWealth: false,
            showDisposition: false,
            showtaxes: false,
            showCareer: false,
            showSimulation: false,
        }
    },
    methods: {
        toggleFamily() {
            this.showFamily = !this.showFamily
        },
        toggleWealth() {
            this.showWealth = !this.showWealth
        },
        toggleDisposition() {
            this.showDisposition = !this.showDisposition
        },
        toggleTaxes() {
            this.showtaxes = !this.showtaxes
        },
        toggleCareer() {
            this.showCareer = !this.showCareer
        },
        toggleSimulation() {
            this.showSimulation = !this.showSimulation
        },
    },
    mounted() {
        this.$store.dispatch('importScenario', this.$route.params.scenarioId)
        const routeArray = this.$route.fullPath.split('/')
        const current_page = routeArray[routeArray.length - 1]
        
        if (['couple', 'children', 'grandchildren', 'others'].includes(current_page)) {
            this.showFamily = true
        } else if (['societies', 'properties', 'assets', 'movables', 'insurances', 'debts'].includes(current_page)) {
            this.showWealth = true
        } else if (['gifts', 'wills'].includes(current_page)) {
            this.showDisposition = true
        } else if (['incomes', 'tax-reductions', 'tax-deductions'].includes(current_page)) {
            this.showtaxes = true
        } else if (['career-user', 'career-spouse'].includes(current_page)) {
            this.showCareer = true
        } else {
            this.showSimulation = true
        }
    }
}
</script>

<style scoped>
.header {
    height: 60px;
    background-color: #0a1c6b;
    padding: 0 10%;
}
.btn-return {
    color: orange;
    text-decoration: none;
}
.profile-container {
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.profile-menu {
    background-color: white;
    margin-top: -15px;
    padding: 5px 0;
    flex-basis: 20%;
    box-shadow: 1px 1px 5px 1px #d1d1d1;
    border-radius: 5px;
}
.menu-category {
    cursor: pointer;
    padding: 10px;
    background-color: rgb(243, 245, 247);
    font-size: 20px;
    font-weight: bold;
    color: #0a1c6b;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-elements {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: black;
}
.menu-elements li {
    cursor: pointer;
    font-size: 16px;
    padding: 5px 0 5px 25px;
    display: flex;
    align-items: center;
}
.menu-elements li:hover {
    background-color: rgb(243, 245, 247);
}
.router-link-exact-active li {
    background-color: rgba(255, 145, 0, 0.361);
}
.vignette {
    font-size: 12px;
    padding: 2px 10px;
    margin-right: 10px;
    background-color: #0a1c6b;
    color: white;
    border-radius: 50px;
}
.profile-view {
    background-color: rgb(250,250,250);
    margin-top: -15px;
    flex-basis: 75%;
    padding: 20px;
    min-height: 600px;
    border-radius: 5px;
}
</style>