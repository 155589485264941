<template>
    <div class="page-container">
        <h2 class="category-title">Carrière professionnelle</h2>

        <div class="btn-ris">Importer un RIS</div>

        <div class="start-date">
            <h4>Date de début de carrière</h4>
            <input type="date" v-model="body.startDate">
        </div>
        
        <table class="career-table">
            <tr>
                <th>Emploi</th>
                <th>Tns</th>
                <th>Premier salaire</th>
                <th>Début</th>
                <th>Fin</th>
                <th>Modifier</th>
            </tr>
            <tr v-for="career in body.careerList" :key="career._id">
                <td>{{ career.label }}</td>
                <td> 
                    <input type="checkbox" v-model="body.is_tns" disabled>
                </td>
                <td>{{ toEuro(career.first_wage) }}</td>
                <td>{{ dateToString(career.start_date) }}</td>
                <td>{{ dateToString(career.end_date) }}</td>
                <td>
                    <edit-icon/>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {
            body: {
                startDate: '1998-02-01',
                careerList: [{
                    _id: 1,
                    start_date: '1998-02-01',
                    end_date: '2023-02-01',
                    activity: 'employee',
                    label: "Employé chez IKEA",
                    is_tns: false,
                    first_wage: 20000,
                    value_type: 'net',
                    period_type: 'yearly',
                    wage_progression: 'low',
                    is_partial_time: false,
                }]
            },
            showCreateModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        }
    },
    mounted() {
        const routeArray = this.$route.fullPath.split('/')
        const current_page = routeArray[routeArray.length - 1]

        if (current_page == 'career-user') {
            this.body = this.$store.getters.getUserCareer
        } else if (current_page == 'career-spouse') {
            this.body = this.$store.getters.getSpouseCareer
        }
    }
}
</script>

<style scoped>
.page-container {
    position: relative;
}
.btn-ris {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;
    background-color: rgb(255, 136, 0);
    padding: 15px;
    border-radius: 10px;
    color:white;
    font-weight: bold;
}
.btn-ris:hover {
    background-color: rgba(255, 136, 0, 0.695);
}
.category-title {
    color: rgb(255, 136, 0);
}
.start-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.career-table {
    width: 100%;
    border-collapse: collapse;
}

.career-table th {
    color: white;
    background-color: rgb(2, 2, 100);
    border: 1px solid rgb(243, 245, 247);
}

.career-table td {
    background-color: white;
    border: 1px solid rgb(243, 245, 247);
    text-align: center;
}

</style>