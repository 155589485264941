<template>
    <div class="page-container">
        <h3>Liste des solutions</h3>
        <div class="add-btn" @click="toggleForm">Ajouter</div>

        <div class="research-frame">
            <input type="text" v-model="researchString">
        </div>

        <div class="section" v-for="section in sections" :key="section._id">
            <h4>{{ section.title }}</h4>

            <div class="items-list">
                <ListItem
                    v-for="solution in solutions.filter(s => s.sectionId == section._id)" :key="solution._id"
                    :item="solution"
                    type="solution"
                    @toggleForm="loadSolutions"
                />
            </div>
        </div>

        <modale :show="showForm" @toggle="toggleForm">
            <SolutionForm @closeModale="toggleForm"/>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

import ListItem from '../components/ListItem.vue'
import SolutionForm from '../components/SolutionForm.vue'
export default {
    components: {
        ListItem,
        SolutionForm,
    },
    data() {
        return {
            researchString: "",
            showForm: false,
            solutions: [],
            sections: [],
        }
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm
            this.loadSolutions()
        },
        async loadSections() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/sections/solution', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.sections = result.data
        },
        async loadSolutions() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/solutions/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.solutions = result.data
        },
        async searchSolutions() {
            if (this.researchString == "") {
                this.loadSolutions()
            } else {
                const result = await axios.post(this.$store.getters.get_api_url +'referential/solutions-research/', {
                    research: this.researchString
                } , {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.solutions = result.data
            }
        }
    },
    watch: {
        researchString() {
            this.searchSolutions()
        }
    },
    mounted() {
        this.loadSections()
        this.loadSolutions()
    }
}
</script>

<style scoped src="../Style.css"></style>