<template>
    <div v-if="show">    
        <teleport to="body">
            <div class="overlay" @click="toggleModale"></div>
            <div class="modale">
                <div class="close">
                    <span class="grow-effect" @click="toggleModale">X</span> 
                </div>

                <slot></slot>
            </div>
        </teleport>
    </div>
</template>

<script>
export default {
    props: ['show'],
    methods: {
        toggleModale() {
            this.$emit('toggle')
        }
    }
}
</script>

<style scoped>
.overlay {
    z-index: 1999;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.701);
}
.modale {
    z-index: 2000;
    position: fixed;
    top: 100px;
    right: 0;
    left: 0;
    max-height: 80%;
    overflow-y: scroll;
    width: 50%;
    margin: auto;
    background-color: white;
    border-radius: 10px;
}
.close span {
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: lightgrey;
    border-radius: 10px;
}
.close span:hover {
    background-color: rgb(190, 1, 1);
    color: white;
}
</style>