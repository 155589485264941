<template>
    <div>
        <h3>Estimation de l'impôt sur la fortune</h3>

        <WealthTaxesResult/>
    </div>
</template>

<script>
import WealthTaxesResult from '../../../results/WealthTaxes.vue'
export default {
    components: {
        WealthTaxesResult,
    }
}
</script>

<style scoped>

</style>