<template>
    <form>
        <div class="form-section">
            <label for="owner">Propriétaire</label>
            <select id="owner" v-model="owner">
                <option v-for="(value, index) in owners_list" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="owner != undefined && action == 'asv_payment'">
            <label for="owner">Assurance vie</label>
            <select id="owner" v-model="selectedLifeInsurance">
                <option v-for="(insurance, index) in $store.getters.getPersonLifeInsurances(owner)" :key="index" :value="insurance._id">{{ insurance.label || $store.getters.insuranceTypes[insurance.type] }} - {{ $store.getters.findPersonName(insurance.owner1) }} - {{ toEuro(insurance.value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Montant du versement</label>
            <input id="value" type="number" v-model="value"/>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['action'],
    data() {
        return {
            owner: undefined,
            value: 0,
            selectedLifeInsurance: undefined,
            errorMsg: "",
        }
    },
    methods: {
        async asv_creation() {
            let payment_after70 = 0
            if (this.$store.getters.findPersonAge(this.owner) > 69) {
                payment_after70 = this.value
            }

            let ranks = []

            if (this.$store.getters.spouseId) {
                ranks.push({
                    beneficiaries: [this.$store.getters.getPersonSpouse(this.owner)],
                    representation: false,
                    repartition: false,
                    order: 1,
                    same_rank: false, 
                    repartition_detail: undefined,
                })
            }

            ranks.push({
                beneficiaries: this.$store.getters.getPersonChildren(this.owner),
                representation: true,
                repartition: false,
                order: 2,
                same_rank: false, 
                repartition_detail: undefined,
            })

            const body = {
                clientId: this.$route.params.clientId,
                scenarioId: this.$route.params.scenarioId,
                label: undefined,
                type: 'life',
                owner1: this.owner,
                owner2: undefined,
                opening_date: this.getCurrentDate(),
                value: this.value,
                payment_monthly: 0,
                total_payment: this.value,
                payment_after70: payment_after70,
                payment_before1998: 0,
                clause: {
                    dismemberment: false,
                    default: 1,
                    ranks: ranks,
                },
                isIpCreation: true,
                addToStore: true,
            }

            if (this.value < 1) {
                this.errorMsg = "Le montant du versement doit être supérieur à 0"
            } else if (!this.owner) {
                this.errorMsg = "Séléctionnez un souscripteur"
            } else {
                await this.$store.dispatch('create_insurance', body)
                this.$emit('closeModale')
            }
            
        },
        async asv_payment() {
            if (this.value < 1) {
                this.errorMsg = "Le montant du versement doit être supérieur à 0"
            } else if (!this.owner) {
                this.errorMsg = "Séléctionnez un souscripteur"
            } else if (!this.selectedLifeInsurance) {
                this.errorMsg = "Séléctionnez une assurance vie à modifier"
            } else {
                let body = JSON.parse(JSON.stringify(this.$store.getters.getAllLifeInsurances.find(i => i._id == this.selectedLifeInsurance)))

                body.value += this.value
                body.total_payment += this.value
                
                if (this.$store.getters.findPersonAge(this.owner) > 69) {
                    body.payment_after70 += this.value
                }

                await this.$store.dispatch('action_modify_insurance', body)
                this.$emit('closeModale')
            }
            
        },
        async financial_creation() {
            if (!this.owner) {
                this.errorMsg = "Choisissez un propriétaire"

            } else if (this.value < 1) {
                this.errorMsg = "Le montant du versement doit être supérieur à 0"

            } else {
                const TRAD_CATEGORY = {
                    pea_creation: "bank",
                    capi_creation: "bank",
                    csl_creation: "bank",
                    cat_creation: "bank",
                    fcpi_creation: "fiscal_fund",
                    fip_creation: "fiscal_fund",
                    fcpr_creation: "fiscal_fund",
                }

                const TRAD_TYPE = {
                    pea_creation: "pea",
                    capi_creation: "capi",
                    csl_creation: "csl",
                    cat_creation: "cat",
                    fcpi_creation: "fcpi",
                    fip_creation: "fip",
                    fcpr_creation: "fcpr",
                }

                let detention = []
                if (this.owner == 'commun') {
                    detention = [ 
                        { owner_id: this.$store.getters.userId, part: 0.5, right: 'pp' },
                        { owner_id: this.$store.getters.spouseId, part: 0.5, right: 'pp' },
                    ]
                } else {
                    detention = [ 
                        { owner_id: this.owner, part: 1, right: 'pp' },
                    ]
                }

                const body = {
                    clientId     : this.$route.params.clientId,
                    scenarioId   : this.$route.params.scenarioId,
                    category     : TRAD_CATEGORY[this.action],
                    type         : TRAD_TYPE[this.action],
                    value        : this.value,
                    payment      : this.value,
                    isIndivision : false,
                    detention    : detention,
                    opening_date : new Date(),
                    linkedSociety: undefined,
                    isIpCreation : true,
                    addToStore   : true,
                }
                
                await this.$store.dispatch('create_asset', body)
                this.$emit('closeModale')
            }
        },
        submit() {
            if (this.action == 'asv_creation') {
                this.asv_creation()

            } else if (this.action == 'asv_payment') {
                this.asv_payment()

            } else if (['pea_creation'].includes(this.action)) {
                this.financial_creation()
            }
        }
    },
    computed: {
        owners_list() {
            if (['asv_creation', 'asv_payment'].includes(this.action)) {
                return this.$store.getters.findOwners.filter(o => o != 'commun')
            } else {
                return this.$store.getters.findOwners
            }
        }
    },
}
</script>

<style src="./form_styles.css" scoped></style>