<template>
    <section v-if="currentWill">

        <div v-if="currentWill.type == 'universal'">
            <p>
                <span>Un testament a été rédigé, il prévoit que </span>

                <span v-for="personId in currentWill.univeral_detail.ranks[0]" :key="personId">
                    {{ $store.getters.findPersonName(personId) }},
                </span>

                <span>soit désigné comme légataire universel en {{ $store.getters.rights[currentWill.univeral_detail.right] }}.</span>
            </p>
        </div>

        <div v-else>
            <p>Un testament a été rédigé, il prévoit les legs suivants : </p>

            <table>
                <tr>
                    <th>Legs</th>
                    <th>Valeur</th>
                    <th>Légataires</th>
                    <th>Droit de propriété</th>
                </tr>

                <tr v-for="(legs, index) in currentWill.legs" :key="index">
                    <td>{{ formatType(existingGoodsList.find(a => a._id == legs.assetId).type) }}</td>
                    <td>{{ toEuro(existingGoodsList.find(a => a._id == legs.assetId).value) }}</td>
                    <td>{{ formatRanks(legs.ranks) }}</td>
                    <td>{{ $store.getters.rights[legs.right] }}</td>
                </tr>
            </table>
        </div>

        <p v-if="reserveesList.length > 0">En présence d'héritiers réservataires, il faudra vérifier que les réserves ne sont pas atteintes et, le cas échéant, calculer les indemnités de réduction payable en valeur.</p>
        
    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'datasFromOrder', 'isSecondDeath', 'deceasedId'],
    data() {
        return {}
    },
    computed: {
        currentWill() {
            return this.datasFromOrder.wills.find(w => w.owner == this.deceasedId)
        },
        existingGoodsList() {
            if (this.isSecondDeath) {
                return this.legacyResult.liquidation?.spouse_existing_goods_list
            } else {
                return this.legacyResult.liquidation?.user_existing_goods_list
            }
        },
        reserveesList() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.inheritors.filter(i => i.reserve > 0)
            } else {
                return this.legacyResult.first_death.inheritors.filter(i => i.reserve > 0)
            }
        },
    },
}
</script>

<style scoped src="./style.css"></style>