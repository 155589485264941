<template>
    <div class="referential-frame">
        <h2 class="page-title">Référentiel</h2>
        <div class="separator"></div>

        <div class="menu">
            <router-link to="/referential/solutions">Liste des solutions</router-link>
            <router-link to="/referential/reminders">Liste des rappels</router-link>
            <router-link to="/referential/reminder-section">Sections de Bilan</router-link>
            <router-link to="/referential/solution-section">Sections de Solutions</router-link>
            <router-link to="/referential/conclusion-section">Sections de conclusion</router-link>
        </div>

        <router-view></router-view>

    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.referential-frame {
    margin: 20px 20%;
}
.page-title {
    color: rgb(0, 0, 99);
    font-size: 34px;
    margin: 0;
}

.menu {
    margin-top: 20px;
    margin-bottom: 20px;
}

.menu a {
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 99);
    text-decoration: none;
    padding: 10px 20px;
    border-bottom: 1px solid lightgrey;
}
.menu .router-link-active {
    color: black;
    border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 0px solid lightgrey;
    border-radius: 5px 5px 0 0;
}

</style>