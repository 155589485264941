<template>
    <div class="event-frame" v-if="event.type">
        <select v-model="type">
            <option v-for="(type, key) in event_types" :key="key" :value="key">{{type}}</option>
        </select>
        <input class="input-title" type="text" v-model="title">
        
        <h5>{{ dateToString(event.date) }}</h5>
        
        <text-editor
            id="text" 
            v-model="text"
            height="400"
        />

        <div class="btn-save" @click="saveModifications">Enregistrer</div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['event'],
    data() {
        return {
            text: '',
            title: '',
            type: '',
            event_types: {
                call: 'Appel',
                email: 'Email',
                meeting: 'Rendez-vous',
                visio: 'Visio',
                event: 'Evènement',
                action: "Action",
            },
        }
    },
    methods: {
        async saveModifications() {
            let body   = this.event
            body.text  = this.text
            body.title = this.title
            body.type  = this.type
            await axios.put(this.$store.getters.get_api_url + 'crm/event/' + this.event._id, body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        }
    },
    watch: {
        'event.text': {
            handler() {
                this.text = this.event.text
            }
        },
        'event.title': {
            handler() {
                this.title = this.event.title
            }
        },
        'event.type': {
            handler() {
                this.type = this.event.type
            }
        },
    }
}
</script>

<style scoped>
.event-frame {
    padding: 1%;
    height: 91%;
}
.btn-save {
    cursor: pointer;
    margin: 10px auto;
    display: inline-block;
    text-align: center;
    padding: 10px;
    background-color: rgb(3, 3, 95);
    color: white;
}
.btn-save:hover {
    background-color: rgb(255, 166, 0);
}
.input-title {
    min-width: 500px;
}
</style>