import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'


const module = {
    state () {
        return state
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
}

export default module