<template>
    <section>
        <li class="title" @click="toggleShowDetail">
            <div class="indicator" :class="{'indicator-open': showDetail}"></div>
            <span>{{section.title}}</span>
        </li>

        <section v-if="showDetail">
            <ol v-if="type == 'SectionParent'">
                <draggable class="dragArea list-group w-full" :list="children" @change="savePositions">
                    <admin-section
                        v-for="section in children" :key="section._id"
                        :sectionId="section._id"
                        :sectionArray="sectionArray"
                        :textArray="textArray"
                        @loadSections="loadSections"
                    />
                </draggable>
            </ol>

            <ol v-else-if="type == 'TextParent'">
                <draggable class="dragArea list-group w-full" :list="children" @change="savePositions">
                    <section v-for="text in children" :key="text._id">
                        <AdminText
                            :textObject="text"
                        />
                    </section>
                </draggable>
            </ol>

            <ol>
                <AddSection
                    v-if="type != 'TextParent'"
                    @loadSections="loadSections"
                    :parent_section="sectionId"
                />

                <AddText
                    v-if="type != 'SectionParent'"
                    @loadText="loadText"
                    :parent_section="sectionId"
                />
            </ol>
        </section>
            
    </section>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'

import AdminSection from './AdminSection.vue'
import AdminText from './AdminText.vue'
import AddSection from './AddSection.vue'
import AddText from './AddText.vue'

export default defineComponent({
    name: 'admin-section',
    props: ['sectionId', 'sectionArray', 'textArray'],
    components: {
        draggable: VueDraggableNext,
        'admin-section': AdminSection,
        AdminText,
        AddSection,
        AddText,
    },
    data() {
        return {
            showDetail: false,
        }
    },
    methods: {
        loadSections() {
            this.$emit('loadSections')
        },
        loadText() {
            this.$emit('loadText')
        },
        toggleShowDetail() {
            this.showDetail = !this.showDetail
        },
        async savePositions(event) {
            let children = JSON.parse(JSON.stringify(this.children))
            const moved_item = event.moved.element
            const original_position = event.moved.oldIndex + 1
            const new_position = event.moved.newIndex + 1
            let modifiedArray = []
            
            children.forEach(child => {
                if (child._id == moved_item._id) {
                    child.position = event.moved.newIndex + 1
                    modifiedArray.push(child)

                } else {
                    const diff = original_position - child.position

                    if (diff > 0 && new_position <= child.position) {
                        child.position += 1
                        modifiedArray.push(child)

                    } else if (diff < 0 && new_position >= child.position) {
                        child.position -= 1
                        modifiedArray.push(child)
                    }
                }
            })

            if (this.type == 'SectionParent') {
                for (let i = 0; i < modifiedArray.length; i++) {
                    const child = modifiedArray[i]
                    await axios.put(this.$store.getters.get_api_url +'library/library-section/' + child._id, child, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                }
                this.loadSections()

            } else if (this.type == 'TextParent') {
                for (let i = 0; i < modifiedArray.length; i++) {
                    const child = modifiedArray[i]
                    await axios.put(this.$store.getters.get_api_url +'library/library-text/' + child._id, child, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                }
                this.loadText()
            }
            
        },
    },
    computed: {
        section() {
            return this.sectionArray.find(s => s._id == this.sectionId)
        },
        nested_texts() {
            return this.textArray.filter(t => t.sectionId  == this.sectionId).sort((a, b) => (a.position > b.position ? 1 : -1))
        },
        nested_sections() {
            return this.sectionArray.filter(s => s.section  == this.sectionId).sort((a, b) => (a.position > b.position ? 1 : -1))
        },
        type() {
            if (this.nested_texts.length > 0) {
                return 'TextParent'

            } else if (this.nested_sections.length > 0) {
                return 'SectionParent'

            } else {
                return undefined
            }
        },
        children() {
            if (this.nested_texts.length > 0) {
                return this.nested_texts

            } else if (this.nested_sections.length > 0) {
                return this.nested_sections

            } else {
                return []
            }
        },
    },
})

</script>

<style scoped>
.title {
    cursor: pointer;
    display: flex;
}
.indicator {
    margin: 5px 10px;
    height: 10px;
    width: 10px;
    border-top: solid black 4px;
    border-right: solid black 4px;
    transform: rotate(45deg);
}
.indicator-open {
    transform: rotate(135deg);
}
li {
    margin-top: 3px;
    border-radius: 5px;
    list-style: none;
    padding: 10px 10px;
    font-size: 22px;
    background-color: white;
    
}
</style>