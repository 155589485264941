<template>
    <svg :width="dimensionDefault" :height="dimensionDefault" viewBox="0 0 38 38" fill="none">
        <path
        d="M19 8.75C17.7021 8.75 16.4333 9.14405 15.3541 9.88231C14.2749 10.6206 13.4337 11.6699 12.937 12.8976C12.4403 14.1253 12.3104 15.4762 12.5636 16.7795C12.8168 18.0828 13.4418 19.28 14.3596 20.2196C15.2774 21.1593 16.4467 21.7992 17.7197 22.0584C18.9927 22.3176 20.3122 22.1846 21.5114 21.6761C22.7105 21.1675 23.7354 20.3064 24.4565 19.2015C25.1776 18.0966 25.5625 16.7976 25.5625 15.4688C25.5625 13.6868 24.8711 11.9779 23.6404 10.7179C22.4097 9.45787 20.7405 8.75 19 8.75ZM19 19.5C18.2212 19.5 17.46 19.2636 16.8124 18.8206C16.1649 18.3777 15.6602 17.7481 15.3622 17.0114C15.0642 16.2748 14.9862 15.4643 15.1382 14.6823C15.2901 13.9003 15.6651 13.182 16.2158 12.6182C16.7664 12.0544 17.468 11.6705 18.2318 11.515C18.9956 11.3594 19.7873 11.4392 20.5068 11.7444C21.2263 12.0495 21.8413 12.5662 22.2739 13.2291C22.7066 13.892 22.9375 14.6714 22.9375 15.4688C22.9365 16.5376 22.5213 17.5623 21.7831 18.3181C21.0449 19.0739 20.044 19.4989 19 19.5Z"
        fill="currentColor"
        ></path>
        <path
        d="M19 0.6875C15.3658 0.6875 11.8132 1.79083 8.7914 3.85798C5.76965 5.92512 3.41448 8.86323 2.02372 12.3008C0.632962 15.7383 0.269075 19.5209 0.978079 23.1701C1.68708 26.8194 3.43713 30.1715 6.00692 32.8024C8.57671 35.4334 11.8508 37.2251 15.4152 37.951C18.9796 38.6769 22.6742 38.3044 26.0318 36.8805C29.3894 35.4566 32.2592 33.0454 34.2783 29.9517C36.2973 26.858 37.375 23.2208 37.375 19.5C37.3694 14.5124 35.4317 9.73063 31.987 6.20384C28.5422 2.67704 23.8717 0.69319 19 0.6875ZM11.125 33.4441V31.5938C11.1261 30.5249 11.5412 29.5002 12.2794 28.7444C13.0176 27.9886 14.0185 27.5636 15.0625 27.5625H22.9375C23.9815 27.5636 24.9824 27.9886 25.7206 28.7444C26.4588 29.5002 26.874 30.5249 26.875 31.5938V33.4441C24.4852 34.8728 21.7674 35.6257 19 35.6257C16.2326 35.6257 13.5149 34.8728 11.125 33.4441ZM29.4895 31.4943C29.4634 29.7318 28.7619 28.0503 27.5364 26.8121C26.3108 25.574 24.6593 24.8783 22.9375 24.875H15.0625C13.3407 24.8783 11.6892 25.574 10.4636 26.8121C9.23808 28.0503 8.53667 29.7318 8.51051 31.4943C6.13037 29.3184 4.4519 26.4537 3.69735 23.2795C2.9428 20.1053 3.14776 16.7713 4.2851 13.719C5.42243 10.6667 7.43848 8.04005 10.0663 6.18689C12.6941 4.33372 15.8098 3.34144 19.0007 3.34144C22.1916 3.34144 25.3072 4.33372 27.935 6.18689C30.5628 8.04005 32.5789 10.6667 33.7162 13.719C34.8536 16.7713 35.0585 20.1053 34.304 23.2795C33.5494 26.4537 31.871 29.3184 29.4908 31.4943H29.4895Z"
        fill="currentColor"
        ></path>
    </svg>    
</template>

<script>
export default {
    props: ['dimensions'],
    computed: {
        dimensionDefault() {
            if (this.dimensions) {
                return this.dimension
            } else {
                return 54
            }
        }
    }
}
</script>