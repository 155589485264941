<template>
    <div class="client-list-frame">
        <h1 class="title">Mes clients</h1>

        <div class="btn-add" @click="toggleModale">Ajouter un client</div>

        <div class="filters-frame">
            <div class="filters">
                <div class="filter">
                    <label>Cabinet</label>
                    <input type="text" v-model="filters.society">
                </div>

                <div class="filter">
                    <label>Nom</label>
                    <input type="text" v-model="filters.lastname">
                </div>

                <div class="filter">
                    <label>Prénom</label>
                    <input type="text" v-model="filters.firstname">
                </div>

                <div class="filter">
                    <label>Code postal</label>
                    <input type="text" v-model="filters.zipcode">
                </div>

                <div class="filter">
                    <label>Status</label>
                    <select v-model="filters.status">
                        <option v-for="(value, key) in  status_list" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="filter">
                    <label>Facture des honoraires</label>
                    <select v-model="filters.hasFees">
                        <option v-for="(value, key) in  booleanChoices" :key="key" :value="value">{{ key }}</option>
                    </select>
                </div>

                <div class="filter">
                    <label>Intéressé par</label>
                    <select v-model="filters.interestedBy">
                        <option v-for="(value, key) in  interest_list" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>
            </div>

            <div class="buttons">
                <div class="btn-erase-filters" @click="eraseFilters">Effacer les filtres</div>
                <div class="btn-apply-filters" @click="getClients">Rechercher</div>
            </div>
        </div>

        <table id="clients-table">
            <tr class="client" v-for="client in clients" :key="client._id">
                <td class="firstname">{{ client.firstname }}</td>
                <td class="lastname">{{ client.lastname }}</td>
                <td class="society">{{ client.name }}</td>
                <td class="creation_date">{{ dateToString(client.creationDate) }}</td>
                <td class="btn-client">
                    <button>
                        <router-link :to="'/conseillers/crm/' + client._id">Accéder</router-link>
                    </button>
                </td>
            </tr>
        </table>

        <div class="pages-frame">
            <p>{{ start }} à {{ Math.min(start + 10, nbResult) }} sur {{ nbResult }}</p>
            <div class="pages">
                <div class="page" v-for="(page, index) in pagesIndex" :key="index" @click="updateStart(page)">
                    {{ page }}
                </div>
            </div>
        </div>
        
        <modale :show="showModale" width="60%" @toggle="toggleModale">
            <form class="form-add-new">
                <label>Nom du cabinet :</label>
                <input type="text" v-model="society.name">

                <label>Adresse :</label>
                <input type="text" v-model="society.adress">

                <label>Code postal :</label>
                <input type="text" v-model="society.zipcode">

                <label>Ville :</label>
                <input type="text" v-model="society.city">

                <label>Prénom du dirigeant :</label>
                <input type="text" v-model="director.firstname">

                <label>Nom du dirigeant :</label>
                <input type="text" v-model="director.lastname">

                <label>Téléphone :</label>
                <input type="text" v-model="director.phone1">

                <label>Email :</label>
                <input type="text" v-model="director.email1">
            </form>

            <div class="btn-save" @click="saveNew">Enregistrer</div>
            <p>{{ msg }}</p>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            start: 0,
            nbResult: 0,
            clients: [],
            showModale: false,
            society: {
                name          : "",
                adress        : "",
                zipcode       : "",
                city          : "",
                creationDate : new Date()
            },
            director: {
                    societyId      : undefined,
                    firstname      : "",
                    lastname       : "",
                    phone1         : "",
                    email1         : "",
            },
            booleanChoices: {
                '--': undefined,
                oui: true,
                non: false
            },
            status_list: {
                0: 'Cabinet non créé',
                1: 'Non contacté',
                2: '1er contact',
                3: 'Commande imminente',
                4: 'Cient',
                5: 'Client récurrent',
                6: 'Non interéssé',
            },
            interest_list: {
                timeSaving: 'Gagner du temps',
                interestedBuyPrice : 'Tarif',
                leaveCompetitor: 'Remplacer un concurrent',
                technicalSupport: 'Support technique',
                coConstruction: 'Co-construction'
            },
            filters: {
                society: undefined,
                firstname: undefined,
                lastame: undefined,
                zipcode: undefined,
                status: undefined,
                hasFees: undefined,
                interestedBy: undefined,
            },
            msg: ''
        }
    },
    methods: {
        toggleModale(){
            this.showModale = !this.showModale
        },
        updateStart(pageIndex) {
            this.start = pageIndex * 10 - 10
            this.getClients()
        },
        async saveNew() {
            const societyCreation = await axios.post(this.$store.getters.get_api_url +'crm/society/', this.society, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.director.societyId = societyCreation.data
            console.log(societyCreation)
            
            if (societyCreation.status != 201) {
                this.msg = 'Erreur création cabinet'
            } else {
                const directorCreation = await axios.post(this.$store.getters.get_api_url +'crm/director/', this.director, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                if (directorCreation.status != 201) {
                    this.msg = "Erreur création directeur"
                } else {
                    this.getClients()
                    this.toggleModale()
                }
            }
        },
        async getClients() {

            const countResponse = await axios.post(this.$store.getters.get_api_url + "crm/society-research?count=true", this.filters, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.nbResult = countResponse.data

            const societiesResponse = await axios.post(this.$store.getters.get_api_url + "crm/society-research?start=" + this.start + "&max=10", this.filters, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            let societies = societiesResponse.data

            const directorsResponse = await axios.post(this.$store.getters.get_api_url +'crm/get-directors', { societyIds: societies.map(s => s._id) }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            const directors = directorsResponse.data

            societies.forEach(society => {
                const director = directors.filter(d => d.societyId == society._id)[0]
                society.firstname = director?.firstname
                society.lastname = director?.lastname
            });

            this.clients = societies
        },
        eraseFilters() {
            this.start = 0
            this.filters = {
                society: undefined,
                firstname: undefined,
                lastame: undefined,
                zipcode: undefined,
                status: undefined,
                hasFees: undefined,
                interestedBy: undefined,
            }

            this.getClients()
        },
    },
    computed: {
        pagesIndex() {
            let pages = []
            let index = 1
            for (let i = 0; i < this.nbResult; i = i + 10) {
                pages.push(index)
                index ++
            }

            return pages
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.start = 0
            }
        }
    },
    async mounted() {
        this.getClients()
    }
}
</script>

<style scoped>
.client-list-frame {
    margin: 20px auto;
    width: 1000px;
    position: relative;
}
.btn-add {
    cursor: pointer;
    background-color: rgb(248, 162, 2);
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 0;
}
.filters-frame {
    display: flex;
}
.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.filter {
    margin: 3px;
    display: flex;
    flex-direction: column;
}
.filter label, input, select {
    color: rgb(0, 0, 81);
    font-size: 16px;
    padding: 3px;
}
.filter label {
    color: rgb(0, 0, 81);
    font-size: 16px;
}
.buttons {
    display: flex;
    flex-direction: column;
}
.btn-erase-filters {
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    background-color: rgb(0, 0, 111);
    color: white;
    width: 150px;
    text-align: center;
    border-radius: 20px;
}
.btn-apply-filters {
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    background-color: orange;
    color: white;
    width: 150px;
    text-align: center;
    border-radius: 20px;
}
#clients-table {
    margin: 20px auto;
    width: 1000px;
    border-collapse: collapse;
}
.client {
    background-color: rgb(255, 255, 255);
}
.client td {
    padding: 10px;
    border: 1px solid lightgrey;
}
.client:hover td {
    background-color: rgb(247, 247, 247);
}
.creation_date {
    text-align: center;
}
.btn-client {
    text-align: center;
}
a {
    text-decoration: none;
    color: black;

}
.form-add-new {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.form-add-new label, input{
    flex-basis: 45%;
    padding: 3px;
    margin: 2px 0;
}
.btn-save {
    cursor: pointer;
    padding: 5px;
    text-align: center;
    width: 100px;
    margin: 10px auto;
    background-color: orange;
    border-radius: 5px;
}
.pages {
    display: flex;
    flex-wrap: wrap;
}
.page {
    cursor: pointer;
    margin: 2px;
    height: 20px;
    width: 20px;
    font-size: 10px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
</style>