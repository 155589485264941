<template>
    <div class="list-section">
        <h1 class="section-title">
            Patrimoine immobilier - {{ toEuro($store.getters.getTotalPropertyWealth) }}

            <add-btn type="property"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="property in $store.getters.getAllProperties"
                :key="property._id"
                :object="property"
                type="property"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    }
}
</script>

<style scoped>
.funding-section {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}
.loan-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
</style>