<template>
    <section>
        <li @click="toggleModale">Ajouter un texte</li>

        <modale
            :show="showModale"
            @toggle="toggleModale"
        >
            <div class="modale-frame">
                <label for="title">Titre de la partie</label>
                <input type="text" v-model="newTextBody.title">

                <text-editor
                    id="add-text" 
                    v-model="newTextBody.text"
                    height="500"
                />

                <div class="btn-save" @click="createText">Enregistrer</div>
            </div>
            
        </modale>
        
    </section>
</template>

<script>
import axios from 'axios'

export default {
    props: ['parent_section'],
    data() {
        return {
            showModale: false,
            newTextBody: {
                sectionId      : "",
                title          : "",
                text           : "",
                last_update    : new Date()
            }
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
        async createText() {
            if (!this.newTextBody.title) {
                alert("Veuillez renseigner un titre");

            } else if (!this.newTextBody.text) {
                alert("Veuillez renseigner un texte");
                
            } else {
                await axios.post(this.$store.getters.get_api_url +'library/library-text/', this.newTextBody, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.$emit('loadText')
                this.toggleModale()
            } 
        },
    },
    mounted() {
        this.newTextBody.sectionId = this.parent_section
    }
}
</script>

<style scoped>
li {
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    list-style: none;
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
}

.modale-frame {
    padding: 20px;
}
input {
    margin: 10px;
    width: 80%;
}

.btn-save {
    cursor: pointer;
    display: inline-block;
    margin: 10px auto;
    padding: 10px 15px;
    background-color: rgb(253, 178, 38);
    font-weight: bold;
}
</style>