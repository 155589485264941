<template>
    <div class="reporting-frame">
        <h2 class="page-title">Reporting</h2>

        <div class="filters">
            <div class="period">
                <label>Période</label>
                <select v-model="period">
                    <option v-for="(period, key) in periods" :key="key" :value="key">{{ period }}</option>
                </select>
            </div>
        </div>

        <div class="container">
            <div>
                <div class="stats-section">
                    <div class="stat-frame"> <span>{{ nb_events }}</span> évènements</div>
                    <div class="stat-frame"> <span>{{ nb_calls }}</span> appels passés</div>
                    <div class="stat-frame"> <span>{{ nb_email }}</span> mails envoyés</div>
                    <div class="stat-frame"> <span>{{ nb_visio }}</span> visios réalisées</div>
                    <div class="stat-frame"> <span>{{ nb_rdv }}</span> rendez-vous physiques</div>
                    <div class="stat-frame"> <span>{{ recommandations.length }}</span> commandes</div>
                    <div class="stat-frame"> <span>{{ nb_society_client }}</span> cabinets ont commandé</div>
                    <div class="stat-frame"> <span>{{ nb_clients }}</span> cabinets clients</div>
                </div>
            </div>
            

            <div class="event-section">
                <table class="event-table">
                    <tr>
                        <td class="header" colspan=5>Evenements de la période</td>
                    </tr>

                    <tr v-for="(event, index) in formatedEventList" :key="index" @click="selectEvent(event)">
                        <td :class="{command: event.type == 'Commande'}">{{ index + 1 }}</td>
                        <td :class="{command: event.type == 'Commande'}">{{ $store.getters.eventTypes[event.type] || event.type }}</td>
                        <td :class="{command: event.type == 'Commande'}">{{ event.title }}</td>
                        <td :class="{command: event.type == 'Commande'}">{{ event.directorName }}</td>
                        <td :class="{command: event.type == 'Commande'}">{{ dateToString(event.date) }}</td>
                        <modale 
                            :show="showEventDetail && selectedEvent._id == event._id"
                            @toggle="toggleShowEvent"
                        >
                            <div v-html="event.text"></div>
                        </modale>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            nb_orders: 0,
            periods: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                week: "Cette semaine",
                month: "Ce mois-ci",
                last_month: "Le mois dernier",
                year: "Cette année"
            },
            period: "",
            events: [],
            recommandations: [],
            nb_clients: 0,
            selectedEvent: {},
            showEventDetail: false,
        }
    },
    methods: {
        async loadEvents() {
            const res = await axios.post(this.$store.getters.get_api_url + "crm/all-events", this.request, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.events = res.data
        },
        async loadRecos() {
            const res = await axios.post(this.$store.getters.get_api_url + "crm/all-recommendation", this.request, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.recommandations = res.data
        },
        isCommand(event) {
            if (event.type == 'Commande') {
                return true
            } else {
                return false
            }
        },
        toggleShowEvent() {
            this.showEventDetail = !this.showEventDetail
        },
        selectEvent(event) {
            this.selectedEvent = event
            this.toggleShowEvent()
        }
    },
    computed: {
        request() {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            let request = {}
            if (this.period == 'today') {
                request = {
                    start: today,
                }

            } else if (this.period == 'yesterday') {
                const yesterday = new Date(today)
                yesterday.setDate(yesterday.getDate() - 1)

                request = {
                    start: yesterday,
                    end: today,
                }

            } else if (this.period == 'week') {
                let day = today.getDay();
                let diff = today.getDate() - day + (day == 0 ? -6:1);
                const current_week = new Date(today.setDate(diff))

                request = {
                    start: current_week,
                }

            } else if (this.period == 'month') {
                const current_month = new Date(today.getFullYear(), today.getMonth(), 0)
                request = {
                    start: current_month,
                }

            } else if (this.period == 'last_month') {
                const last_month = new Date(today.getFullYear(), today.getMonth() - 1, 0)
                const month = new Date(today.getFullYear(), today.getMonth(), 0)

                request = {
                    start: last_month,
                    end: month,
                }

            } else if (this.period == 'year') {
                const current_year = new Date(today.getFullYear(), 0, 0)
                request = {
                    start: current_year,
                }
                
            }

            return request
        },
        nb_rdv() {
            let filtered = this.events.filter(event => event.type == "meeting")
            return filtered.length
        },
        nb_calls() {
            let filtered = this.events.filter(event => event.type == "call")
            return filtered.length
        },
        nb_email() {
            let filtered = this.events.filter(event => event.type == "email")
            return filtered.length
        },
        nb_visio() {
            let filtered = this.events.filter(event => event.type == "visio")
            return filtered.length
        },
        nb_events() {
            return this.formatedEventList.length
        },
        formatedEventList() {
            let result = JSON.parse(JSON.stringify(this.events))
            this.recommandations.forEach(reco => {
                result.push({
                    _id: reco._id,
                    type: 'Commande',
                    date: reco.date,
                    text: "Recommandation commandée par " + reco.directorName + " pour son client " + reco.clientName,
                    title: reco.clientName,
                    directorName: reco.directorName,
                })
            })
            return result.sort((a, b) =>  new Date(b.date) - new Date(a.date))
        },
        nb_society_client() {
            let result = this.recommandations.map(reco => reco.societyId)
            // Suppression des doublons
            result = result.reduce((prev, cur) => (prev.indexOf(cur) === -1) ? [...prev, cur] : prev, []);
            return result.length
        }
    },
    watch: {
        period() {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            this.loadEvents()
            this.loadRecos()
        }
    },
    async mounted() {
        this.period = "month"
        const res = await axios.get(this.$store.getters.get_api_url + "crm/nb-clients", {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })
        this.nb_clients = res.data
    }
}
</script>

<style scoped>

.reporting-frame {
    padding: 20px 20%;
}
.page-title {
    color: rgb(0, 0, 73);
    font-size: 30px;
    margin: 15px;
    border-bottom: 1px solid lightgrey;
}
.filters {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 10px;
}
.period {
    display: flex;
    flex-direction: column;
    color: rgb(255, 136, 0);
}

.container {
    display: flex;
}
.stats-section {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.stat-frame {
    flex-basis: 25%;
    margin: 10px 10px;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
}
.stat-frame span {
    font-size: 22px;
    font-weight: bold;
    color: rgb(255, 128, 0);
    margin-right: 10px;
}

.event-section {
    flex-basis: 100%;
}
.event-table {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    border-collapse: collapse;
}
.event-table .header {
    padding: 10px;
    text-align: center;
    background-color: rgb(2, 2, 95);
    color: white;
    border-radius: 10px 10px 0 0;
    font-size: 15px;
}
.event-table td {
    cursor: pointer;
    padding: 5px;
    border: solid 1px rgb(243, 245, 247);
    font-size: 12px;
}
.event-table td.command {
    background-color: rgba(255, 166, 0, 0.673);
}

</style>