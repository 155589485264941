<template>
    <h1 class="section-label">Simulations des plus-values immobilières</h1>
    <div class="separator"></div>

    <table class="simulation-table">
        <tr>
            <th>Bien vendu</th>
            <th>Valeur</th>
            <th>Date de la vente</th>
            <th>Prix d'achat</th>
            <th>Détail</th>
        </tr>

        <tr v-for="sell in propertySells" :key="sell._id">
            <td>{{ $store.getters.propertyTypesList[sell.property_type] }}</td>
            <td>{{ toEuro(sell.current_value) }}</td>
            <td>{{ dateToString(sell.selling_date) }}</td>
            <td>{{ toEuro(sell.initial_value) }}</td>
            <td @click="toggleShowPropertyGain(sell._id)"> <edit-icon style="cursor: pointer"/> </td>

            <modale :show="selectedPropertySell == sell._id" @toggle="toggleShowPropertyGain(sell._id)">
                <table class="showTaxResult">
                    <tr>
                        <th>Type de bien</th>
                        <td colspan="2">{{ $store.getters.propertyTypesList[sell.property_type] }}</td>
                    </tr>

                    <tr>
                        <th>Mode d'acquisition</th>
                        <td colspan="2">
                            <select v-model="sell.is_bought">
                                <option :value="true">Acquisition à titre onéreux</option>
                                <option :value="false">Acquisition à titre gratuit</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <th>Date d'acquisition</th>
                        <td colspan="2"> <input type="date" v-model="sell.acquisition_date"> </td>
                    </tr>

                    <tr>
                        <th>Date de la vente</th>
                        <td colspan="2"> <input type="date" v-model="sell.selling_date"> </td>
                    </tr>

                    <tr>
                        <th>Prix de vente</th>
                        <td colspan="2"> <input type="number" v-model="sell.current_value"> </td>
                    </tr>

                    <tr>
                        <th>Prix d'achat</th>
                        <td colspan="2"> <input type="number" v-model="sell.initial_value"> </td>
                    </tr>

                    <tr>
                        <th>Frais d'acquisition</th>
                        <td colspan="2"> <input type="number" v-model="sell.acquisition_fees"> </td>
                    </tr>

                    <tr>
                        <th>Travaux réalisés (sur justificatif)</th>
                        <td colspan="2"> <input type="number" v-model="sell.work_value"> </td>
                    </tr>

                    <!-- Resultats du calculateur -->

                    <tr>
                        <th>Durée de détention</th>
                        <td colspan="2">{{ sell.result?.detention_duration }} ans</td>
                    </tr>

                    <tr v-if="sell.result?.is_fixed_acquisition_fees">
                        <th>Frais d'acquisition forfaitaires</th>
                        <td colspan="2">{{ toEuro(sell.result?.acquisition_fees) }}</td>
                    </tr>

                    <tr v-if="sell.result?.is_fixed_work">
                        <th>Travaux forfaitaires</th>
                        <td colspan="2">{{ toEuro(sell.result?.work_value) }}</td>
                    </tr>

                    <tr>
                        <th>Plus-value réalisée</th>
                        <td colspan="2">{{ toEuro(sell.result?.gain) }}</td>
                    </tr>

                    <tr>
                        <th>Plus-value taxable à l'IR</th>
                        <td>Exonération de {{ Math.round(10000 * sell.result?.tax_exoneration_rate) / 100}} % </td>
                        <td>{{ toEuro(sell.result?.taxable_to_ir) }}</td>
                    </tr>

                    <tr>
                        <th>Impôt sur le revenu</th>
                        <td colspan="2">{{ toEuro(sell.result?.taxes) }} </td>
                    </tr>

                    <tr>
                        <th>Plus-value taxable aux prélèvements sociaux</th>
                        <td>Exonération de {{ Math.round(10000 * sell.result?.social_exoneration_rate) / 100}} %</td>
                        <td>{{ toEuro(sell.result?.taxable_to_social) }}</td>
                    </tr>

                    <tr>
                        <th>Prélèvements sociaux</th>
                        <td colspan="2">{{ toEuro(sell.result?.social_contribution) }}</td>
                    </tr>

                    <tr>
                        <th class="result-title">Surtaxe</th>
                        <td colspan="2">{{ toEuro(sell.result?.additional_tax) }}</td>
                    </tr>

                    <tr>
                        <th class="result-title">Total à payer</th>
                        <td colspan="2">{{ toEuro(sell.result?.additional_tax + sell.result?.taxes + sell.result?.social_contribution) }}</td>
                    </tr>
                </table>
                
                <div class="save-btn" @click="savePropertySell(sell._id)">Enregistrer la simulation</div>
            </modale>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    props: ['selectedScenario'],
    data() {
        return {
            propertySells: [],
            selectedPropertySell: undefined,
        }
    },
    methods: {
        async loadPropertySells() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/property-sell/' + this.selectedScenario, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.propertySells = res.data

            for (let i = 0; i < this.propertySells.length; i++) {
                let computation = await axios.post(this.$store.getters.get_api_url + 'simulators/real-estate-gain', this.propertySells[i], {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.propertySells[i].acquisition_date = this.dateToString(this.propertySells[i].acquisition_date, true)
                this.propertySells[i].selling_date = this.dateToString(this.propertySells[i].selling_date, true)
                this.propertySells[i].result = computation.data
            }
        },
        async savePropertySell(id) {
            const sell = this.propertySells.find(s => s._id == id)
            await axios.put(this.$store.getters.get_api_url + 'clients/property-sell/' + id, sell, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.loadPropertySells()
        },
        toggleShowPropertyGain(_id) {
            if (this.selectedPropertySell == _id) {
                this.selectedPropertySell = undefined
            } else {
                this.selectedPropertySell = _id
            }   
        },
    }, 
    watch: {
        selectedScenario() {
            this.loadPropertySells()
        }
    }, 
    mounted() {
        this.loadPropertySells()
    }
}
</script>

<style src="./style.css" scoped></style>

<style scoped>
table.showTaxResult {
    margin-bottom: 100px;
}
table.showTaxResult th, td {
    text-align: center;
    border: 1px solid rgb(5, 7, 9);
    padding: 10px;
    position: relative;
}

td input, td select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    border: none;
    text-align: center;
}
th {
    width: 40%;
}
td {
    width: 30%;
}

.save-btn {
    cursor: pointer;
    background-color: rgb(255, 177, 31);
    position: absolute;
    padding: 10px;
    left: 45%;
    bottom: 20px;
    border-radius: 10px;
}
.save-btn:hover {
    background-color: rgb(253, 188, 68);
}
</style>