<template>
    <div class="container">
        <div class="side-left">
            <Gifts/>
        </div>

        <div class="side-right">
            <Wills/>
            <Rewards/>
        </div>
    </div>
</template>

<script>
import Wills from '../sections/Wills.vue'
import Gifts from '../sections/Gifts.vue'
import Rewards from '../sections/Rewards.vue'

export default {
    components: {
        Wills,
        Gifts,
        Rewards,
    },
}
</script>

<style scoped></style>