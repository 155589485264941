<template>
    <div class="page-container">
        <h3>Sections de solution</h3>
        <div class="add-btn" @click="toggleForm">Ajouter</div>

        <div class="items-list">
            <ListItem
                v-for="solution in solutions" :key="solution._id"
                :item="solution"
                type="section"
                @toggleForm="loadSections"
            />
        </div>

        <modale :show="showForm" @toggle="toggleForm">
            <SectionForm @closeModale="toggleForm" type="solution"/>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'

import ListItem from '../components/ListItem.vue'
import SectionForm from '../components/SectionForm.vue'

export default {
    components: {
        ListItem,
        SectionForm,
    },
    data() {
        return {
            showForm: false,
            solutions: []
        }
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm
            this.loadSections()
        },
        async loadSections() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/sections/solution', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.solutions = result.data
        },
    },
    mounted() {
        this.loadSections()
    }
}
</script>

<style scoped src="../Style.css"></style>