<template>
    <div class="calculators-frame">
        <h1>Calculettes ingénierie patrimoniale</h1>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.calculators-frame {
    margin: 30px 20%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}

.calculators-frame h1 {
    color: rgb(0, 0, 81);
    border-bottom: 4px solid rgb(0, 0, 81);
}
</style>