<template>
    <form class="add-form">
        <div class="form-section">
            <label for="category">Catégorie</label>
            <select id="category" v-model="body.type">
                <option v-for="(value, key) in $store.getters.reductionTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Montant de l'investissement</label>
            <input type="number" step="10000" v-model="body.value">
        </div>

        <div class="form-section" v-if="['FCPI', 'FIP', 'PINEL', 'MALRAUX'].includes(body.type)">
            <label for="value">Taux de réduction d'impôts</label>
            <select id="category" v-model="body.reduction_rate">
                <option v-for="(value, key) in reduction_rate[body.type]" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: "FCPI",
                value: 0,
                reduction_rate: 0,
                isIpCreation: false,
            },
            reduction_rate: {
                PINEL: {
                    0.02: "2% : Année 1 à 9",
                    0.01: "1% : Année 10 à 12",
                },
                MALRAUX: {
                    0.22: "22% : Site patrimonial remarquable avec PVAP",
                    0.3 : "30% : Site patrimonial remarquable avec PSMV"
                },
                FCPI: {
                    0.18: "18%",
                    0.19: "19%",
                    0.2: "20%",
                    0.21: "21%",
                    0.22: "22%",
                    0.23: "23%",
                    0.24: "24%",
                    0.25: "25%",
                },
                FIP: {
                    0.18: "18%",
                    0.19: "19%",
                    0.2: "20%",
                    0.21: "21%",
                    0.22: "22%",
                    0.23: "23%",
                    0.24: "24%",
                    0.25: "25%",
                },
            }
        }
    },
    methods: {
        submit() {
            if (this.isNew) {
                const routeArray = this.$route.fullPath.split('/')
                if (routeArray[3] != 'profile') {
                    this.body.isIpCreation = true
                }
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de réduction"

            } else if (this.body.value == undefined || !this.body.value > 0) {
                this.errorMsg = "Le montant doit être supérieur à 0"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_tax_reduction', this.body)
                } else {
                    this.$store.dispatch('modify_tax_reduction', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    mounted() {

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))

        }
        
        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

