<template>
    <section v-if="legacyResult.first_death.datas.is_married">
        <p>Dans le scenario retenu, le conjoint survivant opte pour <b>{{ CHOICE_TRAD[spouseChoice] }}</b>.</p>

        <section v-if="['3/4US+1/4PP', '1/4PP'].includes(spouseChoice)">
            <h4><u>Calcul de la part en pleine propriété</u></h4>
            <p>En principe, le conjoint a droit au quart en pleine propriété de la masse de calcul réduite des libéralités consenties par le défunt :</p>
            <ul>
                <li>Masse de calcul des droits théoriques = (masse de calcul - legs)</li>
                <li>Masse de calcul des droits théoriques = ({{ toEuro(legacyResult.first_death.legacy_masses.calculation_mass) }} - {{ toEuro(legsValue) }}) </li>
                <li>Masse de calcul des droits théoriques = {{ toEuro(theoreticalRightsMass) }}</li>
                <li>Droits théoriques = Masse de calcul des droits théoriques x 25 %</li>
                <li>Droits théoriques = <b>{{ toEuro(theoreticalRights) }}</b></li>
            </ul>

            <p>Toutefois, les droits du conjoint ne peuvent pas aboutir à la restitution par un donataire de toute ou partie d'une donation qu'il a reçu. Ils ne peuvent pas non plus empêcher un héritier réservataire de recevoir sa réserve. Il convient donc de calculer une masse d'exercice :</p>
            <ul>
                <li>Masse d'exercice = Masse de calcul des droits théoriques - réserve héréditaire globale - fraction des libéralités excédant la part de réserve individuelle</li>
                <li>Masse d'exercice = {{ toEuro(theoreticalRightsMass) }} - {{ toEuro(legacyResult.first_death.legacy_masses.rhg_value) }} - {{ toEuro(onQdoGifts) }}</li>
                <li>Masse d'exercice = <b>{{ toEuro(this.legacyResult.first_death.spouse_part.spouse_part_pp.exercise_mass) }}</b></li>
            </ul>

            <p>Les droits effectifs du conjoint en pleine propriété sont donc égaux au plus petit montant entre les droits théoriques ({{ toEuro(theoreticalRights) }}) et la masse d'exercice ({{ toEuro(this.legacyResult.first_death.spouse_part.spouse_part_pp.exercise_mass) }}). Ils sont donc de <b>{{ toEuro(legacyResult.first_death.spouse_part.spouse_part_pp.value) }}</b></p>
        </section>

        <section v-if="['QDPP'].includes(spouseChoice)">
            <h4><u>Calcul de la part en pleine propriété</u></h4>
            <p>Le conjoint aura ici droit à la totalité de la QDO après déduction des donations qui s'y imputent et des legs. Ses droits seront donc de <b>{{ toEuro(legacyResult.first_death.spouse_part.spouse_part_pp.value) }}</b></p>
        </section>

        <section v-if="['3/4US+1/4PP', '100%US'].includes(spouseChoice)">
            <h4><u>Calcul de la part en usufruit</u></h4>
            <p>Le conjoint a droit à la totalité de l'usufruit des biens existants déduction faite des legs et de la part qu'il a reçu en pleine propriété. Bien sûr, son droit d'usufruit ne s'applique pas sur les biens dont le défunt a fait donation de son vivant.</p>
            <ul>
                <li>Masse d'exercice de l'usufruit = biens existants - usufruit légué - droits reçu en pleine propriété par le conjoint</li>
                <li>Masse d'exercice de l'usufruit = {{ toEuro(legacyResult.first_death.datas.biens_existants.NP) }} - {{ toEuro(legacyResult.first_death.will_result.bequest_granted.US) }} - {{ toEuro(legacyResult.first_death.spouse_part.spouse_part_pp.value) }}</li>
                <li>Masse d'exercice de l'usufruit = <b>{{ toEuro(usufructMass) }}</b></li>
            </ul>

            <p>Comme le conjoint a {{ spouseAge }} ans, la valeur fiscale de l'usufruit viager est de {{ toEuro(usufructMass) }} x {{ this.legacyResult.global_data.val_us * 100 }} % = <b>{{ toEuro(usufructValue) }}</b></p>
        </section>

        <section v-if="['3/4US+1/4PP'].includes(spouseChoice)">
            <h4><u>Total de la part en valeur fiscale</u></h4>
            <p>La part du conjoint survivant en valeur est donc de {{ toEuro(legacyResult.first_death.spouse_part.spouse_part_pp.value) }} (pleine propriété) + {{ toEuro(usufructValue) }} (usufruit) = <b>{{ toEuro(usufructValue + legacyResult.first_death.spouse_part.spouse_part_pp.value) }}</b>.</p>
        </section>

    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'spouseChoice'],
    data() {
        return {
            CHOICE_TRAD: {
                '100%US': "tout l'usufruit",
                'QDPP': "la quotité disponible en pleine propriété",
                '3/4US+1/4PP': "3/4 de l'usufruit et 1/4 en pleine propriété",
                '1/4PP': '1/4 en pleine propriété',
                'O%': 'renoncé à la succession',
            }
        }
    },
    computed: {
        theoreticalRightsMass() {
            return this.legacyResult.first_death.legacy_masses.calculation_mass - Math.max( this.legacyResult.first_death.will_result.bequest_granted.US, this.legacyResult.first_death.will_result.bequest_granted.NP )
        },
        theoreticalRights() {
            return this.theoreticalRightsMass * 0.25
        },
        onQdoGifts() {
            let result = 0
            Object.keys(this.legacyResult.first_death.reduction.list_gift_per_date).forEach(date => {
                this.legacyResult.first_death.reduction.list_gift_per_date[date].forEach(gift => {
                    result += gift.onQdo
                })
            });

            return result
        },
        usufructMass() {
            return this.legacyResult.first_death.datas.biens_existants.NP - this.legacyResult.first_death.will_result.bequest_granted.US - this.legacyResult.first_death.spouse_part.spouse_part_pp.value
        },
        usufructValue() {
            return  this.usufructMass * this.legacyResult.global_data.val_us
        },
        spouseAge() {
            return this.$store.getters.findPersonAge(this.legacyResult.global_data.id_spouse)
        },
        legsValue() {
            return Math.max(this.legacyResult.first_death.will_result.bequest_granted.US, this.legacyResult.first_death.will_result.bequest_granted.NP)
        },
    }
}
</script>

<style scoped src="./style.css"></style>