<template>
   <div class="calculator-frame">

        <h2>Calcul de la l'impôt sur la fortune immobilière</h2>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Valeur de la résidence principale</label>
                <input type="number" v-model="main_property_value">
            </div>

            <div class="datas-section">
                <label>Valeur du reste du patrimoine immobilier</label>
                <input type="number" v-model="real_estate_gross">
            </div>

            <div class="datas-section">
                <label>Valeur des unités de comptes immobilières</label>
                <input type="number" v-model="uc_immo_value">
            </div>
        </div>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Capital restant dû sur la résidence principale</label>
                <input type="number" v-model="crd_main_property">
            </div>

            <div class="datas-section">
                <label>Capital restant dû sur les autres emprunts immobiliers</label>
                <input type="number" v-model="crd_real_estate">
            </div>
        </div>

        <div class="separator"></div>
        
        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Actif brut immobilier</th>
                <td>{{ toEuro(computationResult.total_taxable_asset) }}</td>
            </tr>

            <tr>
                <th>Passif déductible</th>
                <td>- {{ toEuro(computationResult.total_deductible_debts) }}</td>
            </tr>

            <tr>
                <th>IFI théorique</th>
                <td>- {{ toEuro(computationResult.ifi_theorique) }}</td>
            </tr>

            <tr>
                <th>Actif immobilier net</th>
                <td>= {{ toEuro(computationResult.taxable) }}</td>
            </tr>

            <tr>
                <th>IFI à payer</th>
                <td>{{ toEuro(computationResult.ifi_reel) }}</td>
            </tr>
        </table>
   </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            real_estate_gross: 1400000,
            main_property_value: 300000,
            uc_immo_value: 0,
            crd_real_estate: 200000,
            crd_main_property: 100000,
            computationResult: undefined,
        }
    },
    computed: {
        requestBody() {
            let body = {
                persons: [{ _id: 'user1', role: 'user' }],
                properties: [],
                societies: [],
                assets: [],
                movables: [],
                debts: [],
                other_immo_value: this.uc_immo_value
            }

            if (this.main_property_value > 0) {
                body.properties.push({
                    _id: 'mainproperty',
                    type: 'main_property',
                    value: this.main_property_value,
                    detention: [ { owner_id: 'user1', part: 1, right: 'pp' } ],
                })
            }

            body.properties.push({
                _id: 'realestate',
                type: 'secondary',
                value: this.real_estate_gross,
                detention: [ { owner_id: 'user1', part: 1, right: 'pp' } ],
            })

            if (this.crd_main_property > 0) {
                body.debts.push({
                    _id: 'debtmainproperty',
                    type: 'property_loan',
                    value: this.crd_main_property,
                    start_date: new Date(),
                    owner1: 'user1',
                    property_id: 'mainproperty',
                    refund_type: 'amortization',
                })
            }

            if (this.crd_real_estate > 0) {
                body.debts.push({
                    _id: 'otherdebt',
                    type: 'property_loan',
                    value: this.crd_real_estate,
                    start_date: new Date(),
                    owner1: 'user1',
                    property_id: 'realestate',
                    refund_type: 'amortization',
                })
            }

            return body
        }
    },
    watch: {
        requestBody: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ifi-tax', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style scoped>
.calculation-datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.datas-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.datas-section label {
    font-size: 22px;
    font-weight: bold;
}

.datas-section input, select {
    font-size: 22px;
}

.calculation-result {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.calculation-result td, th {
    border: 1px solid lightgrey;
    padding: 10px;
}

</style>