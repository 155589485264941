<template>
    <div class="wealth-detail">
        <h2>Participation dans des sociétés</h2>

        <table>
            <tr>
                <th>Société</th>
                <th>Propriétaire</th>
                <th>Valeur totale</th>
                <th>Date d'acquisition</th>
            </tr>

            <tr v-for="asset in $store.getters.getAllSocieties" :key="asset._id">
                <td>{{ asset.label || $store.getters.societyTypes[asset.type] }}</td>
                <td>{{ $store.getters.getAssetOwner(asset.detention, true) }}</td>
                <td>{{ toEuro(asset.value) }}</td>
                <td>{{ dateToString(asset.opening_date) }}</td>
            </tr>
        </table>

        <h2>Patrimoine immobilier</h2>

        <table>
            <tr>
                <th>Actif immobilier</th>
                <th>Propriétaire</th>
                <th>Valeur totale</th>
                <th>Date d'acquisition</th>
            </tr>

            <tr v-for="asset in $store.getters.getAllProperties" :key="asset._id">
                <td>{{ asset.label || $store.getters.propertyTypesList[asset.type] }}</td>
                <td>{{ $store.getters.getAssetOwner(asset.detention, true) }}</td>
                <td>{{ toEuro(asset.value) }}</td>
                <td>{{ dateToString(asset.opening_date) }}</td>
            </tr>
        </table>

        <h2>Patrimoine financier</h2>

        <table>
            <tr>
                <th>Actif financier</th>
                <th>Propriétaire</th>
                <th>Valeur totale</th>
                <th>Date d'acquisition</th>
            </tr>

            <tr v-for="asset in $store.getters.getAllAssets" :key="asset._id">
                <td>{{ asset.label || $store.getters.assetTypesList[asset.type] }}</td>
                <td>{{ $store.getters.getAssetOwner(asset.detention, true) }}</td>
                <td>{{ toEuro(asset.value) }}</td>
                <td>{{ dateToString(asset.opening_date) }}</td>
            </tr>
        </table>

        <h2>Contrats d'assurance</h2>

        <table>
            <tr>
                <th>Contrat</th>
                <th>Propriétaire</th>
                <th>Valeur totale</th>
                <th>Date d'acquisition</th>
            </tr>

            <tr v-for="asset in $store.getters.getAllInsurances" :key="asset._id">
                <td>{{ asset.label || $store.getters.insuranceTypes[asset.type] }}</td>
                <td>{{ $store.getters.findOwnerName(asset.owner1) }}</td>
                <td>{{ toEuro(asset.value) }}</td>
                <td>{{ dateToString(asset.opening_date) }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.wealth-detail {
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

table th, td {
    border: 1px solid #BE9C5D;
    padding: 10px;
}

table th {
    text-align: center;
    background-color: #F8F4EB;
}

table td:nth-child(3),
table td:nth-child(4) {
    text-align: center;
}
</style>