<template>
  <div>
    <canvas id="custom-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import functions from '../assets/functions'

export default {
    props: {
        labels : {
            type: Object,
            required: true
        },
        datasets : {
            type: Object,
            required: true
        },
        chartId : {
            type: String,
            required: true
        },
        startZero : {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            ChartData : {
                type: "line",
                data: {
                    labels: this.labels,
                    datasets: this.datasets
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: this.startZero
                            },
                            gridLines: {
                                color: "rgb(197, 229, 240)",
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }   
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let label = data.labels[tooltipItem.index];
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                value = functions.toEuro(value, 2)

                                return ' ' + label + ' : ' + value;

                            }
                        }
                    },
                }
            }
        }
    },
    mounted() {
        this.ChartData.data.labels = this.labels
        this.ChartData.data.datasets = this.datasets

        let ctx = document.getElementById('custom-chart');
        ctx.id = this.chartId
        const chart = document.getElementById(this.chartId);
        new Chart(chart, this.ChartData);
    }
}
</script>