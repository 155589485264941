<template>
    <form class="add-form">

        <div class="form-section">
            <label for="value">Titre</label>
            <input type="text" v-model="body.title">
        </div>

        <div class="form-section">
            <label for="value">Catégorie</label>
            <select v-model="body.sectionId">
                <option v-for="section in sections" :key="section._id" :value="section._id">{{ section.title }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Texte</label>
            <text-editor
                id="add-text" 
                v-model="body.text"
                height="500"
            />
        </div>
        
        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            sections: [],
            body: {
                _id: undefined,
                number: undefined,
                sectionId: undefined,
                title: "",
                text: "",
            },
        }
    },
    methods: {
        async submit() {
            if (this.body.title == undefined) {
                this.errorMsg = "Titre manquant"
            } else if (this.body.text == undefined) {
                this.errorMsg = "Texte manquant"
            } else {
                if (this.isNew) {
                    await axios.post(this.$store.getters.get_api_url +'referential/reminders/', this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })

                } else {
                    console.log('yep')
                    await axios.put(this.$store.getters.get_api_url +'referential/reminders/' + this.import._id, this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                }

                this.$emit('closeModale')
                
            }
        },
        async loadSections() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/sections/reminder', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.sections = result.data
        },
    },
    mounted() {
        this.loadSections()
        
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
        }


    }
}
</script>

<style scoped>

</style>