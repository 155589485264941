<template>
    <div class="audit-page">
        <h1>Choix des solutions (4/4)</h1>

        <div class="btn-frame">
            <div class="add-btn" @click="toggleModale">Ajouter une solution</div>
            <div class="default-btn" @click="getAutoSolutions">Auto</div>
        </div>

        <div class="categories">
            <div class="category">
                <h2 class="category-title">Protection de la famille</h2>

                <draggable class="dragArea list-group w-full" v-model="family_protection_solutions" group="audit">
                    <div class="element" v-for="el in family_protection_solutions" :key="el._id">
                        <div>{{ solutions.find(s => s._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteSolution(el)">X</div>
                    </div>
                </draggable>
            </div>

            <div class="category">
                <h2 class="category-title">Gestion du patrimoine</h2>

                <draggable class="dragArea list-group w-full" v-model="wealth_changes_solutions" group="audit">
                    <div class="element" v-for="el in wealth_changes_solutions" :key="el._id">
                        <div>{{ solutions.find(s => s._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteSolution(el)">X</div>
                    </div>
                </draggable>
            </div>

            <div class="category">
                <h2 class="category-title">Transmision du patrimoine</h2>

                <draggable class="dragArea list-group w-full" v-model="legacy_solutions" group="audit">
                    <div class="element" v-for="el in legacy_solutions" :key="el._id">
                        <div>{{ solutions.find(s => s._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteSolution(el)">X</div>
                    </div>
                </draggable>
            </div>
        </div>

        <modale :show="showModale" @toggle="toggleModale">
            <div class="referential">
                <input type="text" v-model="researchString">

                <div class="element" v-for="el in unselected_solutions" :key="el._id">
                    <div>n° {{ el.number }} - {{ el.title }}</div>
                    <div class="delete-btn" @click="addSolution(el._id)">+</div>
                </div>
            </div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    components: {
        draggable: VueDraggableNext,
    },
    data() {
        return {
            trad_sections: {
                '65ea20654c5c587685cc8743': 'family_protection_solutions',
                '65ea207d4c5c587685cc8749': 'wealth_changes_solutions',
                '65ea208c4c5c587685cc874f': 'legacy_solutions',
            },
            recommendation: {},
            solutions: [],
            showModale: false,
            family_protection_solutions: [],
            wealth_changes_solutions: [],
            legacy_solutions: [],
            researchString: "",
        }
    },
    methods: {
        async loadSolutions() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/solutions/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.solutions = result.data
        },
        async getAutoSolutions() {
            const solutionRes = await axios.get(this.$store.getters.get_api_url +'referential/recommendation-solutions/' + this.$route.params.recommendationId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.family_protection_solutions = []
            this.wealth_changes_solutions = []
            this.legacy_solutions = []

            for (const solutionId of solutionRes.data) {
                const solution = this.solutions.find(s => s._id == solutionId)

                if (this.trad_sections[solution.sectionId] == 'family_protection_solutions') {
                    this.family_protection_solutions.push(solutionId)

                } else if (this.trad_sections[solution.sectionId] == 'wealth_changes_solutions') {
                    this.wealth_changes_solutions.push(solutionId)

                } else if (this.trad_sections[solution.sectionId] == 'legacy_solutions') {
                    this.legacy_solutions.push(solutionId)

                } else {
                    this.legacy_solutions.push(solutionId)
                }
            }
        },
        async searchSolutions() {
            if (this.researchString == "") {
                this.loadSolutions()
            } else {
                const result = await axios.post(this.$store.getters.get_api_url +'referential/solutions-research/', {
                    research: this.researchString
                } , {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.solutions = result.data
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        addSolution(id) {
            const solution = this.solutions.find(s => s._id == id)

            if (this.trad_sections[solution.sectionId] == 'family_protection_solutions') {
                this.family_protection_solutions.push(id)

            } else if (this.trad_sections[solution.sectionId] == 'wealth_changes_solutions') {
                this.wealth_changes_solutions.push(id)
                
            } else {
                this.legacy_solutions.push(id)
            }

            this.toggleModale()
        },
        deleteSolution(id) {
            this.family_protection_solutions = this.family_protection_solutions.filter(s => s != id)
            this.wealth_changes_solutions = this.wealth_changes_solutions.filter(s => s != id)
            this.legacy_solutions = this.legacy_solutions.filter(s => s != id)
        },
    },
    watch: {
        family_protection_solutions: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    family_protection_solutions: this.family_protection_solutions,
                    wealth_changes_solutions: this.wealth_changes_solutions,
                    legacy_solutions: this.legacy_solutions,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        wealth_changes_solutions: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    family_protection_solutions: this.family_protection_solutions,
                    wealth_changes_solutions: this.wealth_changes_solutions,
                    legacy_solutions: this.legacy_solutions,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        legacy_solutions: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    family_protection_solutions: this.family_protection_solutions,
                    wealth_changes_solutions: this.wealth_changes_solutions,
                    legacy_solutions: this.legacy_solutions,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        researchString() {
            this.searchSolutions()
        }
    }, 
    computed: {
        unselected_solutions() {
            const selected_solutions = [...this.family_protection_solutions, ...this.wealth_changes_solutions, ...this.legacy_solutions]

            return this.solutions.filter(s => !selected_solutions.includes(s._id))
        }
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url +'clients/find-recommendation/' + this.$route.params.recommendationId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.recommendation = res.data
        this.family_protection_solutions = this.recommendation.family_protection_solutions
        this.wealth_changes_solutions = this.recommendation.wealth_changes_solutions
        this.legacy_solutions = this.recommendation.legacy_solutions

        await this.loadSolutions()

        if ([...this.family_protection_solutions, ...this.wealth_changes_solutions, ...this.legacy_solutions].length == 0) {
            this.getAutoSolutions()
        }
    }
}
</script>

<style scoped>
.audit-page {
    position: relative;
    margin: 20px;
}
</style>

<style src="../audit/style.css" scoped/>