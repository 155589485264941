import axios from 'axios'

function notaryFeesContributions(value, isImmo, isFirst) {
    let notary_remuneration = value * 0.00271
    if (isFirst) {
        notary_remuneration += 135.45
    }
    let tpf = value * 0.0071498
    let csi = 0
    let fixed_recording_fees = 0
    if (isImmo) {
        tpf = value * 0.0071498
        csi = value * 0.001
        fixed_recording_fees = 346.16
    }

    const act_package = 25

    const tva = (notary_remuneration + fixed_recording_fees + act_package) * 0.2

    return notary_remuneration + tpf + csi + fixed_recording_fees + act_package + tva
}

export default {
    // Suppression
    async sell_property(context, body) {
        const CODES = {
            main_property: 'DELETE_MAIN_PROPERTY',
            secondary: 'DELETE_SECONDARY_PROPERTY',
            location: 'DELETE_LOCATION_PROPERTY',
            scpi: 'DELETE_SCPI_PROPERTY',
            parking: 'DELETE_LOCATION_PROPERTY',
            land: 'DELETE_LAND_PROPERTY',
            agricultural_land: 'DELETE_LAND_PROPERTY',
            forest: 'DELETE_FOREST_PROPERTY',
            gfa: 'DELETE_GFA_PROPERTY',
            gff: 'DELETE_GFF_PROPERTY',
            gfv: 'DELETE_GFV_PROPERTY',
            office: 'DELETE_LOCATION_PROPERTY',
            warehouse: 'DELETE_LOCATION_PROPERTY',
            other: 'DELETE_PROPERTY',
        }



        const participation_value = context.getters.getParticipationValue(body.detention, body.value)

        if (participation_value > 0) {
            // Ne prend pas en compte les biens détenus en usufruit
            // Ne prend pas en compte les biens détenus au travers d'une société
            const action = {
                scenarioId  : body.scenarioId,
                category    : "DELETE_PROPERTY",
                code        : CODES[body.type],
                liquidities : participation_value,
                old_value   : body,
                date: new Date(),
                new_value   : null,
            }
            await axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        await context.dispatch('delete_property', body._id)
    },
    sell_asset(context, body) {
        const CODES = {
            cc: "DELETE_SAVINGS_ASSETS",
            livreta: "DELETE_SAVINGS_ASSETS",
            ldds: "DELETE_SAVINGS_ASSETS",
            csl: "DELETE_SAVINGS_ASSETS",
            cat: "DELETE_CAT_ASSETS",
            capi: "DELETE_CAPI_ASSETS",
            pel: "DELETE_PEL_ASSETS",
            cel: "DELETE_CEL_ASSETS",
            lep: "DELETE_SAVINGS_ASSETS",
            pea: "DELETE_PEA_ASSETS",
            cto: "DELETE_CTO_ASSETS",
            social_share: "DELETE_SOCIAL_SHARES_ASSETS",
            pep: "DELETE_PEP_ASSETS",
            goodwill: "DELETE_GOODWILL_ASSETS",
            ei: "DELETE_INDIVIDUAL_ACTIVITY_ASSETS",
            pee: "DELETE_PEE_ASSETS",
            cca: "DELETE_CCA_ASSETS",
            perin: "DELETE_PERIN_ASSETS",
            percol: "DELETE_PERCOL_ASSETS",
            pero: "DELETE_PERO_ASSETS",
            perco: "DELETE_PERCO_ASSETS",
            fcpi: "DELETE_FCPI_ASSETS",
            fip: "DELETE_FIP_ASSETS",
            fcpr: "DELETE_FCPR_ASSETS",
            fpci: "DELETE_FPCI_ASSETS",
            eltif: "DELETE_ELTIF_ASSETS",
            other: "DELETE_SAVINGS_ASSETS",
        }

        let participation_value = context.getters.getParticipationValue(body.detention, body.value)
        if (body.type == 'cca') participation_value = participation_value * -1

        const action = {
            scenarioId  : body.scenarioId,
            category    : "DELETE_FINANCIAL_ASSET",
            code        : CODES[body.type],
            liquidities : participation_value,
            old_value   : body,
            new_value   : null,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('addAction', action)
        context.dispatch('delete_asset', body._id)
    },
    sell_movable(context, body) {
        const CODES = {
            car               : "DELETE_CAR_MOVABLE",
            furnitures        : "DELETE_FURNITURE_MOVABLE",
            art_objects       : "DELETE_ART_MOVABLE",
            precious_stones   : "DELETE_PRECIOUS_STONES_MOVABLE",
            precious_metals   : "DELETE_PRECIOUS_METALS_MOVABLE",
            plane             : "DELETE_PLANE_MOVABLE",
            boat              : "DELETE_BOAT_MOVABLE",
            horse             : "DELETE_HORSES_MOVABLE",
            other             : "DELETE_MOVABLE",
        }

        const participation_value = context.getters.getParticipationValue(body.detention, body.value)

        const action = {
            scenarioId  : body.scenarioId,
            category    : "DELETE_MOVABLE_ASSET",
            code        : CODES[body.type],
            liquidities : participation_value,
            old_value   : body,
            new_value   : null,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('addAction', action)
        context.dispatch('delete_movable', body._id)
    },
    sell_society(context, body) {
        let value = body.value

        if (body.wealthValueMode) {
            value = context.getters.getSocietyValue(body._id)
        }

        const participation_value = context.getters.getParticipationValue(body.detention, value)
        const action = {
            scenarioId  : body.scenarioId,
            category    : "DELETE_SOCIETY",
            code        : "DELETE_SOCIETY",
            liquidities : participation_value,
            old_value   : body,
            new_value   : null,
            date: new Date(),
        }
        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
        context.dispatch('delete_society', body._id)
    },
    sell_insurance(context, body) {

        const INSURANCE_CODES = {
            life      : 'DELETE_LIFE_INSURANCE',
            lux       : 'DELETE_LUX_INSURANCE',
            pep       : 'DELETE_PEP_INSURANCE',
            perin     : 'DELETE_PERIN_INSURANCE',
            percol    : 'DELETE_PERCOL_INSURANCE',
            pero      : 'DELETE_PERO_INSURANCE',
            perp      : 'DELETE_PERP_INSURANCE',
            madelin   : 'DELETE_MADELIN_INSURANCE',
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "DELETE_INSURANCE",
            code        : INSURANCE_CODES[body.type],
            liquidities : body.value,
            old_value   : body,
            new_value   : null,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
        context.dispatch('delete_insurance', body._id)
    },
    refund_debt(context, body) {
        const CODES = {
            insured_loan: "DELETE_INSURED_DEBT",
            uninsured_loan: "DELETE_UNINSURED_DEBT",
            property_loan: "DELETE_PROPERTY_DEBT",
            professionnal_loan: 'DELETE_PROFESSIONNAL_DEBT',
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "DELETE_DEBT",
            code        : CODES[body.type],
            liquidities : -body.remaining_value,
            old_value   : body,
            new_value   : null,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
        context.dispatch('delete_debt', body._id)
    },
    // Création
    action_buy_property(context, body) {
        const CODES = {
            main_property: 'CREATION_MAIN_PROPERTY',
            secondary: 'CREATION_SECONDARY_PROPERTY',
            location: 'CREATION_LOCATION_PROPERTY',
            scpi: 'CREATION_SCPI_PROPERTY',
            parking: 'CREATION_LOCATION_PROPERTY',
            land: 'CREATION_LAND_PROPERTY',
            agricultural_land: 'CREATION_AGRICULTURAL_LAND_PROPERTY',
            forest: 'CREATION_FOREST_PROPERTY',
            gfa: 'CREATION_GFA_PROPERTY',
            gff: 'CREATION_GFF_PROPERTY',
            gfv: 'CREATION_GFV_PROPERTY',
            office: 'CREATION_LOCATION_PROPERTY',
            warehouse: 'CREATION_LOCATION_PROPERTY',
            other: 'CREATION_PROPERTY',
        }

        let code = CODES[body.type]
        if (body.is_furnished) {
            code = 'CREATION_FURNISHED_LOCATION_PROPERTY'
        }

        const participation_value = context.getters.getParticipationValue(body.detention, body.value)
        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_PROPERTY",
            code        : code,
            liquidities : -participation_value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_buy_asset(context, body) {
        const CODES = {
            cc: "CREATION_SAVINGS_ASSETS",
            livreta: "CREATION_SAVINGS_ASSETS",
            ldds: "CREATION_SAVINGS_ASSETS",
            csl: "CREATION_SAVINGS_ASSETS",
            cat: "CREATION_CAT_ASSETS",
            capi: "CREATION_CAPI_ASSETS",
            pel: "CREATION_PEL_ASSETS",
            cel: "CREATION_CEL_ASSETS",
            lep: "CREATION_LEP_ASSETS",
            pea: "CREATION_PEA_ASSETS",
            cto: "CREATION_CTO_ASSETS",
            social_share: "CREATION_SOCIAL_SHARES_ASSETS",
            pep: "CREATION_PEP_ASSETS",
            goodwill: "CREATION_GOODWILL_ASSETS",
            ei: "CREATION_INDIVIDUAL_ACTIVITY_ASSETS",
            pee: "CREATION_PEE_ASSETS",
            cca: "CREATION_CCA_ASSETS",
            perin: "CREATION_PERIN_ASSETS",
            percol: "CREATION_PERCOL_ASSETS",
            pero: "CREATION_PERO_ASSETS",
            perco: "CREATION_PERCO_ASSETS",
            fcpi: "CREATION_FCPI_ASSETS",
            fip: "CREATION_FIP_ASSETS",
            fcpr: "CREATION_FCPR_ASSETS",
            fpci: "CREATION_FPCI_ASSETS",
            eltif: "CREATION_ELTIF_ASSETS",
            other: "CREATION_SAVINGS_ASSETS",
        }

        let participation_value = context.getters.getParticipationValue(body.detention, body.value)
        if (body.type == 'cca') participation_value = participation_value * -1

        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_FINANCIAL_ASSET",
            code        : CODES[body.type],
            liquidities : -participation_value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_buy_movable(context, body) {
        const CODES = {
            car               : "CREATION_CAR_MOVABLE",
            furnitures        : "CREATION_FURNITURE_MOVABLE",
            art_objects       : "CREATION_ART_MOVABLE",
            precious_stones   : "CREATION_PRECIOUS_STONES_MOVABLE",
            precious_metals   : "CREATION_PRECIOUS_METALS_MOVABLE",
            plane             : "CREATION_PLANE_MOVABLE",
            boat              : "CREATION_BOAT_MOVABLE",
            horse             : "CREATION_HORSES_MOVABLE",
            other             : "CREATION_MOVABLE",
        }

        const participation_value = context.getters.getParticipationValue(body.detention, body.value)
        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_MOVABLE_ASSET",
            code        : CODES[body.type],
            liquidities : -participation_value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_buy_society(context, body) {
        const CODES = {
            sarl   : 'CREATION_SOCIETY', 
            sas    : 'CREATION_SOCIETY',  
            selarl : "CREATION_LIBERAL_SOCIETY",  
            snc    : "CREATION_SOCIETY",  
            scp    : "CREATION_SOCIETY",  
            sa     : "CREATION_SOCIETY",  
            sasu   : "CREATION_INDIVIDUAL_SOCIETY",  
            eurl   : "CREATION_INDIVIDUAL_SOCIETY", 
            sci    : "CREATION_SCI_SOCIETY",
            gfa    : "CREATION_GFA_SOCIETY",
            other  : "CREATION_SOCIETY",
        }

        const participation_value = context.getters.getParticipationValue(body.detention, body.value)
        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_SOCIETY",
            code        : CODES[body.type],
            liquidities : -participation_value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_buy_insurance(context, body) {
        const INSURANCE_CODES = {
            life      : 'CREATION_LIFE_INSURANCE',
            lux       : 'CREATION_LUX_INSURANCE',
            pep       : 'CREATION_PEP_INSURANCE',
            perin     : 'CREATION_PERIN_INSURANCE',
            percol    : 'CREATION_PERCOL_INSURANCE',
            pero      : 'CREATION_PERO_INSURANCE',
            perp      : 'CREATION_PERP_INSURANCE',
            madelin   : 'CREATION_MADELIN_INSURANCE',
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_INSURANCE",
            code        : INSURANCE_CODES[body.type],
            liquidities : -body.value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_add_debt(context, body) {
        const CODES = {
            insured_loan: "CREATION_INSURED_DEBT",
            uninsured_loan: "CREATION_UNINSURED_DEBT",
            property_loan: "CREATION_PROPERTY_DEBT",
            professionnal_loan: 'CREATION_PROFESSIONNAL_DEBT',
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_DEBT",
            code        : CODES[body.type],
            liquidities : body.value,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_add_gift(context, body) {
        const CODES_PP = {
            property  : 'CREATION_PROPERTY_GIFT',
            financial : 'CREATION_FINANCIAL_GIFT',
            society   : 'CREATION_SOCIETY_GIFT',
            dutreil   : 'CREATION_DUTREIL_GIFT',
            movable   : 'CREATION_MOVABLE_GIFT',
            liquidity : 'CREATION_LIQUIDITY_GIFT',
            manual    : 'CREATION_MANUAL_GIFT',
        }

        const CODES_NP = {
            property  : 'CREATION_DISMEMBERED_PROPERTY_GIFT',
            financial : 'CREATION_DISMEMBERED_FINANCIAL_GIFT',
            society   : 'CREATION_DISMEMBERED_SOCIETY_GIFT',
            dutreil   : 'CREATION_DISMEMBERED_DUTREIL_GIFT',
            movable   : 'CREATION_DISMEMBERED_MOVABLE_GIFT',
            liquidity : 'CREATION_DISMEMBERED_MANUAL_GIFT', // 790G possible uniquement en PP
            manual    : 'CREATION_DISMEMBERED_MANUAL_GIFT',
        }

        let liquidities = 0

        if (['liquidity', 'manual'].includes(body.type)) {
            liquidities = -body.value
        }

        let code = CODES_PP[body.type]
        if (body.is_dismembered) {
            code = CODES_NP[body.type]
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_GIFT",
            code        : code,
            liquidities : liquidities,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    action_add_will(context, body) {
        const CODES = {
            universal: "CREATION_UNIVERSAL_WILL",
            legs: "CREATION_LEGS_WILL"
        }

        const action = {
            scenarioId  : body.scenarioId,
            category    : "CREATION_WILL",
            code        : CODES[body.type],
            liquidities : -150,
            old_value   : null,
            new_value   : body,
            date: new Date(),
        }

        axios.post(context.getters.get_api_url + 'referential/actions/', action, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('addAction', action)
    },
    // Modifications
    action_modify_couple(context, body) {
        const current = context.getters.getContract

        if (current.situation != body.situation) {
            if (body.situation == 'married') {
                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "COUPLE_MODIFICATION",
                    code        : "COUPLE_NEW_WEDDING",
                    liquidities : 0,
                    old_value   : {
                        situation: current.situation,
                        regmat: current.regmat,
                    },
                    new_value   : {
                        situation: body.situation,
                        regmat: body.regmat,
                    },
                    date: new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)

            } else if (body.situation == 'pacs') {
                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "COUPLE_MODIFICATION",
                    code        : "COUPLE_NEW_PACS",
                    liquidities : 0,
                    old_value   : {
                        situation: current.situation,
                        regmat: current.regmat,
                    },
                    new_value   : {
                        situation: body.situation,
                        regmat: body.regmat,
                    },
                    date: new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)
            }

        } else if (current.regmat != body.regmat) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_MARITAL_REGIME_MODIFICATION",
                liquidities : 0,
                old_value   : {
                    regmat: current.regmat,
                },
                new_value   : {
                    regmat: body.regmat,
                },
                date: new Date(),
            }
            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.preciput == false && body.preciput == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_NEW_PRECIPUT",
                liquidities : -1000,
                old_value   : {
                    preciput: false,
                    preciput_detail: [],
                },
                new_value   : {
                    preciput: true,
                    preciput_detail: body.preciput_detail,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.preciput == true && body.preciput == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_PRECIPUT",
                liquidities : -1000,
                old_value   : {
                    preciput: true,
                    preciput_detail: current.preciput_detail,
                },
                new_value   : {
                    preciput: false,
                    preciput_detail: [],
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.full_attribution == false && body.full_attribution == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_ADD_FULL_ATTRIBUTION",
                liquidities : 0,
                old_value   : {
                    full_attribution: false,
                },
                new_value   : {
                    full_attribution: true,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.full_attribution == true && body.full_attribution == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_FULL_ATTRIBUTION",
                liquidities : 0,
                old_value   : {
                    full_attribution: true,
                },
                new_value   : {
                    full_attribution: false,
                },
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.acquest_society == false && body.acquest_society == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_ADD_AQUEST_SOCIETY",
                liquidities : 0,
                old_value   : {
                    acquest_society: false,
                },
                new_value   : {
                    acquest_society: true,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.acquest_society == true && body.acquest_society == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_AQUEST_SOCIETY",
                liquidities : 0,
                old_value   : {
                    acquest_society: true,
                },
                new_value   : {
                    acquest_society: false,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.ddv_userforspouse == false && body.ddv_userforspouse == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_ADD_DDV_USER",
                liquidities : 0,
                old_value   : {
                    ddv_userforspouse: false,
                },
                new_value   : {
                    ddv_userforspouse: true,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.ddv_userforspouse == true && body.ddv_userforspouse == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_DDV_USER",
                liquidities : 0,
                old_value   : {
                    ddv_userforspouse: true,
                },
                new_value   : {
                    ddv_userforspouse: false,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.ddv_spouseforuser == false && body.ddv_spouseforuser == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_ADD_DDV_SPOUSE",
                liquidities : 0,
                old_value   : {
                    ddv_spouseforuser: false,
                },
                new_value   : {
                    ddv_spouseforuser: true,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.ddv_spouseforuser == true && body.ddv_spouseforuser == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_DDV_SPOUSE",
                liquidities : 0,
                old_value   : {
                    ddv_spouseforuser: true,
                },
                new_value   : {
                    ddv_spouseforuser: false,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.inegal_repartition == false && body.inegal_repartition == true) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_NEW_INEGAL_REPARTITION",
                liquidities : 0,
                old_value   : {
                    inegal_repartition: false,
                    inegal_repartition_detail: [],
                },
                new_value   : {
                    inegal_repartition: true,
                    inegal_repartition_detail: body.inegal_repartition_detail,
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.inegal_repartition == true && body.inegal_repartition == false) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "COUPLE_MODIFICATION",
                code        : "COUPLE_DELETE_INEGAL_REPARTITION",
                liquidities : 0,
                old_value   : {
                    inegal_repartition: true,
                    inegal_repartition_detail: current.inegal_repartition_detail,
                },
                new_value   : {
                    inegal_repartition: false,
                    inegal_repartition_detail: [],
                },
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_contract', body)
    },
    action_modify_person(context, body) {
        const oldValue = context.getters.getAllPersons.find(p => p._id == body._id)
        console.log(oldValue)
        if (oldValue.role == 'child') {
            if (!oldValue.parent2 && body.parent2) {
                // Adoption de l'enfant du conjoint
                const CODES = {
                    full: "SPOUSE_CHILD_ADOPTION_FULL",
                    simple: "SPOUSE_CHILD_ADOPTION_SIMPLE"
                }

                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "PERSON_MODIFICATION",
                    code        : CODES[body.adoption_mode],
                    liquidities : 0,
                    old_value   : oldValue,
                    new_value   : body,
                    date: new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })
    
                context.commit('addAction', action)
            }
        } else if (oldValue.role == 'other' && body.role == "child") {
            // Adoption d'un tiers'
            const CODES = {
                full: "CHILD_ADOPTION_FULL",
                simple: "CHILD_ADOPTION_SIMPLE"
            }

            const action = {
                scenarioId  : body.scenarioId,
                category    : "PERSON_MODIFICATION",
                code        : CODES[body.adoption_mode],
                liquidities : 0,
                old_value   : oldValue,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_person', body)
    },
    action_modify_property(context, body) {

        const current = context.getters.getAllProperties.find(p => p._id == body._id)

        
        const old_owners = current.detention.map(p => p.owner_id)
        const new_owners = body.detention.map(p => p.owner_id)

        if (current.detention.length == 1 && body.detention.length == 2
            && ( old_owners.includes(context.getters.userId) || old_owners.includes(context.getters.spouseId) )
            && new_owners.includes(context.getters.userId) && new_owners.includes(context.getters.spouseId)
        ) {
            // Apport à la communauté
            const fees = notaryFeesContributions(body.value, true, context.getters.hasCommunityContribution)
            const action = {
                scenarioId  : body.scenarioId,
                category    : "PROPERTY_MODIFICATION",
                code        : "PROPERTY_COMMUNITY_CONTRIBUTION",
                liquidities : -fees,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        }

        if (body.detention.length == 1
            && ( old_owners.includes(context.getters.userId) || old_owners.includes(context.getters.spouseId) )
            && context.getters.getSocietyIdsList.includes(new_owners[0])
        ) {
            // Apport à une société
            const society = context.getters.getAllSocieties.find(s => s._id == new_owners[0])
            let fees = 0
            if (society.taxOption == 'is') {
                fees = body.value * 0.05
            }

            const action = {
                scenarioId  : body.scenarioId,
                category    : "PROPERTY_MODIFICATION",
                code        : "PROPERTY_SOCIETY_CONTRIBUTION",
                liquidities : -fees,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (current.value < body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "PROPERTY_INVESTMENT",
                code        : "INVESTMENT_PROPERTY_MODIFICATION",
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.value > body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "PROPERTY_SELL",
                code        : "SELL_PROPERTY_MODIFICATION",
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_property', body)
    },
    action_modify_asset(context, body) {
        const CODES = {
            cc: "_SAVINGS_ASSETS",
            livreta: "_SAVINGS_ASSETS",
            ldds: "_SAVINGS_ASSETS",
            csl: "_SAVINGS_ASSETS",
            cat: "_CAT_ASSETS",
            capi: "_CAPI_ASSETS",
            pel: "_PEL_ASSETS",
            cel: "_CEL_ASSETS",
            lep: "_LEP_ASSETS",
            pea: "_PEA_ASSETS",
            cto: "_CTO_ASSETS",
            social_share: "_SOCIAL_SHARES_ASSETS",
            pep: "_PEP_ASSETS",
            goodwill: "_GOODWILL_ASSETS",
            ei: "_INDIVIDUAL_ACTIVITY_ASSETS",
            pee: "_PEE_ASSETS",
            cca: "_CCA_ASSETS",
            perin: "_PERIN_ASSETS",
            percol: "_PERCOL_ASSETS",
            pero: "_PERO_ASSETS",
            perco: "_PERCO_ASSETS",
            fcpi: "_FCPI_ASSETS",
            fip: "_FIP_ASSETS",
            fcpr: "_FCPR_ASSETS",
            fpci: "_FPCI_ASSETS",
            eltif: "_ELTIF_ASSETS",
            other: "_SAVINGS_ASSETS",
        }

        const current = context.getters.getAllAssets.find(p => p._id == body._id)

        if (current.detention.length != body.detention.length) {
            const old_owners = current.detention.map(p => p.owner_id)
            const new_owners = body.detention.map(p => p.owner_id)

            if (current.detention.length == 1 && body.detention.length == 2
                && ( old_owners.includes(context.getters.userId) || old_owners.includes(context.getters.spouseId) )
                && new_owners.includes(context.getters.userId) && new_owners.includes(context.getters.spouseId)
            ) {
                // Apport à la communauté
                const fees = notaryFeesContributions(body.value, false, context.getters.hasCommunityContribution)

                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "ASSET_MODIFICATION",
                    code        : "ASSET_COMMUNITY_CONTRIBUTION",
                    liquidities : -fees,
                    old_value   : current,
                    new_value   : body,
                    date: new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)
            } 
        }

        if (current.value < body.value) {
            let liquidities = current.value - body.value
            if (body.type == 'cca') liquidities = liquidities * -1

            const action = {
                scenarioId  : body.scenarioId,
                category    : "ASSET_INVESTMENT",
                code        : "INVESTMENT" + CODES[body.type],
                liquidities : liquidities,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.value > body.value) {
            let liquidities = current.value - body.value
            if (body.type == 'cca') liquidities = liquidities * -1

            const action = {
                scenarioId  : body.scenarioId,
                category    : "ASSET_SELL",
                code        : "SELL" + CODES[body.type],
                liquidities : liquidities,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_asset', body)
    },
    action_modify_movable(context, body) {
        const CODES = {
            car               : "_CAR_MOVABLE",
            furnitures        : "_FURNITURE_MOVABLE",
            art_objects       : "_ART_MOVABLE",
            precious_stones   : "_PRECIOUS_STONES_MOVABLE",
            precious_metals   : "_PRECIOUS_METALS_MOVABLE",
            plane             : "_PLANE_MOVABLE",
            boat              : "_BOAT_MOVABLE",
            horse             : "_HORSES_MOVABLE",
            other             : "_MOVABLE",
        }

        const current = context.getters.getAllMovable.find(p => p._id == body._id)

        if (current.detention.length != body.detention.length) {
            const old_owners = current.detention.map(p => p.owner_id)
            const new_owners = body.detention.map(p => p.owner_id)

            if (current.detention.length == 1 && body.detention.length == 2
                && ( old_owners.includes(context.getters.userId) || old_owners.includes(context.getters.spouseId) )
                && new_owners.includes(context.getters.userId) && new_owners.includes(context.getters.spouseId)
            ) {
                // Apport à la communauté
                const fees = notaryFeesContributions(body.value, false, context.getters.hasCommunityContribution)

                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "MOVABLE_MODIFICATION",
                    code        : "MOVABLE_COMMUNITY_CONTRIBUTION",
                    liquidities : -fees,
                    old_value   : current,
                    new_value   : body,
                    date: new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)
            } 
        }

        if (current.value < body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "MOVABLE_INVESTMENT",
                code        : "INVESTMENT" + CODES[body.type],
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.value > body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "MOVABLE_SELL",
                code        : "SELL" + CODES[body.type],
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_movable', body)
    },
    action_modify_society(context, body) {
        const current = context.getters.getAllSocieties.find(p => p._id == body._id)

        if (current.detention.length != body.detention.length) {
            const old_owners = current.detention.map(p => p.owner_id)
            const new_owners = body.detention.map(p => p.owner_id)

            if (current.detention.length == 1 && body.detention.length == 2
                && ( old_owners.includes(context.getters.userId) || old_owners.includes(context.getters.spouseId) )
                && new_owners.includes(context.getters.userId) && new_owners.includes(context.getters.spouseId)
            ) {
                // Apport à la communauté
                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "SOCIETY_MODIFICATION",
                    code        : "SOCIETY_COMMUNITY_CONTRIBUTION",
                    liquidities : 0,
                    old_value   : current,
                    new_value   : body,
                    date        : new Date(),
                }

                axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)
            }
        }

        if (current.taxOption != body.taxOption && body.type == 'sci') {
            // Changement de régime fiscal de SCI
            let code = "SCI_TAX_TO_IR"
            if (current.taxOption == 'ir') {
                code = "SCI_TAX_TO_IS"
            }

            const action = {
                scenarioId  : body.scenarioId,
                category    : "SOCIETY_MODIFICATION",
                code        : code,
                liquidities : 0,
                old_value   : current,
                new_value   : body,
                date        : new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

            axios.post(context.getters.get_api_url + 'clients/society-tax-comparison/', {
                clientId   : body.clientId,
                scenarioId : body.scenarioId,
                societyId  : body._id,
            }, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })
        }

        context.dispatch('modify_society', body)
    },
    async action_modify_insurance(context, body) {
        const CODES = {
            life      : '_LIFE_INSURANCE',
            lux       : '_LUX_INSURANCE',
            pep       : '_PEP_INSURANCE',
            perin     : '_PERIN_INSURANCE',
            percol    : '_PERCOL_INSURANCE',
            pero      : '_PERO_INSURANCE',
            perp      : '_PERP_INSURANCE',
            madelin   : '_MADELIN_INSURANCE',
        }

        const current = context.getters.getAllInsurances.find(p => p._id == body._id)

        if (current.value < body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "INSURANCE_INVESTMENT",
                code        : "INVESTMENT" + CODES[body.type],
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            await axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else if (current.value > body.value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "INSURANCE_SELL",
                code        : "SELL" + CODES[body.type],
                liquidities : current.value - body.value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            await axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        if (!current.clause.dismemberment && body.clause.dismemberment) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "INSURANCE_MODIFICATION",
                code        : "INSURANCE_CLAUSE_DISMEMBERMENT",
                liquidities : 0,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            await axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)

        } else {
            if (
                current.clause.dismemberment != body.clause.dismemberment
                || current.clause.default != body.clause.default
                || current.clause.ranks.length != body.clause.ranks.length
            ) {
                const action = {
                    scenarioId  : body.scenarioId,
                    category    : "INSURANCE_MODIFICATION",
                    code        : "INSURANCE_CLAUSE_MODIFICATION",
                    liquidities : 0,
                    old_value   : current,
                    new_value   : body,
                    date: new Date(),
                }

                await axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                    headers: { authorization : 'Bearer ' + context.getters.get_token}
                })

                context.commit('addAction', action)
            }
        }

        context.dispatch('modify_insurance', body)
    },
    action_modify_debt(context, body) {
        const current = context.getters.getAllDebts.find(p => p._id == body._id)

        if (current.remaining_value > body.remaining_value) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "DEBT_REFUND",
                code        : "INCOMPLETE_DEBT_REFUND",
                liquidities : body.remaining_value - current.remaining_value,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_debt', body)
    },
    action_modify_gift(context, body) {
        const current = context.getters.getAllGifts.find(p => p._id == body._id)

        if (!body.current_value) {
            body.current_value = body.value
        }

        if (!current.is_shared && body.is_shared) {
            const action = {
                scenarioId  : body.scenarioId,
                category    : "GIFT_MODIFICATION",
                code        : "SHARING_GIFT_REINTEGRATION",
                liquidities : ( body.current_value * 0.025 ) * -1,
                old_value   : current,
                new_value   : body,
                date: new Date(),
            }

            axios.post(context.getters.get_api_url + 'referential/actions/', action, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })

            context.commit('addAction', action)
        }

        context.dispatch('modify_gift', body)
    },
}