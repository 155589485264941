

exports.capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

exports.toEuro = (value, nbDigits) => {
    try {
        if (nbDigits == undefined) nbDigits = 0
            
        const stringValue = value.toString()
        const splitAtDigit = stringValue.split('.')

        let arrayValue = splitAtDigit[0].split('')
        arrayValue.reverse()
        
        let count = 0
        let result = []

        arrayValue.forEach(number => {
            count ++
            if (count == 4 && number != '-') {
                result.push('.')
                count = 1
            }
                
            result.push(number)
        });
        result.reverse()
        result = result.join('')

        if (nbDigits > 0)
            result = result + ','

        for (let i = 0; i < nbDigits; i++) {
            if (splitAtDigit[1] == undefined || [i] == undefined)
                result = result + "0"
            else
                result = result + splitAtDigit[1][i]
        }

        return result + '€'
        
    } catch (err) {
        return value
    }
}

exports.getRandomId = () => {
    const random1 = Math.random();
    const random2 = Math.random();
    return 'R' + Math.round( (random1 * random2) * 100000000000000000 )
}

exports.vpm = (value, rate, duration) => {
    let n = value * ( rate / 12 )
    let d = 1 - Math.pow(1 + rate / 12, -duration)
    return Math.ceil(n / d)
}

exports.currentDate = () => {
    const date = new Date()
    function stringTransform(int) {
        if (int.toString().length < 2)
            return '0' + int
        else
            return int
    }

    const year = date.getFullYear()
    const month = stringTransform(date.getMonth() + 1)
    const day = stringTransform(date.getDate())
  
    return year + '-' + month + '-' + day
}

exports.getAge = (date) => {
    const dob = new Date(date)
    let diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

exports.nbMonth = (start_date, end_date) => {
    const dateActuelle = new Date(start_date);
    const dateCible = new Date(end_date);

    if (dateCible < dateActuelle) {
        return 0
    }

    const annees = dateCible.getFullYear() - dateActuelle.getFullYear();
    const mois = dateCible.getMonth() - dateActuelle.getMonth();

    return annees * 12 + mois;
},

exports.dateToString = (date, isEnVersion) => {
    if (!date) return undefined

    const d = new Date(date)
    const year = d.getFullYear(d)
    let month = d.getMonth() + 1
    let day = d.getDate()

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }

    if (isEnVersion) {
        return year + '-' + month + '-' + day
    } else {
        return day + '/' + month + '/' + year
    }
}