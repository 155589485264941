<template>
    <div class="library-admin">
        <h1 class="page-title">Administration de la librairie</h1>
        <div class="separator"></div>
        
        <ul>
            <AdminSection 
                v-for="MainSection in mainSections" :key="MainSection._id"
                :sectionId="MainSection._id"
                :sectionArray="sections"
                :textArray="texts"
                @loadSections="loadSections"
                @loadText="loadText"
            />

            <AddSection
                :parent_section="undefined"
                @loadSections="loadSections"
            />
        </ul>
    </div>
</template>

<script>
import axios from 'axios'
import AdminSection from '../components/AdminSection.vue'
import AddSection from '../components/AddSection.vue'

export default {
    components: {
        AdminSection,
        AddSection,
    },
    data() {
        return {
            sections: [],
            texts: [],
            addMode: false,
            newSectionBody: {
                title: '',
                section: undefined,
                isMain: true,
            }
        }
    },
    methods: {
        async loadSections() {
            const res = await axios.get(this.$store.getters.get_api_url +'library/library-section/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.sections = res.data
        },
        async loadText() {
            const res = await axios.get(this.$store.getters.get_api_url +'library/library-text/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.texts = res.data
        },
    },
    computed: {
        mainSections() {
            return this.sections.filter(s => s.isMain)
        }
    },
    mounted() {
        this.loadSections()
        this.loadText()
    }
}
</script>

<style scoped>
.library-admin {
    padding: 30px 10%;
}
.page-title {
    color: rgb(2, 2, 86);
    padding: 0;
    margin: 0;
}
.separator {
    height: 1px;
    width: 100%;
    background-color: lightgrey;
}

</style>