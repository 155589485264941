<template>
    <div class="page-container">
        <h3>Estimation de la succession</h3>

        <div class="actions-box">
            <div class="action-btn" @click="goToKyc">Optimiser</div>
        </div>

        <LegacyResult :isOriginal="true"/>
    </div>
</template>

<script>
import LegacyResult from '../../../results/Legacy.vue'
export default {
    components: {
        LegacyResult,
    },
    methods: {
        goToKyc() {
            this.$router.replace('ClientProfileKyc')
        }
    }
}
</script>

<style scoped>

.page-container {
    position: relative;
}

.actions-box {
    position: absolute;
    right: 10px;
    top: 10px;
}

.action-btn {
    cursor: pointer;
    padding: 10px 40px;
    background-color: orange;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.action-btn:hover {
    background-color: rgba(255, 166, 0, 0.831)
}
</style>