export default {
    getParticipationValue : (_state, _getters, _rootState, rootGetters) => (detention, value) => {
        const userId = rootGetters.userId
        const spouseId = rootGetters.spouseId

        const clients_parts = detention.filter(p => [userId, spouseId].includes(p.owner_id))
        const user_parts    = detention.filter(p => userId == p.owner_id)
        const spouse_parts  = detention.filter(p => spouseId == p.owner_id)

        const pp_percentage        = clients_parts.filter(p => p.right == 'pp').map(p => p.part).reduce((sum, current) => sum + current, 0) // Pourcentage détenu en PP
        const us_percentage_user   = user_parts.filter(p => p.right == 'us').map(p => p.part).reduce((sum, current) => sum + current, 0) // Pourcentage détenu en US par l'utilisateur
        const us_percentage_spouse = spouse_parts.filter(p => p.right == 'us').map(p => p.part).reduce((sum, current) => sum + current, 0) // Pourcentage détenu en US par le conjoint
        const np_percentage        = clients_parts.filter(p => p.right == 'np').map(p => p.part).reduce((sum, current) => sum + current, 0) // Pourcentage détenu en NP

        const pp_value        = value * pp_percentage // Valeur de la participation en PP
        const us_value_user   = value * us_percentage_user * rootGetters.usValue(userId) // Valeur de la participation en US de l'utlisateur
        const us_value_spouse = value * us_percentage_spouse * rootGetters.usValue(spouseId) // Valeur de la participation en US du conjoint
        const np_value        = value * np_percentage * 0.8 // Valeur de la participation en nue-propriété (80% est une valeur arbitraire car on ne connait pas l'âge de l'usufruitier)

        const participation_value = pp_value + us_value_user + us_value_spouse + np_value // valeur totale de la participation

        return participation_value
    },
    getActionList(state) {
        return state.actions
    },
    hasCommunityContribution(state) {
        const contributions = state.actions.filter(a => ['PROPERTY_COMMUNITY_CONTRIBUTION', 'ASSET_COMMUNITY_CONTRIBUTION', 'MOVABLE_COMMUNITY_CONTRIBUTION'].includes(a.code))

        if (contributions.length > 0) {
            return true
        } else {
            return false
        }
    },
    codeTranslations() {
        return {
            DELETE_MAIN_PROPERTY: "Vente de la résidence principale",
            DELETE_SECONDARY_PROPERTY: "Vente d'une résidence secondaire",
            DELETE_LOCATION_PROPERTY: "Vente d'un bien locatif",
            DELETE_SCPI_PROPERTY: "Vente de parts de SCPI",
            DELETE_LAND_PROPERTY: "Vente d'un terrain",
            DELETE_FOREST_PROPERTY: "Vente d'une forêt",
            DELETE_GFA_PROPERTY: "Vente de parts de GFA",
            DELETE_GFF_PROPERTY: "Vente de part de GFF",
            DELETE_GFV_PROPERTY: "Vente de parts de GFV",
            DELETE_PROPERTY: "Vente d'un bien immobilier",
            DELETE_SAVINGS_ASSETS: "Retrait de l'épargne bancaire",
            DELETE_CAT_ASSETS: "Retrait d'un compte à terme",
            DELETE_CAPI_ASSETS: "Rachat d'un contrat de capitalisaton",
            DELETE_PEL_ASSETS: "Retrait d'un PEL",
            DELETE_CEL_ASSETS: "Retrait d'un CEL",
            DELETE_PEA_ASSETS: "Retrait d'un PEA",
            DELETE_CTO_ASSETS: "Vente de titres", 
            DELETE_SOCIAL_SHARES_ASSETS: "Vente de parts sociales",
            DELETE_PEP_ASSETS: "Retrait d'un Plan d'Épargne Populaire (PEP)",
            DELETE_GOODWILL_ASSETS: "Vente d'un fonds de commerce",
            DELETE_INDIVIDUAL_ACTIVITY_ASSETS: "Vente d'un entreprise individuelle",
            DELETE_PEE_ASSETS: "Retrait d'un plan d'épargne entreprise (PEE)",
            DELETE_CCA_ASSETS: "Remboursement d'un compte courant d'associé",
            DELETE_PERIN_ASSETS: "Retrait d'un PER compte-titres",
            DELETE_PERCOL_ASSETS: "Retrait d'un PERCOL compte-titres",
            DELETE_PERO_ASSETS: "Retrait d'un PER compte-titres",
            DELETE_PERCO_ASSETS: "Retrait d'un PERCO",
            DELETE_FCPI_ASSETS: "Retrait de parts de FCPI",
            DELETE_FIP_ASSETS: "Retrait de parts de FIP",
            DELETE_FCPR_ASSETS: "Vente de parts de FCPR",
            DELETE_FPCI_ASSETS: "Vente de parts de FPCI",
            DELETE_ELTIF_ASSETS: "Vente de parts de ELTIF",
            DELETE_SOCIETY: "Vente d'une société",
            DELETE_LIFE_INSURANCE: "Rachat d'un contrat d'assurance vie",
            DELETE_LUX_INSURANCE: "Rachat d'un contrat d'assurance vie luxembourgeois",
            DELETE_PEP_INSURANCE: "Rachat d'un Plan d'Épargne Populaire (PEP)",
            DELETE_PERIN_INSURANCE: "Rachat d'un PERIN",
            DELETE_PERCOL_INSURANCE: "Rachat d'un PERCOL",
            DELETE_PERO_INSURANCE: "Rachat d'un PER",
            DELETE_PERP_INSURANCE: "Rachat d'un PERP",
            DELETE_MADELIN_INSURANCE: "Rachat d'un contrat retraite Madelin",
            DELETE_INSURED_DEBT: "Remboursement d'un prêt",
            DELETE_UNINSURED_DEBT: "Remboursement d'un prêt",
            DELETE_PROPERTY_DEBT: "Remboursement d'un emprunt immobilier",
            DELETE_PROFESSIONNAL_DEBT: "Remboursement d'un emprunt professionnel",
            CREATION_MAIN_PROPERTY: "Achat de la résidence principale",
            CREATION_SECONDARY_PROPERTY: "Achat d'une résidence secondaire",
            CREATION_LOCATION_PROPERTY: "Achat d'un bien locatif",
            CREATION_FURNISHED_LOCATION_PROPERTY: "Achat d'un bien immobilier meublé",
            CREATION_SCPI_PROPERTY: "Achat de parts de SCPI",
            CREATION_LAND_PROPERTY: "Achat d'un terrain",
            CREATION_AGRICULTURAL_LAND_PROPERTY: "Achat de foncier agricole",
            CREATION_FOREST_PROPERTY: "Achat d'une fôret",
            CREATION_GFA_PROPERTY: "Achat de parts de GFA",
            CREATION_GFF_PROPERTY: "Achat de parts de GFF",
            CREATION_GFV_PROPERTY: "Achat de parts de GFV",
            CREATION_PROPERTY: "Achat d'un bien immobilier",
            CREATION_SAVINGS_ASSETS: "Versement sur l'épargne bancaire",
            CREATION_CAT_ASSETS: "Versement sur un compte à terme",
            CREATION_CAPI_ASSETS: "Versement sur un contrat de capitalisation",
            CREATION_PEL_ASSETS: "Versement sur un PEL",
            CREATION_CEL_ASSETS: "Versement sur un CEL",
            CREATION_LEP_ASSETS: "Versement sur un livret d'épargne populaire (LEP)",
            CREATION_PEA_ASSETS: "Versement sur un PEA",
            CREATION_CTO_ASSETS: "Achat de titres",
            CREATION_SOCIAL_SHARES_ASSETS: "Achat de parts sociales bancaires",
            CREATION_PEP_ASSETS: "Versement sur un Plan d'Épargne Populaire (PEP)",
            CREATION_GOODWILL_ASSETS: "Achat d'un fonds de commerce",
            CREATION_INDIVIDUAL_ACTIVITY_ASSETS: "Investissement dans une entreprise individuelle",
            CREATION_PEE_ASSETS: "Versement sur un PEE",
            CREATION_CCA_ASSETS: "Versement au compte courant d'associé",
            CREATION_PERIN_ASSETS: "Souscription d'un PERIN compte-titres",
            CREATION_PERCOL_ASSETS: "Souscriptuon d'un PERCOL compte-titres",
            CREATION_PERO_ASSETS: "Souscription d'un PERO compte-titres",
            CREATION_PERCO_ASSETS: "Souscription d'un PERCO compte-titres",
            CREATION_FCPI_ASSETS: "Achat de parts de FCPI",
            CREATION_FIP_ASSETS: "Achat de parts de FIP",
            CREATION_FCPR_ASSETS: "Achat de parts de FCPR",
            CREATION_FPCI_ASSETS: "Achat de parts de FPCI",
            CREATION_ELTIF_ASSETS: "Achat de parts de ELTIF",
            CREATION_CAR_MOVABLE: "Achat d'une voiture",
            CREATION_FURNITURE_MOVABLE: "Achat de meubles",
            CREATION_ART_MOVABLE: "Achat d'œuvres d'art",
            CREATION_PRECIOUS_STONES_MOVABLE: "Achat de pierres précieuses",
            CREATION_PRECIOUS_METALS_MOVABLE: "Achat de métaux précieux",
            CREATION_PLANE_MOVABLE: "Achat d'un avion",
            CREATION_BOAT_MOVABLE: "Achat d'un bateau",
            CREATION_HORSES_MOVABLE: "Achat de chevaux",
            CREATION_MOVABLE: "Achat d'un bien mobilier",
            CREATION_LIBERAL_SOCIETY: "Création d'une société d'exercice libéral",
            CREATION_INDIVIDUAL_SOCIETY: "Création d'un société unipersonnelle",
            CREATION_SCI_SOCIETY: "Création d'un SCI",
            CREATION_GFA_SOCIETY: "Création d'un GFA familial",
            CREATION_SOCIETY: "Création d'une société",
            CREATION_LIFE_INSURANCE: "Souscription d'un contrat d'assurance vie",
            CREATION_LUX_INSURANCE: "Souscription d'un contrat d'assurance vie luxembourgeois",
            CREATION_PEP_INSURANCE: "Souscription d'un Plan d'Épargne Populaire (PEP)",
            CREATION_PERIN_INSURANCE: "Souscription d'un Plan d'Épargne Retraite Individuel",
            CREATION_PERCOL_INSURANCE: "Souscription d'un PERCOL",
            CREATION_PERO_INSURANCE: "Souscription d'un PERO",
            CREATION_PERP_INSURANCE: "Souscription d'un PERP",
            CREATION_MADELIN_INSURANCE: "Souscription d'un contrat d'épargne retraite Madelin",
            CREATION_INSURED_DEBT: "Souscription d'un prêt assuré",
            CREATION_UNINSURED_DEBT: "Souscription d'un prêt non assuré",
            CREATION_PROPERTY_DEBT: "Souscription d'un emprunt immobilier",
            CREATION_PROFESSIONNAL_DEBT: "Souscription d'un emprunt professionnel",
            CREATION_PROPERTY_GIFT: "Réalisation d'une donation immobilière",
            CREATION_FINANCIAL_GIFT: "Réalisation d'une donation d'un actif financier",
            CREATION_SOCIETY_GIFT: "Réalisation d'une donation de parts de société",
            CREATION_DUTREIL_GIFT: "Réalisation d'une donation de parts de société avec pacte Dutreil",
            CREATION_MOVABLE_GIFT: "Réalisation d'une donation d'un bien mobilier",
            CREATION_LIQUIDITY_GIFT: "Réalisation d'un don familial de liquidités (790G du CGI)",
            CREATION_MANUAL_GIFT: "Réalisation d'un don manuel",
            CREATION_DISMEMBERED_PROPERTY_GIFT: "Réalisation d'une donation immobilière en nue-propriété",
            CREATION_DISMEMBERED_FINANCIAL_GIFT: "Réalisation d'une donation d'un actif financier en nue-propriété",
            CREATION_DISMEMBERED_SOCIETY_GIFT: "Réalisation d'une donation de parts de société en nue-propriété",
            CREATION_DISMEMBERED_DUTREIL_GIFT: "Réalisation d'une donation de parts de société avec pacte Dutreil en nue-propriété",
            CREATION_DISMEMBERED_MOVABLE_GIFT: "Réalisation d'une donation d'un bien mobilier en nue-propriété",
            CREATION_DISMEMBERED_MANUAL_GIFT: "Réalisation d'un don manuel en nue-propriété",
            CREATION_UNIVERSAL_WILL: "Rédaction d'un testament",
            CREATION_LEGS_WILL: "Rédaction d'un testament",
            COUPLE_NEW_WEDDING: "Conclusion d'un mariage",
            COUPLE_NEW_PACS: "Conclusion d'un PACS",
            COUPLE_MARITAL_REGIME_MODIFICATION: "Modification du régime matrimonial",
            COUPLE_NEW_PRECIPUT: "Ajout d'une clause de préciput",
            COUPLE_DELETE_PRECIPUT: "Retrait d'une clause de préciput",
            COUPLE_ADD_FULL_ATTRIBUTION: "Ajout d'une clause d'attribution intégrale",
            COUPLE_DELETE_FULL_ATTRIBUTION: "Retrait d'une clause d'attribution intégrale",
            COUPLE_ADD_AQUEST_SOCIETY: "Ajout d'une clause de société d'acquêts",
            COUPLE_DELETE_AQUEST_SOCIETY: "Retrait d'une clause de société d'acquêts",
            COUPLE_ADD_DDV_USER: "Rédaction d'une donation au dernier vivant",
            COUPLE_ADD_DDV_SPOUSE: "Rédaction d'une donation au dernier vivant",
            COUPLE_DELETE_DDV_USER: "Retrait d'une donation au dernier vivant",
            COUPLE_DELETE_DDV_SPOUSE: "Retrait d'une donation au dernier vivant",
            COUPLE_NEW_INEGAL_REPARTITION: "Ajout d'une clause de répartition inégale",
            COUPLE_DELETE_INEGAL_REPARTITION: "Retrait d'une clause de répartition inégale",
            SPOUSE_CHILD_ADOPTION_FULL: "Adoption plénière de l'enfant du conjoint",
            SPOUSE_CHILD_ADOPTION_SIMPLE: "Adoption simple de l'enfant du conjoint",
            CHILD_ADOPTION_FULL: "Adoption plénière d'un enfant",
            CHILD_ADOPTION_SIMPLE: "Adoption simple d'un enfant",
            PROPERTY_COMMUNITY_CONTRIBUTION: "Apport d'un bien immobilier à la communauté",
            PROPERTY_SOCIETY_CONTRIBUTION: "Apport d'un bien immobilier à une société",
            SCI_TAX_TO_IR : "Passage à l'IR d'une société civile immobilière",
            SCI_TAX_TO_IS : "Passage à l'IS d'une société civile immobilière",
            INVESTMENT_PROPERTY_MODIFICATION: "Investissement immobilier",
            SELL_PROPERTY_MODIFICATION: "Vente immobilière",
            ASSET_COMMUNITY_CONTRIBUTION: "Apport à la communauté d'un bien financier",
            INVESTMENT_SAVINGS_ASSETS: "Versement sur l'épargne bancaire",
            INVESTMENT_CAT_ASSETS: "Versement sur un compte à terme",
            INVESTMENT_CAPI_ASSETS: "Versement sur un contrat de capitalisation",
            INVESTMENT_PEL_ASSETS: "Versement sur un PEL",
            INVESTMENT_CEL_ASSETS: "Versement sur un CEL",
            INVESTMENT_LEP_ASSETS: "Versement sur un LEP",
            INVESTMENT_PEA_ASSETS: "Versement sur un PEA",
            INVESTMENT_CTO_ASSETS: "Achat de titres",
            INVESTMENT_SOCIAL_SHARES_ASSETS: "Achat de parts sociales bancaires",
            INVESTMENT_PEP_ASSETS: "Versement sur un PEP",
            INVESTMENT_GOODWILL_ASSETS: "Achat d'un fonds de commerce",
            INVESTMENT_INDIVIDUAL_ACTIVITY_ASSETS: "Achat d'un fonds de commerce",
            INVESTMENT_PEE_ASSETS: "Versement sur un PEE",
            INVESTMENT_CCA_ASSETS: "Versement en compte courant d'associé",
            INVESTMENT_PERIN_ASSETS: "Versement sur un PER compte-titres",
            INVESTMENT_PERCOL_ASSETS: "Versement sur un PERCOL compte-titres",
            INVESTMENT_PERO_ASSETS: "Versement sur un PERO compte-titres",
            INVESTMENT_PERCO_ASSETS: "Versement sur un PERCO compte-titres",
            INVESTMENT_FCPI_ASSETS: "Achat de parts de FCPI",
            INVESTMENT_FIP_ASSETS: "Achat de parts de FIP",
            INVESTMENT_FCPR_ASSETS: "Achat de parts de FCPR",
            INVESTMENT_FPCI_ASSETS: "Achat de parts de FPCI",
            INVESTMENT_ELTIF_ASSETS: "Achat de parts de ELTIF",
            SELL_SAVINGS_ASSETS: "Retrait de l'épargne bancaire",
            SELL_CAT_ASSETS: "Retrait d'un compte à terme",
            SELL_CAPI_ASSETS: "Rachat d'un contrat de capitalisation",
            SELL_PEL_ASSETS: "Retrait d'un PEL",
            SELL_CEL_ASSETS: "Retrait d'un CEL",
            SELL_LEP_ASSETS: "Retrait d'un LEP",
            SELL_PEA_ASSETS: "Retrait d'un PEA",
            SELL_CTO_ASSETS: "Vente de titres",
            SELL_SOCIAL_SHARES_ASSETS: "Vente de parts sociales bancaires",
            SELL_PEP_ASSETS: "Retrait d'un PEP",
            SELL_GOODWILL_ASSETS: "Vente d'un fonds de commerce",
            SELL_INDIVIDUAL_ACTIVITY_ASSETS: "Vente d'une entreprise individuelle",
            SELL_PEE_ASSETS: "Retrait d'un PEE",
            SELL_CCA_ASSETS: "Remboursement d'un compte courant d'associé",
            SELL_PERIN_ASSETS: "Retrait d'un PER compte-titres",
            SELL_PERCOL_ASSETS: "Retrait d'un PERCOL compte-titres",
            SELL_PERO_ASSETS: "Retrait d'un PERO compte-titres",
            SELL_PERCO_ASSETS: "Retrait d'un PERCO compte-titres",
            SELL_FCPI_ASSETS: "Remboursement de parts de FCPI",
            SELL_FIP_ASSETS: "Remboursement de parts de FIP",
            SELL_FCPR_ASSETS: "Vente de parts de FCPR",
            SELL_FPCI_ASSETS: "Vente de parts de FPCI",
            SELL_ELTIF_ASSETS: "Vente de parts de ELTIF",
            MOVABLE_COMMUNITY_CONTRIBUTION: "Apport d'un bien mobilier à la communauté",
            INVESTMENT_CAR_MOVABLE: "Achat de voitures",
            INVESTMENT_FURNITURE_MOVABLE: "Achat de meubles",
            INVESTMENT_ART_MOVABLE: "Achat d'art",
            INVESTMENT_PRECIOUS_STONES_MOVABLE: "Achat de pierres précieuses",
            INVESTMENT_PRECIOUS_METALS_MOVABLE: "Achat de métaux précieux",
            INVESTMENT_PLANE_MOVABLE: "Achat d'avion",
            INVESTMENT_BOAT_MOVABLE: "Achat de bateaux",
            INVESTMENT_HORSES_MOVABLE: "Achat de chevaux",
            SELL_CAR_MOVABLE: "Vente de voitures",
            SELL_FURNITURE_MOVABLE: "Vente de meubles",
            SELL_ART_MOVABLE: "Vente d'œeuvres d'art",
            SELL_PRECIOUS_STONES_MOVABLE: "Vente de pierres précieuses",
            SELL_PRECIOUS_METALS_MOVABLE: "Vente de métaux précieux",
            SELL_PLANE_MOVABLE: "Vente d'avions",
            SELL_BOAT_MOVABLE: "Vente de bateaux",
            SELL_HORSES_MOVABLE: "Vente de chevaux",
            SELL_MOVABLE: "Vente de biens mobiliers",
            SOCIETY_COMMUNITY_CONTRIBUTION: "Apport de parts de société à la communauté",
            INVESTMENT_LIFE_INSURANCE: "Versement sur un contrat d'assurance vie",
            INVESTMENT_LUX_INSURANCE: "Versement sur un contrat d'assurance vie luxembourgeois",
            INVESTMENT_PEP_INSURANCE: "Versement sur un PEP",
            INVESTMENT_PERIN_INSURANCE: "Versement sur un PERIN",
            INVESTMENT_PERCOL_INSURANCE: "Versement sur un PERCOL",
            INVESTMENT_PERO_INSURANCE: "Versement sur un PERO",
            INVESTMENT_PERP_INSURANCE: "Versement sur un PERP",
            INVESTMENT_MADELIN_INSURANCE: "Versement sur un contrat retraite Madelin",
            SELL_LIFE_INSURANCE: "Rachat d'un contrat d'assurance vie",
            SELL_LUX_INSURANCE: "Rachat d'un contrat d'assurance vie luxembourgeis",
            SELL_PEP_INSURANCE: "Rachat d'un PEP",
            SELL_PERIN_INSURANCE: "Rachat d'un PERIN",
            SELL_PERCOL_INSURANCE: "Rachat d'un PERCOL",
            SELL_PERO_INSURANCE: "Rachat d'un PERO",
            SELL_PERP_INSURANCE: "Rachat d'un PERP",
            SELL_MADELIN_INSURANCE: "Rachat d'un contrat retraite Madelin",
            INSURANCE_CLAUSE_DISMEMBERMENT: "Démembrement d'une clause bénéficiaire",
            INSURANCE_CLAUSE_MODIFICATION: "Modification d'une clause bénéficiaire",
            INCOMPLETE_DEBT_REFUND: "Remboursement partiel d'un emprunt",
            SHARING_GIFT_REINTEGRATION: "Réintégration d'une donation à une donation-partage",
        }
    },
}