<template>
     <section v-if="totalReduction > 0">
        <h2>Calcul des indéminités de réduction :</h2>
        <p>Il manque {{ toEuro(totalReduction) }} dans les biens existants pour allotir tous les héritiers de leur réserve. Il convient de calculer les indémnités de réduction à payer par les héritiers ayant trop perçu.</p>
        <p>L'indémnité est une indémnité en valeur et non en nature. On ne reprend pas les biens qu'un héritier à reçu en trop, on calcule le montant qu'il doit payer pour indémniser les réservataires.</p>
        <p>Il manque :</p>
        <ul>
            <li v-for="beneficiary in reductionBeneficiaries" :key="beneficiary._id">{{ toEuro(beneficiary.reduction.NP) }} à {{ beneficiary.firstname }} pour compléter sa réserve.</li>
        </ul>

        <p>Il faut donc déterminer le champs d'application des réductions. Elles s'imputent prioritairement sur les legs au prorata des droits de chacun des légataires. Puis sur les donations, de la plus récente à la plus ancienne. On ne réduit pas les donations et les legs consentis aux héritiers qui n'ont pas reçu leur réserve.</p>

        <table>
            <tr>
                <th>Transmission réductible</th>
                <th>Date</th>
                <th>Bénéficiaire</th>
                <th>Montant</th>
            </tr>

            <tr v-for="(reduction, index) in reductionList" :key="index">
                <td>{{ REDUCTION_TYPES_TRAD[reduction.reduction_on] }}</td>
                <td>{{ dateToString(reduction.date) }}</td>
                <td>{{ $store.getters.findPersonName(reduction.inheritor_id) }}</td>
                <td>{{ toEuro(reduction.reduction.NP) }}</td>
            </tr>
        </table>

    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
            REDUCTION_TYPES_TRAD: {
                legs: 'Legs reçus',
                gift: "Donation reçue"
            }
        }
    },
    computed: {
        reductionBeneficiaries() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.inheritors.filter(i => i.reduction.NP > 0)
            } else {
                return this.legacyResult.first_death.inheritors.filter(i => i.reduction.NP > 0)
            }
        },
        totalReduction() {
            return this.reductionBeneficiaries.map(i => i.reduction.NP).reduce((sum, current) => sum + current, 0)
        },
        reductionList() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.reduction.reductions_list
            } else {
                return this.legacyResult.first_death.reduction.reductions_list
            }
        }
    },
}
</script>

<style scoped src="./style.css"></style>