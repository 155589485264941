<template>
    <div class="library">
        <div class="navigation">
            <div class="menu">
                <router-link class="menu-option" to="/librairie/home">Librairie</router-link>
                <router-link class="menu-option" to="/librairie/administration">Administration</router-link>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>

</style>