<template>
    <div class="list-section">
        <h1 class="section-title">
            {{ title }}

            <add-btn v-if="!roles.includes('user')" type="person" :category="roles[0]"></add-btn>
        </h1>
        
        <table class="table-elements">
            <list-component class="list-element"
                v-for="person in personsList"
                :key="person._id"
                :object="person"
                type="family"
            />
        </table>
    </div>
</template>

<script>

export default {
    props: ['roles', 'title'],
    data() {
        return {}
    },
    computed: {
        personsList() {
            return this.$store.state.persons.filter(p => this.roles.includes(p.role))
        }
    }
}
</script>

