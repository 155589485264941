<template>
    <div class="scenario-overview">

        <h1 class="section-label">Simulations patrimoine global (2/4)</h1>
        
        <div class="scenarios-container">
            
            <div v-for="scenario in scenariosList" :key="scenario._id" class="scenario-frame">
                <div class="delete-scenario" @click="deleteScenario(scenario._id)" v-if="!scenario.isOriginal">X</div>

                <div :class="{'scenario-title-original': scenario.isOriginal}" class="scenario-title">
                    <h3> 
                        <switch-ux  :value="selectedScenario == scenario._id" @toggle="(bool) => updateSelectedScenario(scenario._id)" v-if="!scenario.isOriginal"/>
                        
                        <span v-if="scenario.isOriginal">Situation d'origine</span>
                        <span v-else>{{ scenario.label }}</span>
                    </h3>
                </div>
                
                <p class="scenario-content"><b>Date de création :</b> {{ dateToString(scenario.creationDate) }}</p>
                <p class="scenario-content"  v-if="scenario.isOriginal"><b>Attention :</b> En modifiant ce scenario, vous modifiez la situation d'origine des clients. Les nouveaux scenarios créés seront une copie de ce scenario d'origine qui reflète la situation actuelle.</p>

                <div class="access-frame">
                    <div class="access-btn" :class="{'access-btn-original': scenario.isOriginal}" @click="openScenario(scenario._id)">Accéder au scenario</div>
                </div>
            </div>

            <div class="add-frame">
                <div class="add-icon" @click="createScenario">+</div>
                <div class="add-text">Ajouter un scenario</div>
            </div>
        </div>
        
        <NewGifts v-if="selectedScenario"/>

        <ProperySells :selectedScenario="selectedScenario" v-if="selectedScenario"/>

        <DebtsOverview v-if="selectedScenario"/>

        <SocialCharges v-if="selectedScenario" :selectedScenario="selectedScenario"/>

        <SocietyTaxesComparison v-if="selectedScenario" :selectedScenario="selectedScenario"/>
    </div>
</template>

<script>
import axios from 'axios'

import SocialCharges from './overview/SocialCharges.vue'
import DebtsOverview from './overview/Loans.vue'
import ProperySells from './overview/PropertySells.vue'
import NewGifts from './overview/NewGifts.vue'
import SocietyTaxesComparison from './overview/SocietyTaxesComparison.vue'

export default {
    components: {
        SocialCharges,
        DebtsOverview,
        ProperySells,
        NewGifts,
        SocietyTaxesComparison,
    },
    data() {
        return {
            client: {},
            recommendation: {},
            scenariosList: [],
            selectedScenario: undefined,
        }
    },
    methods: {
        openScenario(scenarioId) {
            this.$router.replace('/client/' + this.$route.params.clientId + '/recommendation/' + this.$route.params.recommendationId + '/scenario/' + scenarioId + '/family')
        },
        async createScenario() {
            const label = prompt("Choisissez un nom de scenario", "Scenario");
            if (label && label != '') {
                const res = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                const originalScenarioId = res.data._id

                await axios.post(this.$store.getters.get_api_url + 'clients/duplicate-scenario/', {
                    clientId: this.$route.params.clientId,
                    recommendationId: this.$route.params.recommendationId,
                    originalScenarioId: originalScenarioId,
                    scenarioLabel: label,
                    
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.loadScenarios()

            } else {
                alert('Veuillez choisir un nom de scenario')
            }
        },
        async deleteScenario(scenarioId) {
            const confirmation = confirm('Supprimer le scenario ?')
            if (confirmation) {
                await axios.delete(this.$store.getters.get_api_url + 'clients/scenario/' + scenarioId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                await this.loadScenarios()
            }
        },
        async loadScenarios() {
            const scenario_response = await axios.get(this.$store.getters.get_api_url + 'clients/scenario/' + this.$route.params.recommendationId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            const original_scenario_response = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.scenariosList = [original_scenario_response.data, ...scenario_response.data]
        },
        updateSelectedScenario(_id) {
            if (this.selectedScenario == _id) {
                this.selectedScenario = null
            } else {
                this.selectedScenario = _id
            }
        },
        async saveSelectedScenario() {
            let body = this.recommendation
            body.selectedScenario = this.selectedScenario
            
            await axios.put(this.$store.getters.get_api_url + 'clients/recommendation/', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        },
    },
    computed: {
        originalScenario() {
            return this.scenariosList.filter(scenario => scenario.isOriginal == true)
        },
        originalScenarioId() {
            return this.originalScenario._id
        },
        
    },
    watch: {
        selectedScenario() {
            this.saveSelectedScenario()
            this.$store.dispatch('importScenario', this.selectedScenario)
        },
    },
    async beforeMount() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/find-client/' + this.$route.params.clientId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })
        this.client = res.data

        const recoResult = await axios.get(this.$store.getters.get_api_url + 'clients/find-recommendation/' + this.$route.params.recommendationId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.recommendation = recoResult.data

        this.selectedScenario = this.recommendation.selectedScenario

        this.$store.dispatch('importScenario', this.selectedScenario)
    },
    async mounted() {
        await this.loadScenarios()
    }
}
</script>

<style src="./overview/style.css" scoped></style>

<style scoped>
.scenario-overview {
    padding: 20px;
}

.scenarios-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.scenario-frame, .add-frame {
    position: relative;
    min-width: 400px;
    max-width: 400px;
    background-color: white;
    border-radius: 20px;
    min-height: 300px;
    margin-bottom: 10px;
}
.scenario-frame {
    padding-bottom: 50px;
}
.add-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(255, 145, 0);
}

.add-icon {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px rgb(255, 145, 0) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
}


.scenario-title {
    border-radius: 20px 20px 0 0;
    background-color: rgba(1, 1, 99, 0.688);
    color: white;
}
.scenario-title-original {
    background-color: rgb(255, 145, 0);
    color: white;
}
.scenario-title h3 {
    margin: 0;
    padding: 20px;
}

.scenario-title h3 span {
    margin-left: 10px;
}

.scenario-content {
    padding: 5px 30px;
    text-align: justify;
}

.delete-scenario {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
}

.access-frame {
    position: absolute;
    bottom: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.access-btn {
    cursor: pointer;
    background-color: rgb(255, 128, 0);
    padding: 15px;
    color: white;
    border-radius: 10px;
    font-weight: bold;
}
.access-btn-original {
    background-color: rgba(1, 1, 99, 0.688);
    color: white;
}

</style>