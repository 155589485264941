<template>
    <div class="modale-wrapper">

        <div class="form-data">
            <div class="form-section">
                <label>Nom du scenario</label>
                <input type="text" v-model="body.label">
            </div>

            <div class="incomes-section">
                <p>Revenus à remplacer</p>
                <div class="incomes-frame">
                    <div class="incomes" v-for="(income, index) in incomesList" :key="index">
                        <label>{{ income.label }}</label>
                        <input type="checkbox" :disabled="income.disabled" v-model="income.is_checked">
                    </div>
                </div>
            </div>

            <div class="form-section">
                <label>Budget de rémunération</label>
                <input type="number" v-model="body.budget">
            </div>

            <div class="detail-btn" @click="toggleDetails">{{ detailBtnLabel }}</div>

            <div class="details" v-if="showDetails">
                <div class="form-section">
                    <label>Activité</label>
                    <select v-model="body.activity">
                        <option v-for="(value, key) in activitiesList" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="form-section" v-if="body.activity == 'libérale'">
                    <label>Libéral réglementé ?</label>
                    <select v-model="body.is_regulated_liberal">
                        <option :value="true">OUI</option>
                        <option :value="false">NON</option>
                    </select>
                </div>

                <div class="form-section" v-if="body.is_regulated_liberal">
                    <label>Activité réglementée</label>
                    <select v-model="body.regulated_activity">
                        <option v-for="(value, key) in regulatedActivities" :key="key" :value="key">{{ value }}</option>
                    </select>
                </div>

                <div class="form-section">
                    <label>Date de début de l'activité</label>
                    <input type="date" v-model="body.creation_date">
                </div>

                <div class="form-section" v-if="new Date(body.creation_date) >= new Date('01/01/2024')">
                    <label>Bénéficiaire de l'ACRE</label>
                    <select v-model="body.acre">
                        <option :value="true">OUI</option>
                        <option :value="false">NON</option>
                    </select>
                </div>
            </div>

            <div class="form-section">
                <label>Simulations à réaliser</label>
                <multiselect 
                    v-model="body.required_comparisons" 
                    :options="simulationTypesKeys"
                    :closeOnSelect="false"
                    :multiple="true"
                    :allow-empty="false"
                    :customLabel="(value) => $store.getters.simulationRemunerationTypes[value]"
                ></multiselect>
            </div>
        </div>

        <div class="compute-btn-container">
            <div class="compute-btn btn" @click="compute">Calculer</div>
            <div class="save-btn btn" @click="saveSimulation">Enregistrer</div>
            <div class="delete-btn btn" @click="deleteSimulation" v-if="!isNew">Supprimer</div>
        </div>

        <loader-spin v-if="isLoading"></loader-spin>

        <div v-else class="comparison-table">
            <table>
                <tr>
                    <th>Poste</th>
                    <th v-for="simulation in simulations" :key="simulation._id">{{ simulation.label }}</th>
                </tr>

                <tr>
                    <td>Régime social</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ socialRegimeList[simulation.social_regime] }}</td>
                </tr>

                <tr class="important-line">
                    <td>Budget rémunération</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ toEuro(body.budget) }}</td>
                </tr>

                <tr>
                    <td>Rémunération brute</td>
                    <td v-for="simulation in simulations" :key="simulation._id">+ {{ toEuro(simulation.remuneration_gross) }}</td>
                </tr>

                <tr>
                    <td>Dividendes</td>
                    <td v-for="simulation in simulations" :key="simulation._id">+ {{ toEuro(simulation.dividends) }}</td>
                </tr>

                <tr>
                    <td>Impôt sur les sociétés</td>
                    <td v-for="simulation in simulations" :key="simulation._id">- {{ toEuro(simulation.society_taxes) }}</td>
                </tr>

                <tr>
                    <td>Cotisations sociales</td>
                    <td v-for="simulation in simulations" :key="simulation._id">- {{ toEuro(simulation.contributions) }}</td>
                </tr>

                <tr class="important-line">
                    <td>Revenu net avant impôt</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ toEuro(simulation.income_net) }}</td>
                </tr>

                <tr>
                    <td>Prélèvements sociaux</td>
                    <td v-for="simulation in simulations" :key="simulation._id">- {{ toEuro(simulation.social_taxes) }}</td>
                </tr>

                <tr>
                    <td>Impôt sur le revenu</td>
                    <td v-for="simulation in simulations" :key="simulation._id">- {{ toEuro(simulation.income_taxes) }}</td>
                </tr>

                <tr>
                    <td>PFU</td>
                    <td v-for="simulation in simulations" :key="simulation._id">- {{ toEuro(simulation.pfu) }}</td>
                </tr>

                <tr class="important-line">
                    <td>Rémunération disponible</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ toEuro(simulation.available_incomes) }}</td>
                </tr>

                <tr>
                    <td :colspan="simulations.length + 1" style="text-align: center; background-color: orange; font-weight: bold;">Protection sociale</td>
                </tr>

                <tr>
                    <td>Trimestres de retraite de base validés</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ simulation.retirement_quarters }} trimestres</td>
                </tr>

                <tr>
                    <td>Droits à la retraite complémentaires estimés par année de cotisation</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ toEuro(simulation.complementary_pension_yearly) }} / an</td>
                </tr>

                <tr>
                    <td>Protection maladie</td>
                    <td v-for="simulation in simulations" :key="simulation._id"><ul>
                        <li v-for="(right, index) in simulation.seakness_protection" :key="index">{{ right }}</li>
                    </ul></td>
                </tr>

                <tr>
                    <td>Indémnités journalières</td>
                    <td v-for="simulation in simulations" :key="simulation._id">
                        <span v-if="typeof simulation.daily_allowance == 'string'">{{ simulation.daily_allowance }}</span>
                        <span v-else>Revenu de référence : {{ simulation.daily_allowance?.reference_income }} ; Franchise : {{ simulation.daily_allowance?.franchise_in_days }} jours ; Montant maximum : {{ toEuro(simulation.daily_allowance?.max_value) }} ; Durée : {{ simulation.daily_allowance?.duration }}</span>
                    </td>
                </tr>

                <tr>
                    <td>Droits au chômage</td>
                    <td v-for="simulation in simulations" :key="simulation._id">{{ simulation.unemployment }}</td>
                </tr>
            </table>

            {{ errorMsg }}
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
    props: ['simulationObj', 'selectedScenario'],
    components: { Multiselect },
    data() {
        return {
            isNew: true,
            showModale: false,
            showDetails: false,
            isLoading: false,
            body: {},
            simulations: [],  
            socialRegimeList: {
                tns: "TNS",
                salary: "AS"
            },
            activitiesList: {
                artisanale: "Activité artisanale",
                commerciale: "Activité commerciale",
                libérale: "Activité libérale",
            },
            regulatedActivities: {
                'santé . sage-femme': "Sage femme",
                'santé . chirurgien-dentiste': "Chirurgien-dentiste",
                'expert-comptable': "Expert-comptable",
                'juridique . avocat': "Avocat",
                'rattaché CIPAV': "Autre métier rattaché à la CIPAV",
                'santé . auxiliaire médical': "Auxiliaire médical",
                'santé . pharmacien': "Pharmacien ou biologiste médical"
            },
            incomesList: [],
            errorMsg: "",
        }
    },
    watch: {
        'body.revenues': {
            handler() {
                this.body.remuneration_gross = this.body.revenues - this.body.charges
            }
        },
        'body.charges': {
            handler() {
                this.body.remuneration_gross = this.body.revenues - this.body.charges
            }
        },
        'body.remuneration_gross': {
            handler() {
                this.body.remuneration_gross = this.body.revenues - this.body.charges
            }
        },
        'body.activity': {
            handler() {
                if (this.body.activity != 'libérale') {
                    this.body.is_regulated_liberal = false
                }
            }
        },
        'body.is_regulated_liberal': {
            handler() {
                if (this.body.is_regulated_liberal != 'oui') {
                    this.body.regulated_activity = undefined
                }
            }
        },
        'body.creation_date': {      
            handler() {
                const current_date = new Date()
                if (new Date(this.body.creation_date) < new Date("01/01/" + current_date.getFullYear())) {
                    this.body.acre = false
                }
            }
        },
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
            if (this.showModale) {
                this.compute()
            }
        },
        toggleDetails() {
            this.showDetails = !this.showDetails
        },
        getTaxModel(model) {
            this.body.society_tax_model = model
        },
        getSocialRegime(regime) {
            this.body.social_regime = regime
        },
        async loadIncomes() {
            this.incomesList = []
            const resSelected = await axios.get(this.$store.getters.get_api_url + 'clients/find-client/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            const res = await axios.get(this.$store.getters.get_api_url + 'clients/income/' + resSelected.data.selectedScenarioId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            res.data.forEach(income => {
                this.incomesList.push({
                    _id: income._id,
                    label: this.$store.getters.incomeTypes[income.type] + " - " + this.$store.getters.findPersonName(income.person_id) + " - " + this.toEuro(income.net_value),
                    is_checked: false,
                    disabled: false,
                })
            });
        },
        async compute() {
            this.isLoading = true

            let taxes_datas = this.$store.getters.taxesDatas
            taxes_datas.replaced_incomes = this.incomesList.filter(i => i.is_checked).map(i => i._id)
            
            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/social-charges/', {
                    datas: this.body,
                    taxes_datas: taxes_datas
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.simulations = res.data
                console.log(res.data)

            } catch(err) {
                console.log(err)
            }
            
            this.isLoading = false
            
        },
        async saveSimulation() {
            if (this.body.budget == 0 || !this.body.budget) {
                this.$store.commit('changeErrorMsg', "Le budget rémunération doit être supérieur à 0")

            } else if (this.body.required_comparisons.length == 0) {
                this.$store.commit('changeErrorMsg', "Choisissez un ou plusieurs scenarios de simulation")

            } else {
                if (this.isNew) {
                    const res = await axios.post(this.$store.getters.get_api_url + 'clients/manager-remuneration/', this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    this.body._id = res.data
                    if (res.status == 200) {
                        this.$store.commit('changeValidationMsg', "Enregistrement réalisé avec succès")
                    } else {
                        this.$store.commit('changeErrorMsg', "Erreur lors de l'enregistrement")
                    }
                    this.isNew = false
                    this.$emit('modification')
                    this.$emit('toggle')
                } else {
                    const res = await axios.put(this.$store.getters.get_api_url + 'clients/manager-remuneration/' + this.body._id, this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    if (res.status == 200) {
                        this.$store.commit('changeValidationMsg', "Enregistrement réalisé avec succès")
                    } else {
                        this.$store.commit('changeErrorMsg', "Erreur lors de l'enregistrement")
                    }
                    this.$emit('toggle')
                }
            }
        },
        async deleteSimulation() {
            await axios.delete(this.$store.getters.get_api_url + 'clients/manager-remuneration/' + this.body._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.$emit('modification')
            this.$emit('toggle')
        }
    },
    computed: {
        detailBtnLabel() {
            if (this.showDetails) {
                return "Afficher moins"
            } else {
                return "Afficher plus"
            }
        },
        simulationTypesKeys() {
            return Object.keys(this.$store.getters.simulationRemunerationTypes)
        }
    },
    mounted() {
        this.loadIncomes()

        if (this.simulationObj != undefined) {
            this.isNew = false
            this.body = this.simulationObj
        } else {
            this.body = {
                clientId: this.$route.params.clientId,
                scenarioId: this.selectedScenario,
                label: "Simulation",
                activity: 'commerciale', 
                is_regulated_liberal: false,
                regulated_activity: undefined,
                society_tax_model: 'IS',
                creation_date: undefined,
                acre: false,
                zip_code: '',
                budget: 0,
                required_comparisons: [],
            }
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.modale-wrapper {
    width: 70%;
    margin: 20px auto;
}

/* Formulaire */
.incomes-section {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}
.incomes-frame {
    display: flex;
    flex-direction: column;
}
.incomes {
    display: flex;
    flex-basis: 70%;
    justify-content: right;
    font-weight: lighter;
}
.incomes input[type] {
    height: 20px;
    width: 20px;
    flex-basis: auto;
}

.detail-btn {
    cursor: pointer;
    font-size: 18px;
    background-color: rgb(0, 0, 96);
    color: white;
    display: inline-block;
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
}

/* Bouton */
.compute-btn-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.btn {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    align-self: center;
    border-radius: 10px;
    font-size: 18px;
    margin: 10px;
}
.compute-btn {
    background-color: orange;
}
.compute-btn:hover {
    background-color: rgb(251, 183, 55);
}

.delete-btn {
    background-color: rgb(179, 1, 1);
    color: white;
}
.delete-btn:hover {
    background-color: red;
}

.save-btn {
    background-color: green;
    color: white;
}
.save-btn:hover {
    background-color: rgb(4, 186, 4);
}

/* Tableau de comparaison */
.comparison-table table {
    border-collapse: collapse;
    width: 100%;
    font-size: 13px;
}

.comparison-table th, .comparison-table td {
    padding: 10px;
    border: 1px solid grey;
    position: relative;
}

.comparison-table table th {
    background-color: rgb(0, 0, 96);
    color: white;
}

.important-line {
    background-color: rgb(172, 192, 247);
    font-weight: bold;
}

.comparison-table table td+td {
    text-align: center;
}

.in-row-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
    border: none;
}


</style>