<template>
    <div class="legacy-details-frame">
        <h1>Liquidation de succession</h1>

        <div v-if="legacyResult.first_death.datas.is_married">
            <div class="detail-section">
                <div class="toggle-btn" @click="toggleLiquidation">
                    <span :class="{ unrotate: showSection.communityLiquidation, rotate: !showSection.communityLiquidation }">V</span>
                    <h2>Liquidation de la communauté :</h2>
                </div>

                <div class="section-body" v-if="showSection.communityLiquidation">
                    <p>Avant liquidation de la communauté, la répartition du patrimoine est la suivante :</p>

                    <table>
                        <tr>
                            <th>Élement de patrimoine</th>
                            <th>{{this.$store.getters.userName}}</th>
                            <th>{{this.$store.getters.spouseName}}</th>
                            <th>Communauté</th>
                        </tr>

                        <tr v-for="asset in formattedWealthTable" :key="asset._id">
                            <td>{{ formatType(asset.type) }}</td>
                            <td>{{ toEuro(asset.value_per_owner.user) }}</td>
                            <td>{{ toEuro(asset.value_per_owner.spouse) }}</td>
                            <td>{{ toEuro(asset.value_per_owner.community) }}</td>
                        </tr>

                        <tr>
                            <th>Total</th>
                            <th>{{ toEuro(formattedWealthTable.map(a => a.value_per_owner.user).reduce((sum, current) => sum + current, 0)) }}</th>
                            <th>{{ toEuro(formattedWealthTable.map(a => a.value_per_owner.spouse).reduce((sum, current) => sum + current, 0)) }}</th>
                            <th>{{ toEuro(formattedWealthTable.map(a => a.value_per_owner.community).reduce((sum, current) => sum + current, 0)) }}</th>
                        </tr>
                    </table>

                    <!-- Récompenses -->
                    <div v-if="$store.getters.getAllCommunityRewards.length > 0">
                        <h3>Calcul des récompenses</h3>
                        <p>La récompense a lieu lorsque des mouvements de valeurs se produisent pendant le mariage entre le patrimoine propre et le patrimoine commun, et que l'un de ses patrimoine en a tiré profit. Votre situation présente les récompenses suivantes :</p>
                        <ul>
                            <li v-for="reward in $store.getters.getAllCommunityRewards" :key="reward._id">{{ getRewardLabel(reward) }}</li>
                        </ul>

                        <p>Les récompenses sont payées en priorité sur les liquidités du patrimoine débiteur, puis sur les biens meubles puis sur les biens immobiliers :</p>

                        <ul>
                            <li v-for="(reward_payment, index) in legacyResult.liquidation.reward_payments" :key="index">{{ getRewardPaymentLabel(reward_payment) }}</li>
                        </ul>
                    </div>

                    <!-- Clauses de répartition de la communauté -->
                    <section>
                        <h3>Prise en compte des clauses de répartition de la communauté</h3>
                        <div v-if="$store.getters.getContract.full_attribution">
                            <p>Il existe une clause d'attribution intégrale attribuant la totalité des biens communs au conjoint survivant.</p>
                        </div>

                        <div v-else-if="$store.getters.getContract.preciput">
                            <p>Il existe une clause de préciput attribuant les biens suivants au conjoint survivant :</p>
                            <ul>
                                <li v-for="asset in $store.getters.getContract.preciput_detail" :key="asset">{{ findAsset(asset) }}</li>
                            </ul>
                        </div>

                        <div v-else-if="$store.getters.getContract.inegal_repartition">
                            <p>Il existe une clause de repartition inégale venant modifier la répartition de la communauté au moment de la liquidation. La répartition inégale choisit par les époux est la suivante :</p>
                            <ul>
                                <li>{{ $store.getters.userName }} : {{ $store.getters.getContract.inegal_repartition_detail[$store.getters.userId] }} %</li>
                                <li>{{ $store.getters.spouseName }} : {{ $store.getters.getContract.inegal_repartition_detail[$store.getters.spouseId] }} %</li>
                            </ul>
                        </div>

                        <div v-else>
                            <p>Les époux n'ont pas prévu de dispositions allant à l'encontre de la répartition égalitaire de la communauté. </p>
                        </div>
                    </section>

                    <!-- Créances entre époux -->
                    <div v-if="$store.getters.getAllSpouseClaims.length > 0">
                        <h3>Prise en compte des créances entre époux</h3>
                        <p>Les créances entre époux résultent de mouvements de valeur, non pas entre la masse commune et l'une des masses propres, mais entre les deux masses propres. Votre situation présente les créances entre époux suivantes :</p>
                        <ul>
                            <li v-for="reward in $store.getters.getAllSpouseClaims" :key="reward._id">{{ getRewardLabel(reward) }}</li>
                        </ul>

                        <p>Les récompenses sont payées en priorité sur les liquidités du patrimoine débiteur, puis sur les biens meubles puis sur les biens immobiliers :</p>

                        <ul>
                            <li v-for="(claim_payment, index) in legacyResult.liquidation.claim_payments" :key="index">{{ getRewardPaymentLabel(claim_payment) }}</li>
                        </ul>
                    </div>

                    <p>Finalement, les biens seront répartis comme ceci : </p>
                    <table>
                        <tr>
                            <th>Répartition des biens</th>
                            <th><span v-if="order==1">{{ $store.getters.userName }}</span><span v-else>{{ $store.getters.spouseName }}</span></th>
                            <th><span v-if="order==1">{{ $store.getters.spouseName }}</span><span v-else>{{ $store.getters.userName }}</span></th>
                        </tr>

                        <tr v-for="asset in assetsRepartition" :key="asset._id">
                            <td>{{ formatType(asset.type) }}</td>
                            <td>{{ toEuro(asset.user_value) }}</td>
                            <td>{{ toEuro(asset.spouse_value) }}</td>
                        </tr>

                        <tr>
                            <th>Total</th>
                            <th>{{ toEuro(legacyResult.liquidation.user_existing_goods) }}</th>
                            <th>{{ toEuro(legacyResult.liquidation.spouse_existing_goods) }}</th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div>
            <h1>1er décès : {{ $store.getters.findPersonName(userId) }}</h1>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'existingGoods')">
                    <span :class="{ unrotate: showSection.user.existingGoods, rotate: !showSection.user.existingGoods }">V</span>
                    <h2>Inventaire des biens existants : </h2>
                </div>

                <div class="section-body" v-if="showSection.user.existingGoods">
                    <ExistingGoods
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'willRepartition')">
                    <span :class="{ unrotate: showSection.user.willRepartition, rotate: !showSection.user.willRepartition }">V</span>    
                    <h2>Application du testament</h2>
                </div>

                <div class="section-body" v-if="showSection.user.willRepartition">
                    <WillRepartition
                        :legacyResult="legacyResult"
                        :datasFromOrder="datasFromOrder"
                        :isSecondDeath="false"
                        :deceasedId="userId"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'spousePart')">
                    <span :class="{ unrotate: showSection.user.spousePart, rotate: !showSection.user.spousePart }">V</span>
                    <h2>Calcul de la part du conjoint</h2>
                </div>

                <div class="section-body" v-if="showSection.user.spousePart">
                    <SpousePart
                        v-if="$store.getters.hasSpouse"
                        :legacyResult="legacyResult"
                        :spouseChoice="spouseChoice"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'devolution')">
                    <span :class="{ unrotate: showSection.user.devolution, rotate: !showSection.user.devolution }">V</span>
                    <h2>Dévolution successorale légale</h2>
                </div>

                <div class="section-body" v-if="showSection.user.devolution">
                    <Devolution
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'reserveCheck')">
                    <span :class="{ unrotate: showSection.user.reserveCheck, rotate: !showSection.user.reserveCheck }">V</span>
                    <h2>Vérification de l'atteinte aux réserves :</h2>
                </div>

                <div class="section-body" v-if="showSection.user.reserveCheck">
                    <ReserveCheck
                        :legacyResult="legacyResult"
                        :deceasedId="userId"
                        :datasFromOrder="datasFromOrder"
                        :isSecondDeath="false"
                    />

                    <Reduction
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'civilPart')">
                    <span :class="{ unrotate: showSection.user.civilPart, rotate: !showSection.user.civilPart }">V</span>
                    <h2>Résumé de la part reçue par les héritiers :</h2>
                </div>

                <div class="section-body" v-if="showSection.user.civilPart">
                    <CivilPart 
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'taxableAssets')">
                    <span :class="{ unrotate: showSection.user.taxableAssets, rotate: !showSection.user.taxableAssets }">V</span>
                    <h2>Calcul de l'actif taxable :</h2>
                </div>

                <div class="section-body" v-if="showSection.user.taxableAssets">
                    <TaxableAssets 
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(false, 'inheritorTaxes')">
                    <span :class="{ unrotate: showSection.user.inheritorTaxes, rotate: !showSection.user.inheritorTaxes }">V</span>
                    <h2>Calcul de la fiscalité :</h2>
                </div>

                <div class="section-body" v-if="showSection.user.inheritorTaxes">
                    <InheritorTaxes 
                        :legacyResult="legacyResult"
                        :isSecondDeath="false"
                    />
                </div>
            </div>
        </div>

        <div v-if="spouseId">
            <h1>2ème décès : {{ $store.getters.findPersonName(spouseId) }}</h1>
            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'existingGoods')">
                    <span :class="{ unrotate: showSection.spouse.existingGoods, rotate: !showSection.spouse.existingGoods }">V</span>
                    <h2>Inventaire des biens existants : </h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.existingGoods">
                    <ExistingGoods
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'willRepartition')">
                    <span :class="{ unrotate: showSection.spouse.willRepartition, rotate: !showSection.spouse.willRepartition }">V</span>    
                    <h2>Application du testament</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.willRepartition">
                    <WillRepartition
                        :legacyResult="legacyResult"
                        :datasFromOrder="datasFromOrder"
                        :isSecondDeath="true"
                        :deceasedId="spouseId"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'devolution')">
                    <span :class="{ unrotate: showSection.spouse.devolution, rotate: !showSection.spouse.devolution }">V</span>
                    <h2>Dévolution successorale légale</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.devolution">
                    <Devolution
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'reserveCheck')">
                    <span :class="{ unrotate: showSection.spouse.reserveCheck, rotate: !showSection.spouse.reserveCheck }">V</span>
                    <h2>Vérification de l'atteinte aux réserves :</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.reserveCheck">
                    <ReserveCheck
                        :legacyResult="legacyResult"
                        :deceasedId="spouseId"
                        :datasFromOrder="datasFromOrder"
                        :isSecondDeath="true"
                    />

                    <Reduction
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'civilPart')">
                    <span :class="{ unrotate: showSection.spouse.civilPart, rotate: !showSection.spouse.civilPart }">V</span>
                    <h2>Résumé de la part reçue par les héritiers :</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.civilPart">
                    <CivilPart 
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'taxableAssets')">
                    <span :class="{ unrotate: showSection.spouse.taxableAssets, rotate: !showSection.spouse.taxableAssets }">V</span>
                    <h2>Calcul de l'actif taxable :</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.taxableAssets">
                    <TaxableAssets 
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>

            <div class="detail-section">
                <div class="toggle-btn" @click="toggleSection(true, 'inheritorTaxes')">
                    <span :class="{ unrotate: showSection.spouse.inheritorTaxes, rotate: !showSection.spouse.inheritorTaxes }">V</span>
                    <h2>Calcul de la fiscalité :</h2>
                </div>

                <div class="section-body" v-if="showSection.spouse.inheritorTaxes">
                    <InheritorTaxes 
                        :legacyResult="legacyResult"
                        :isSecondDeath="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ExistingGoods from './ExistingGoods.vue'
import ReserveCheck from './ReserveCheck.vue'
import WillRepartition from './WillRepartition.vue'
import SpousePart from './SpousePart.vue'
import Devolution from './Devolution.vue'
import Reduction from './Reduction.vue'
import CivilPart from './CivilPart.vue'
import TaxableAssets from './TaxableAssets.vue'
import InheritorTaxes from './InheritorTaxes.vue'

export default {
    props: [
        'legacyResult',
        'order',
        'spouseChoice',
    ],
    components: {
        ExistingGoods,
        ReserveCheck,
        WillRepartition,
        SpousePart,
        Devolution,
        Reduction,
        CivilPart,
        TaxableAssets,
        InheritorTaxes,
    },
    data() {
        return {
            BOOLEAN_TRAD: {
                true: 'OUI',
                false: 'NON'
            },
            showSection: {
                communityLiquidation: false,
                user: {
                    existingGoods: false,
                    willRepartition: false,
                    spousePart: false,
                    devolution: false,
                    reserveCheck: false,
                    reduction: false,
                    civilPart: false,
                    taxableAssets: false,
                    inheritorTaxes: false
                },
                spouse: {
                    existingGoods: false,
                    willRepartition: false,
                    spousePart: false,
                    devolution: false,
                    reserveCheck: false,
                    reduction: false,
                    civilPart: false,
                    taxableAssets: false,
                    inheritorTaxes: false,
                },
            },
        }
    },
    methods: {
        formatType(type) {
            const realEstateType = this.$store.getters.propertyTypesList[type]
            const assetType = this.$store.getters.assetTypesList[type]
            const societyType = this.$store.getters.societyTypes[type]
            const movableType = this.$store.getters.movableTypes[type]
            const debtType = this.$store.getters.debtTypes[type]

            if (realEstateType) {
                return realEstateType
            } else if (assetType) {
                return assetType
            } else if (societyType) {
                return societyType
            } else if (movableType) {
                return movableType
            } else if (debtType) {
                return debtType
            } else {
                return 'Inconnu'
            }
        },
        formatRanks(ranks) {
            let result = ""

            for (let i = 0; i < ranks.length; i++) {
                ranks[i].forEach(personId => {
                    result = result + this.$store.getters.findPersonName(personId)
                    result = result + ', '
                })

                if (i != ranks.length - 1) {
                    result = result + 'à défaut '
                }
            }

            return result
        },
        findAsset(id) {
            const asset = this.$store.getters.getAllWealth.find(asset => asset._id == id)
            
            return this.formatType(asset?.type) + ' - ' + this.toEuro(asset?.value)
        },
        toggleLiquidation() {
            this.showSection.communityLiquidation = !this.showSection.communityLiquidation
        },
        toggleSection(isSpouse, key) {
            let user = 'user'
            if (isSpouse) {
                user = 'spouse'
            }

            this.showSection[user][key] = !this.showSection[user][key]
        },
        getRewardLabel(rewardObj) {
            const TRAD_DIRECTION = {
                userToSpouse: `${this.$store.getters.userName} doit à ${this.$store.getters.spouseName}`,
                spouseToUser: `${this.$store.getters.spouseName} doit à ${this.$store.getters.userName}`,
                userToCommunity: `${this.$store.getters.userName} doit à la communauté`,
                spouseToCommunity: `${this.$store.getters.spouseName} doit à la communauté`,
                communityToUser: `La communauté doit à ${this.$store.getters.userName}`,
                communityToSpouse: `La communauté doit à ${this.$store.getters.spouseName}`,
            }
            
            let directionLabel = TRAD_DIRECTION[rewardObj.direction]

            const result = `${directionLabel} ${this.toEuro(rewardObj.value)} (${this.$store.getters.rewardLabels[rewardObj.label].label})`

            return result
        },
        getRewardPaymentLabel(rewardPayment) {
            
            let debtor_label = this.$store.getters.findPersonName(rewardPayment.debtor_ids[0])
            if (rewardPayment.debtor_ids.length > 1) debtor_label = "La communauté"

            let beneficiary_label = this.$store.getters.findPersonName(rewardPayment.beneficiaryIds[0])
            if (rewardPayment.beneficiaryIds.length > 1) beneficiary_label = "la communauté"

            const asset = this.$store.getters.getAllWealth.find(a => a._id == rewardPayment.asset_id)
            const assetLabel = this.$store.getters.allTypes[asset?.type]
            
            return `${debtor_label} verse à ${beneficiary_label} ${this.toEuro(rewardPayment.value)} (${assetLabel})`
        }
    },
    computed: {
        datasFromOrder() {
            return this.$store.getters.legacyDatas(this.order, false)
        },
        assetsRepartition() {
            let result = []
            
            for (const asset of this.$store.getters.getAllWealth) {
                
                const user_asset = this.legacyResult.liquidation?.user_existing_goods_list?.find(a => a._id == asset._id)
                const spouse_asset = this.legacyResult.liquidation?.spouse_existing_goods_list?.find(a => a._id == asset._id)

                result.push({
                    _id: asset._id,
                    type: asset.type,
                    user_value: user_asset?.value,
                    spouse_value: spouse_asset?.value,
                })
            }

            return result
        },
        userId() {
            const id = this.datasFromOrder.persons.find(p => p.role == 'user')?._id
            return id
        },
        spouseId() {
            const id = this.datasFromOrder.persons.find(p => p.role == 'spouse')?._id
            return id
        },
        formattedWealthTable() {
            let result = []

            let array_of_wealth = []

            for (let asset of this.$store.getters.getAllWealth) {
                // Suppression des biens en usufruit qui n'intègrent pas la succession
                asset.detention.filter(d => d.right != 'us')

                if (asset.detention.length > 0) {
                    array_of_wealth.push(asset)
                }
            }

            for (const asset of array_of_wealth) {
                let value_per_owner = {user: 0, spouse: 0, community: 0}

                if (!asset.isIndivision && asset.detention.length > 1) {
                    // Bien commun
                    value_per_owner.community = asset.value
                } else {
                    value_per_owner.user = asset.detention.filter(d => d.owner_id == this.$store.getters.userId).map(d => d.part).reduce((sum, current) => sum + current, 0) * asset.value
                    value_per_owner.spouse = asset.detention.filter(d => d.owner_id == this.$store.getters.spouseId).map(d => d.part).reduce((sum, current) => sum + current, 0) * asset.value
                }

                result.push({
                    _id: asset._id,
                    type: asset.type,
                    value_per_owner: value_per_owner
                })
            }

            return result
        }
    }
}
</script>

<style scoped src="./style.css"></style>