<template>
    <div class="list-section">
        <h1 class="section-title">
            {{ label }} - {{ toEuro(totalWealthValue) }}
            <add-btn type="asset"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="asset in list_assets"
                :key="asset._id"
                :object="asset"
                type="asset"
            />
        </table>
    </div>
</template>

<script>

export default {
    props: ['isLiquidities'],
    data() {
        return {}
    },
    computed: {
        label() {
            if (this.isLiquidities) {
                return "Liquidités bancaires"

            } else {
                return 'Patrimoine financier'
            }
        },
        list_assets() {
            if (this.isLiquidities) {
                return this.$store.getters.getAllAssets.filter(a => this.$store.getters.liquidityTypes.includes(a.type))

            } else {
                return this.$store.getters.getAllAssets.filter(a => !this.$store.getters.liquidityTypes.includes(a.type))
            }
        },
        totalWealthValue() {
            if (this.isLiquidities) {
                return this.$store.getters.getTotalLiquidityWealth

            } else {
                return this.$store.getters.getTotalFinancialWealth
            }
        }
    }
}
</script>


