<template>
    <div>
        <h2 class="category-title">Enfants</h2>

        <div class="elements-container">
            <div class="element" v-for="person in $store.getters.getChildren" :key="person._id">
                <h3>{{ person.firstname }} {{ person.lastname }}</h3>
                <table>
                    <tr>
                        <td class="col1">Parent</td>
                        <td class="col2">{{ getParentLabel(person) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Date de naissance</td>
                        <td class="col2">{{ dateToString(person.birthdate) }}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-modify" @click="toggleModifyModale(person._id)">MODIFIER</div>
                    <div class="btn btn-delete" @click="deleteItem(person._id)">SUPPRIMER</div>

                    <modale :show="showModifyModale[person._id]" @toggle="toggleModifyModale(person._id)">
                        <Person :import="person" @closeModale="toggleModifyModale(person._id)"/>
                    </modale>
                </div>
            </div>

            <div class="add-btn" @click="toggleModale">
                <div>+</div>

                <modale :show="showModale" @toggle="toggleModale">
                    <Person role='child' @closeModale="toggleModale"/>
                </modale>
            </div>
        </div>
    </div>
</template>

<script>
import Person from '../../../forms/Person.vue'

export default {
    components: {Person},
    data() {
        return {
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm('Êtes vous sur de vouloir supprimer cette personne ?')
            if (valid) {
                this.$store.dispatch('delete_person', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        },
        getParentLabel(child) {
            if (child.parent1 && child.parent2) {
                return 'Commun'
            } else {
                return this.$store.getters.findPersonName(child.parent1)
            }
        }
    },

}
</script>

<style src="../style.css" scoped/>