<template>
    <div>
        <h3>Estimation de l'impôt sur le revenu</h3>

        <TaxesResult/>
    </div>
</template>

<script>
import TaxesResult from '../../../results/Taxes.vue'
export default {
    components: {
        TaxesResult,
    }
}
</script>

<style scoped>

</style>