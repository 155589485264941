<template>
    <div class="list-section">
        <h1 class="section-title">
            Contrats d'assurance - {{ toEuro($store.getters.getTotalInsuranceWealth) }}

            <add-btn type="insurance"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="insurance in $store.getters.getAllInsurances"
                :key="insurance._id"
                :object="insurance"
                type="insurance"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
            
    },
}
</script>