<template>
    <div class="navigation">
      <div class="menu">
        <router-link class="menu-option" to="/conseillers/crm">Gestion</router-link>
        <router-link class="menu-option" to="/conseillers/reporting">Reporting</router-link>
      </div>
    </div>

    <router-view></router-view>
</template>

<script>
export default {
}

</script>

<style>
.navigation {
  height: 30px;
  padding: 10px;
  background-color: #0a1c6b;
  display: flex;
  justify-content: flex-end;
}
.menu {
  margin-right: 10%;
}
.menu-option {
  text-decoration: none;
  color: white;
  margin: 0 10px;
}
.router-link-active {
  color: rgb(255, 145, 0);
}
</style>
