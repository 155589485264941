<template>
     <section>
        <p>Le code général des impôts prévoit que certains biens soient exonérés partiellement de droits de succession. Il convient donc de lister ces exonération pour calculer l'actif taxable à partager ou la fiscalité des legs : </p>
        <table>
            <tr>
                <th>Actif</th>
                <th>Valeur vénale</th>
                <th>Abattement fiscal</th>
                <th>Valeur taxable</th>
            </tr>

            <tr v-for="asset in taxable_assets_detail" :key="asset._id">
                <td>{{ $store.getters.allTypes[asset.type] }}</td>
                <td>{{ toEuro(asset.value) }}</td>
                <td>{{ asset.reduction * 100 }}%</td>
                <td>{{ toEuro(asset.fiscal_value) }}</td>
            </tr>

            <tr>
                <th colspan="3">Total</th>
                <th>{{ toEuro(totalTaxableValue) }}</th>
            </tr>
        </table>

    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
        }
    },
    computed: {
        taxable_assets_detail() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.taxable_assets.taxable_assets_detail
            } else {
                return this.legacyResult.first_death.taxable_assets.taxable_assets_detail
            }
        },
        totalTaxableValue() {
            return this.taxable_assets_detail.map(a => a.fiscal_value).reduce((sum, current) => sum + current, 0)
        }
    },
}
</script>

<style scoped src="./style.css"></style>