<template>
    <div class="list-section">
        <h1 class="section-title">
            Parts de sociétés - {{ toEuro($store.getters.getTotalSocietyWealth) }}
            <add-btn type="society"></add-btn>
        </h1>
        <table class="table-elements">
            <list-component class="list-element"
                v-for="asset in $store.getters.getAllSocieties"
                :key="asset._id"
                :object="asset"
                type="society"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    }
}
</script>


