<template>
    <div class="container">
        <div class="side-left">
            <Properties/>
            <Assets :isLiquidities="true"/>
            <Assets :isLiquidities="false"/>
            <Movable/>
        </div>

        <div class="side-right">
            <Society/>
            <Insurances/>
            <Debts/>
        </div>
    </div>
</template>

<script>
import Assets from '../sections/Assets.vue'
import Properties from '../sections/Properties.vue'
import Insurances from '../sections/Insurances.vue'
import Debts from '../sections/Debts.vue'
import Movable from '../sections/Movable.vue'
import Society from '../sections/Society.vue'

export default {
    components: {
        Assets,
        Properties,
        Insurances,
        Debts,
        Movable,
        Society,
    },
}
</script>

<style scoped></style>