<template>
    <h1 class="section-label">Simulations des donations</h1>
    <div class="separator"></div>

    <table class="simulation-table">
        <tr>
            <th>Type</th>
            <th>Donateur</th>
            <th>Donataire</th>
            <th>Valeur</th>
            <th>DMTG</th>
            <th>Date</th>
            <th>Détail</th>
        </tr>

        <tr v-for="gift in new_gifts" :key="gift._id">
            <td>{{ $store.getters.giftTypes[gift.type] }}</td>
            <td>{{ $store.getters.findPersonName(gift.donor) }}</td>
            <td>{{ $store.getters.findPersonName(gift.donee) }}</td>
            <td>{{ toEuro(gift.value) }}</td>
            <td>{{ toEuro(gift.taxesResult?.tax) }}</td>
            <td>{{ dateToString(gift.date) }}</td>
            <td @click="toggleShowTaxesResult(gift._id)"> <edit-icon style="cursor: pointer"/> </td>

            <small-modale :show="showTaxResult[gift._id]" @toggle="toggleShowTaxesResult(gift._id)">
                <table class="showTaxResult">
                    <tr>
                        <th>Type de donation</th>
                        <td>{{ $store.getters.giftTypes[gift.type] }}</td>
                    </tr>

                    <tr>
                        <th>Date</th>
                        <td>{{ dateToString(gift.date) }}</td>
                    </tr>

                    <tr>
                        <th>Donateur</th>
                        <td>{{ $store.getters.findPersonName(gift.donor) }}</td>
                    </tr>

                    <tr>
                        <th>Donataire</th>
                        <td>{{ $store.getters.findPersonName(gift.donee) }}</td>
                    </tr>

                    <tr>
                        <th>Valeur de la donation</th>
                        <td>{{ toEuro(gift.value) }}</td>
                    </tr>

                    <tr v-if="gift.fiscal_value != gift.value">
                        <th>Valeur de la nue-propriété</th>
                        <td>{{ toEuro(gift.fiscal_value) }}</td>
                    </tr>

                    <tr v-if="gift.taxesResult?.dutreil_reduction > 0">
                        <th>Abattement Dutreil</th>
                        <td>{{ toEuro(gift.taxesResult?.dutreil_reduction) }}</td>
                    </tr>

                    <tr>
                        <th>Abattement de droit commun</th>
                        <td>{{ toEuro(gift.taxesResult?.reduction_available) }}</td>
                    </tr>

                    <tr>
                        <th>Montant taxable</th>
                        <td>{{ toEuro(gift.taxesResult?.taxable) }}</td>
                    </tr>

                    <tr>
                        <th>Impôt à payer</th>
                        <td>{{ toEuro(gift.taxesResult?.tax) }}</td>
                    </tr>

                    <tr>
                        <th>Abattement restant</th>
                        <td>{{ toEuro(gift.taxesResult?.reduction_available - gift.taxesResult?.used_reduction) }}</td>
                    </tr>
                </table>
            </small-modale>
        </tr>
    </table>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            donationTaxesHistory: [],
            new_gifts: [],
            showTaxResult: {},
        }
    },
    methods: {
        async getDonationTaxes(doneesIds, gift_list) {
            let donationTaxesHistory = []

            for (let i = 0; i < doneesIds.length; i++) {
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/donation-taxes/', {
                    donations: gift_list,
                    donee: this.$store.getters.getPersons.find(p => p._id == doneesIds[i])
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                
                donationTaxesHistory = [...donationTaxesHistory, ...res.data]
            }

            this.donationTaxesHistory = donationTaxesHistory 
        },
        toggleShowTaxesResult(_id) {
            if (this.showTaxResult[_id]) {
                this.showTaxResult[_id] = false
            } else {
                this.showTaxResult[_id] = true
            }
        },
    },
    watch: {
        '$store.getters.getAllGifts': function() {
            const gifts = this.$store.getters.getAllGifts
            let new_gifts = gifts.filter(g => g.isIpCreation)

            let doneesIds = new_gifts.map(g => g.donee)
            doneesIds = doneesIds.filter((x, i) => doneesIds.indexOf(x) === i);

            this.getDonationTaxes(doneesIds, gifts)

            this.new_gifts = new_gifts
        },
        donationTaxesHistory() {
            this.new_gifts.forEach(gift => {
                gift.taxesResult = this.donationTaxesHistory.find(h => h._id == gift._id)
            });
        },
    }
}
</script>

<style src="./style.css" scoped></style>