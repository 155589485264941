<template>
   <div class="calculator-frame">

        <h2>Calcul de la plus-value immobilière des particuliers</h2>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Type de biens</label>
                <select v-model="body.property_type">
                    <option v-for="(value, key) in $store.getters.propertyTypesList" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Date d'acquisition</label>
                <input type="date" v-model="body.acquisition_date">
            </div>

            <div class="datas-section">
                <label>Prix d'acquisition</label>
                <input type="number" v-model="body.initial_value">
            </div>

            <div class="datas-section">
                <label>Frais d'acquisition (agence, notaire etc...)</label>
                <input type="number" v-model="body.acquisition_fees">
            </div>

            <div class="datas-section">
                <label>Mode d'acquisition</label>
                <select v-model="body.is_bought">
                    <option :value="true">Acquisition à titre onéreux</option>
                    <option :value="false">Acquisition à titre gratuit</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Montant des travaux réalisés (avec justificatifs)</label>
                <input type="number" v-model="body.work_value">
            </div>

            <div class="datas-section">
                <label>Date de vente du bien</label>
                <input type="date" v-model="body.selling_date">
            </div>

            <div class="datas-section">
                <label>Prix de vente</label>
                <input type="number" v-model="body.current_value">
            </div>
        </div>

        <div class="separator"></div>

        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Prix de vente</th>
                <td>{{ toEuro(body.current_value) }}</td>
                <td></td>
            </tr>

            <tr>
                <th>Prix d'achat</th>
                <td>- {{ toEuro(body.initial_value) }}</td>
                <td></td>
            </tr>

            <tr>
                <th>Frais d'acquisition</th>
                <td>- {{ toEuro(computationResult.acquisition_fees) }}</td>
                <td>
                    <span v-if="computationResult.is_fixed_acquisition_fees">Les frais d'acquisition forfaitaires de 7,5% sont appliqués.</span>
                </td>
            </tr>

            <tr>
                <th>Travaux déductibles</th>
                <td>- {{ toEuro(computationResult.work_value) }}</td>
                <td>
                    <span v-if="computationResult.is_fixed_work">Le forfait travaux de 15% est appliqué.</span>
                </td>
            </tr>

            <tr>
                <th>Plus-value réalisée</th>
                <td>= {{ toEuro(computationResult.gain) }}</td>
                <td></td>
            </tr>

            <tr>
                <th>Plus-value taxable à l'IR</th>
                <td>{{ toEuro(computationResult.taxable_to_ir) }}</td>
                <td>Exonération de {{ Math.round(10000 * computationResult.tax_exoneration_rate) / 100}} % après {{ computationResult.detention_duration }} ans</td>
            </tr>

            <tr>
                <th>Impôt sur la plus-value</th>
                <td>{{ toEuro(computationResult.taxes) }} </td>
                <td>Taux forfaitaire de 19%</td>
            </tr>

            <tr>
                <th>Plus-value taxable aux prélèvements sociaux</th>
                <td>{{ toEuro(computationResult.taxable_to_social) }}</td>
                <td>Exonération de {{ Math.round(10000 * computationResult.social_exoneration_rate) / 100}} % après {{ computationResult.detention_duration }} ans</td>
            </tr>

            <tr>
                <th>Prélèvements sociaux</th>
                <td>{{ toEuro(computationResult.social_contribution) }}</td>
                <td>Taux forfaitaire de 17,20%</td>
            </tr>

            <tr>
                <th class="result-title">Surtaxe</th>
                <td>{{ toEuro(computationResult.additional_tax) }}</td>
                <td>Une surtaxe est appliquée aux plus-values supérieures à 50.000€. </td>
            </tr>

            <tr>
                <th class="result-title">Total à payer</th>
                <td>{{ toEuro(computationResult.additional_tax + computationResult.taxes + computationResult.social_contribution) }}</td>
                <td></td>
            </tr>
        </table>
   </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            body: {
                acquisition_date : undefined,
                property_type    : "secondary",
                is_bought        : true,
                selling_date     : undefined,
                initial_value    : 100000,
                acquisition_fees : 0,
                work_value       : 0,
                current_value    : 150000,
                selling_fees     : 0,
                is_main_property : false,
            },
            computationResult: undefined,
        }
    },
    watch: {
        'body.property_type': {
            handler() {
                if (this.body.property_type == 'main_property') {
                    this.body.is_main_property = true
                } else {
                    this.body.is_main_property = false
                }
            }
        },
        body: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/real-estate-gain', this.body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style scoped>
.calculation-datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.datas-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.datas-section label {
    font-size: 22px;
    font-weight: bold;
}

.datas-section input, select {
    font-size: 22px;
}

.calculation-result {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.calculation-result td, th {
    border: 1px solid lightgrey;
    padding: 10px;
}

</style>