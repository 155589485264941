<template>
    <div class="legacy">
        <form class="legacy-params">
            <label for="">Date de décès</label>
            <input type="date" v-model="death_date">
        </form>

        <form class="legacy-params" v-if="!isOriginal">
            <div>
                <label for="order">Ordre de succession avant optimisation</label>
                <select id="order" v-model="order">
                    <option :value="1">{{ $store.getters.userName }} puis {{ $store.getters.spouseName }}</option>
                    <option :value="2">{{ $store.getters.spouseName }} puis {{ $store.getters.userName }}</option>
                </select>
            </div>

            <div v-if="marital_contract_before.situation == 'married'">
                <label for="spouse-choice" >Choix du conjoint avant optimisation</label>
                <select id="spouse-choice" v-model="spouseChoiceBefore">
                    <option v-for="(value, key) in spouseChoicesBefore" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </form>

        <form class="legacy-params">
            <div>
                <label for="order">Ordre de succession <span v-if="!isOriginal">après optimisation</span></label>
                <select id="order" v-model="order">
                    <option :value="1">{{ $store.getters.userName }} puis {{ $store.getters.spouseName }}</option>
                    <option :value="2">{{ $store.getters.spouseName }} puis {{ $store.getters.userName }}</option>
                </select>
            </div>
            
            <div>
                <label for="spouse-choice" v-if="$store.getters.allowSpouseChoice">Choix du conjoint <span v-if="!isOriginal">après optimisation</span></label>
                <select id="spouse-choice" v-if="$store.getters.allowSpouseChoice" v-model="spouseChoice">
                    <option v-for="(value, key) in $store.getters.spouseChoices(order)" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </form>

        <table class="legacy-detail" v-for="(death, index) in deathList" :key="index">
            <thead>
                <th class="th-title">Décès {{ index + 1 }}</th>
                <th>Relation</th>
                <th>Donations reçues</th>
                <th>Héritage brut</th>
                <th>Capitaux décès bruts</th>
                <th>DMTG succession</th>
                <th>Prélèvements assurance</th>
                <th>Total net reçu au décès</th>
            </thead>

            <tr v-for="(inheritor, index) in death" :key="index">
                <td>{{ inheritor.firstname }} {{ inheritor.lastname }}</td>
                <td class="center-content">{{ translateRole(inheritor.role) }}</td>
                <td class="center-content">{{ toEuro(inheritor.total_gift) }}</td>
                <td class="center-content">{{ toEuro(inheritor.part_civile) }}</td>
                <td class="center-content">{{ toEuro(inheritor.partAsvValue) }}</td>
                <td class="center-content">{{ toEuro(inheritor.tax) }}</td>
                <td class="center-content">{{ toEuro(inheritor.tax990i + inheritor.tax757b) }}</td>
                <td class="center-content">{{ toEuro( ( inheritor.part_civile + inheritor.partAsvValue ) - ( inheritor.tax + inheritor.tax990i + inheritor.tax757b ) ) }}</td>
            </tr>
        </table>

        <table class="legacy-stats">
            <thead>
                <th>Total net transmis</th>
                <th>Total de l'impôt à payer</th>
                <th>Frais de notaire</th>
            </thead>

            <tr>
                <td class="center-content">{{ toEuro(totalReceived) }}</td>
                <td class="center-content">{{ toEuro(totalPayed) }}</td>
                <td class="center-content">{{ toEuro(totalNotary) }}</td>
            </tr>
        </table>
        
        <button class="btn-detail btn-orange" @click="toggleDetails">Voir le détail du calcul</button>

        <full-screen-modale :show="showDetails" @toggle="toggleDetails">
            <DetailLegacy
                :legacyResult="legacy"
                :order="order"
                :spouseChoice="spouseChoice"
            />
        </full-screen-modale>
    </div>
</template>

<script>
import axios from 'axios'
import DetailLegacy from './components/DetailLegacy.vue'

export default {
    props: ['isOriginal'],
    components: {
        DetailLegacy
    },
    data() {
        return {
            legacyId: undefined,
            death_date: undefined,
            marital_contract_before: {},
            spouseChoiceBefore: '1/4PP',
            orderBefore: 1,
            spouseChoice: '1/4PP',
            order: 1,
            legacy: {},
            body:{},
            showDetails: false,
        }
    },
    methods: {
        async getMaritalContractBefore() {
            const originalScenarioRes = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            const scenarioOriginal = originalScenarioRes.data

            const maritalContractRes = await axios.get(this.$store.getters.get_api_url + 'clients/marital-contract/' + scenarioOriginal._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.marital_contract_before = maritalContractRes.data
        },
        async computeLegacy() {
            try {
                this.saveLegacyParams()

                const body = {
                    datas: this.$store.getters.legacyDatas(this.order, false),
                    spouseChoice: this.spouseChoice,
                    death_date: this.death_date,
                }
                this.body=body
                
                const legacy = await axios.post(this.$store.getters.get_api_url + 'simulators/legacy', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.legacy = legacy.data

            } catch(err) {
                this.legacy = {}
            }
        },
        translateRole(role) {
            const ROLES = {
                spouse: 'Conjoint',
                child: 'Enfant',
                grandchild: 'Petit-enfant',
                parent: 'Parent',
                sibling: 'Frère/soeur',
                nephew: 'Neveu/Nièce',
                other: 'Tiers',
            }

            let result = undefined

            if (role == 'spouse') {
                const contract = this.$store.getters.getContract
                if (contract.situation == 'married') {
                    result = 'Conjoint'
                } else if (contract.situation == 'pacs') {
                    result = 'Partenaire de PACS'
                } else {
                    result = 'Concubin'
                }

            } else {
                result = ROLES[role]
            }
            

            return result
        },
        toggleDetails() {
            this.showDetails = !this.showDetails
        },
        async saveLegacyParams() {
            const body = {
                _id            : this.legacyId, 
                clientId       : this.$route.params.clientId,
                scenarioId     : this.$route.params.scenarioId,
                date           : this.death_date,
                order_before   : this.orderBefore,
                choice_before  : this.spouseChoiceBefore,
                order_after    : this.order,
                choice_after   : this.spouseChoice,
            }
            
            
            const res = await axios.post(this.$store.getters.get_api_url + 'clients/legacy-simulation', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            if (typeof res.data == 'string') {
                this.legacyId = res.data
            }
        },
    },
    watch: {
        spouseChoice() {
            this.computeLegacy()
        },
        order() {
            this.orderBefore = this.order
            this.computeLegacy()
        },
        spouseChoiceBefore() {
            this.saveLegacyParams()
        },
        orderBefore() {
            this.saveLegacyParams()
        },
        death_date() {
            this.computeLegacy()
        },
        '$store.state': {
            deep: true,
            handler() {
                this.computeLegacy()
            }
        },
        totalPayed() {
            this.$emit('updateTotalCost', this.totalPayed)
        }
    },
    computed: {
        spouseChoicesBefore() {
            let has_ddv = undefined

            if (this.orderBefore == 1)
                has_ddv = this.marital_contract_before.ddv_userforspouse
            else if (this.orderBefore == 2)
                has_ddv = this.marital_contract_before.ddv_spouseforuser

            if (has_ddv) {
                return {
                    '100%US': "Tout l'usufruit",
                    'QDPP': "La quotité disponible en pleine propriété",
                    '1/4PP': '1/4 en pleine propriété',
                    '3/4US+1/4PP': "1/4 en pleine propriété et 3/4 de l'usufruit",
                    'O%': 'Renonciation',
                }
            } else {
                return {
                    '100%US': "Tout l'usufruit",
                    '1/4PP': '1/4 en pleine propriété',
                    'O%': 'Renonciation',
                }
            }
        },
        deathList() {
            let result = []

            if (this.legacy.first_death) {
                result.push(this.legacy.first_death.inheritors)
            }

            if (this.legacy.second_death) {
                result.push(this.legacy.second_death.inheritors)
            }

            return result
        },
        totalPayed() {
            let total = 0

            this.legacy.first_death?.inheritors?.forEach(inheritor => {
                total += inheritor.tax
                total += inheritor.tax990i
                total += inheritor.tax757b
            });

            if (this.legacy.second_death) {
                this.legacy.second_death.inheritors?.forEach(inheritor => {
                    total += inheritor.tax
                    total += inheritor.tax990i
                    total += inheritor.tax757b
                });
            }

            return total
        },
        totalReceived() {
            let total = 0

            this.legacy.first_death?.inheritors?.forEach(inheritor => {
                total += inheritor.part_civile
                total += inheritor.partAsvValue
            });

            if (this.legacy.second_death) {
                this.legacy.second_death.inheritors?.forEach(inheritor => {
                    total += inheritor.part_civile
                    total += inheritor.partAsvValue
                });
            }

            return total - this.totalPayed
        },
        totalNotary() {
            let total = this.legacy.first_death?.datas.notarial_fees
            if (this.legacy.second_death?.datas.notarial_fees) {
                total += this.legacy.second_death?.datas.notarial_fees
            }
            return total
        },
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/legacy-simulation/' + this.$route.params.scenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.computeLegacy()

        if (res.data) {
            this.legacyId = res.data._id
            this.death_date = res.data.date
            this.spouseChoiceBefore = res.data.choice_before
            this.orderBefore = res.data.order_before
            this.spouseChoice = res.data.choice_after
            this.order = res.data.order_after
        }

        this.getMaritalContractBefore()
    }
}
</script>

<style scoped>

.legacy-params {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.legacy-params div {
    width: 100%;
    margin-top: 10px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}
.legacy-params div label, select, input {
    align-self: center;
    flex-basis: 100%;
}

table {
    width: 95%;
    margin: 10px auto;
    border-spacing : 0;
}

thead {
    background-color: rgba(10,28,107,.6);
    color: white;
    
}
th, td {
    border: solid 0.5px #eee9e0;
}

th {
    padding: 10px;
}

.th-title {
    background-color: rgb(216, 141, 2);
}

td {
    background-color: #f7efdf;
    padding: 5px;
    color: rgb(92, 91, 91);
}
.center-content {
    text-align: center;
}

.btn-detail {
    cursor: pointer;
    text-align: center;
    width: 20%;
    padding: 10px;
    margin: 10px;
    background-color: rgb(0, 0, 67);
    color: white;
}

.btn-orange {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    background-color: rgb(233, 110, 10);
    color: white;
}
</style>