<template>
    <div class="library">
        <h2 class="page-title">LBF Librairie</h2>
        <div class="separator"></div>

        <div class="files-frame">
            <div class="thematic-file" v-for="(file, index) in filesArray" :key="index" @click="openFile(file._id)">
                <h3>{{ file.title }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            filesArray: []
        }
    },
    methods: {
        async loadMainSections() {
            const res = await axios.get(this.$store.getters.get_api_url + 'library/library-section/?ismain=true', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.filesArray = res.data
        },
        openFile(fileId) {
            this.$router.replace('/librairie/home/' + fileId)
        }
    },
    mounted() {
        this.loadMainSections()
    }
}
</script>

<style scoped>
.library {
    padding: 20px 10%;
}

.page-title {
    color: rgb(0, 0, 99);
    font-size: 32px;
}

.files-frame {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.thematic-file {
    cursor: pointer;
    height: 250px;
    width: 250px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(0, 0, 99);
}
.thematic-file:hover {
    background-color: rgb(0, 0, 128);
}

.thematic-file h3 {
    font-family: 'Montserrat Alternates Semibold', 'Montserrat';
    font-size: 32px;
}

</style>