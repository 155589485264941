<template>
    <form>
        <div class="form-section">
            <label for="donor">Donateur</label>
            <select id="donor" v-model="donor">
                <option v-for="(value, index) in $store.getters.findDonors" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="['give_property', 'give_property_np'].includes(action)">
            <label for="property">Actif à donner</label>
            <select v-model="given_asset" id="property">
                <option v-for="asset in givable_list" :key="asset._id" :value="asset">
                    {{ asset.type }} - {{ toEuro(asset.value) }}
                </option>
            </select>
        </div>

        <div class="form-section" v-else>
            <label for="value">Montant total du don</label>
            <input id="value" type="number" v-model="value"/>
        </div>

        <div class="form-section">
            <label>Bénéficiaire(s)</label>
            <div class="multi-select">

                <multi-select
                    :elements="$store.getters.getAllInheritors"
                    title="Selectionnez les bénéficiaires" 
                    label="firstname"
                    :values="donees"
                    @closed="(result)=>{donees = result}"
                />
            </div>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['action'],
    data() {
        return {
            donor: undefined,
            value: 0,
            given_asset: undefined, 
            donees: [],
            errorMsg: "",
        }
    },
    methods: {
        async property_creation() {
            if (!this.owner) {
                this.errorMsg = "Choisissez un propriétaire"

            } else if (this.value < 1) {
                this.errorMsg = "Le montant du versement doit être supérieur à 0"

            } else {
                const TRAD_CATEGORY = {
                    main_property_creation: "residential",
                    location_creation: "yield",
                    scpi_creation: "yield"
                }

                const TRAD_TYPE = {
                    main_property_creation: "main_property",
                    location_creation: "location",
                    scpi_creation: "scpi"
                }

                let detention = []
                if (this.owner == 'commun') {
                    detention = [ 
                        { owner_id: this.$store.getters.userId, part: 0.5, right: 'pp' },
                        { owner_id: this.$store.getters.spouseId, part: 0.5, right: 'pp' },
                    ]
                } else {
                    detention = [ 
                        { owner_id: this.owner, part: 1, right: 'pp' },
                    ]
                }

                const body = {
                    clientId                 : this.$route.params.clientId,
                    scenarioId               : this.$route.params.scenarioId,
                    label                    : undefined,
                    category                 : TRAD_CATEGORY[this.action],
                    type                     : TRAD_TYPE[this.action],
                    is_furnished             : false,
                    value                    : this.value,
                    isIndivision             : false,
                    detention                : detention,
                    opening_date             : new Date(),
                    total_payment            : this.value,
                    isfixedTermDismemberment : false,
                    isIpCreation             : true,
                }
                
                await this.$store.dispatch('create_property', body)
                this.$emit('closeModale')
            }
        },
        submit() {
            this.property_creation()
        }
    },
    computed: {
        givable_properties() {
            let list = []
            if (this.donor == 'commun') {

                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }

            return list.map(a => {
                let copy = JSON.parse(JSON.stringify(a))
                copy.type = this.$store.getters.propertyTypes[a.category][a.type]
                return copy
            })
        },
        givable_assets() {
            if (this.donor == 'commun') {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }
        },
        givable_societies() {
            let list = []
            if (this.donor == 'commun') {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }

            return list.map(a => {
                let copy = JSON.parse(JSON.stringify(a))
                copy.type = this.$store.getters.societyTypes[a.type]
                copy.value = this.$store.getters.getSocietyValue(a._id)
                return copy
            })
        },
        givable_movable() {
            if (this.donor == 'commun') {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }
        },
        givable_list() {
            if (['give_property', 'give_property_np'].includes(this.action)) {
                return this.givable_properties
            } else {
                return []
            }
            
        },
        body() {
            const TRAD_TYPE = {
                give_property: "property",
                give_property_np: "property",
                give_money_790G: "liquidity",
                give_money_other: "manual", 
            }

            const TRAD_DISMEMBERMENT = {
                give_property: false,
                give_property_np: true,
                give_money_790G: false,
                give_money_other: false, 
            }

            return {
                _id: undefined,
                clientId: this.$route.params.clientId,
                scenarioId: this.$route.params.scenarioId,
                donor: undefined,
                donee: undefined,
                type: TRAD_TYPE[this.action],
                value: 0,
                fiscal_value: 0,
                current_value: 0,
                date: new Date(),
                aps: true,
                given_asset: undefined,
                is_shared: false,
                is_dismembered: TRAD_DISMEMBERMENT[this.action],
                isIpCreation: true
            }
        }
    }
}
</script>

<style src="./form_styles.css" scoped></style>