<template>
    <table>
        <tr v-for="(el, index) in elements" :key="index">
            <td>{{ el.firstname }} {{ el.lastname }}</td>
            <td class="check-frame"><input type="checkbox" v-model="datas[el._id]"></td>
        </tr>
    </table>
</template>

<script>

export default {
    emits: ['closed'],
    props: ['elements', 'values'],
    data() {
        return {
            datas: {}
        }
    },
    watch: {
        datas: {
            deep: true, 
            handler() {
                let result = []

                Object.keys(this.datas).forEach(id => {
                    if (this.datas[id]) {
                        result.push(id)
                    }
                })
                
                this.$emit('closed', result)
            }
        }
    },
    mounted() {
        this.elements.forEach(el => {
            this.datas[el._id] = false
        })

        if (this.values) {
            Object.keys(this.datas).forEach(id => {
                if (this.values.includes(id)) {
                    this.datas[id] = true
                }
            })
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
    border-bottom: solid 1px rgb(251, 248, 248);
    margin-bottom: 10px;
}
td {
    width: 50%;
    padding: 0 10px;
}
.check-frame {
    text-align: center;
}
</style>