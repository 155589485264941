<template>
    <div class="rank" v-if="show">

        <!-- Bénéfifiaires -->
        <div class="rank-label">
            <p>
                <span>Bénéficiaires</span>
                <span v-if="isDismembered && index == 0"> de l'usufruit</span>
                <span v-else-if="isDismembered && index == 1"> de la nue-propriété</span>
                <span v-else> de rang {{index + 1}}</span>
            </p>
            
            <div class="delete-rank-btn" @click="deleteRank">-</div>
        </div>

        <multi-select
            :elements="beneficiariesList"
            :values="beneficiaries"
            @closed="(result)=>{ beneficiaries = result }"
        />

        <!-- représentation -->
        <div class="check-container">
            <label>Représentation</label>
            <input type="checkbox" v-model="representation">
        </div>

        <!-- Repartition -->
        <div class="check-container">
            <label>Repartition</label>
            <input type="checkbox" v-model="repartition">
        </div>

        <table class="repartition_detail" v-if="repartition">
            <tr v-for="beneficiaryDetail in repartition_detail" :key="beneficiaryDetail.beneficiary_id">
                <td>{{ $store.getters.findPersonName(beneficiaryDetail.beneficiary_id) }}</td>
                <td><input type="number" v-model="beneficiaryDetail.percentage"></td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: [
        'isDismembered', 
        'index',
        'rank',
        'beneficiariesList'
    ],
    data() {
        return {
            show: false,
            beneficiaries: [],
            representation: false,
            repartition: false,
            repartition_detail: []
        }
    },
    watch: {
        rank: {
            deep: true,
            handler() {
                this.beneficiaries = this.rank.beneficiaries
                this.representation = this.rank.representation
                this.repartition = this.rank.repartition
                this.repartition_detail = this.rank.repartition_detail
            }
        },
        beneficiaries: {
            deep: true,
            handler() {
                if (this.repartition) {
                    try {
                        this.repartition_detail.find(rd => rd == 0)
                    } catch(err) {
                        // Si le repartition_detail n'est pas un array, c'est l'ancien format
                        this.repartition_detail = []
                    }
                    
                    this.repartition_detail = this.repartition_detail.filter(rd => this.beneficiaries.includes(rd.beneficiary_id)) // Suppression des bénéficiaires supprimés.
                    const missing_beneficiaries = this.beneficiaries.filter(b => !this.repartition_detail.map(rd => rd.beneficiary_id).includes(b))
                    
                    for (const missing_beneficiary of missing_beneficiaries) {
                        this.repartition_detail.push({
                            beneficiary_id: missing_beneficiary,
                            percentage: 0,
                        })
                    }
                }
                this.updateRank()
            },
        },
        representation() {
            this.updateRank()
        },
        repartition() {
            if (this.repartition && (!this.repartition_detail || this.repartition_detail.length == 0)) {
                this.repartition_detail = []

                for (const beneficiaryId of this.beneficiaries) {
                    this.repartition_detail.push({
                        beneficiary_id: beneficiaryId,
                        percentage: 100 / this.beneficiaries.length
                    })
                }
            }
            this.updateRank()
        },
        repartition_detail: {
            deep: true,
            handler() {
                this.updateRank()
            }
        }
    },
    methods: {
        deleteRank() {
            this.$emit('deleteRank')
        },
        updateRank() {
            this.$emit('updateRank', {
                beneficiaries: this.beneficiaries,
                representation: this.representation,
                repartition: this.repartition,
                repartition_detail: this.repartition_detail,
            })
        }
    },
    mounted() {
        this.beneficiaries = this.rank.beneficiaries
        this.representation = this.rank.representation
        this.repartition = this.rank.repartition
        if(this.rank.repartition_detail) {
            this.repartition_detail = this.rank.repartition_detail
        }

        this.show = true
    }
}
</script>

<style scoped>

</style>