export default {
    errorMsg: "",
    validationMsg: "",
    // api_url: 'http://localhost:3000/api/',
    api_url: 'https://api-bo-lbf-basile.onrender.com/api/',
    auth: {
        token: undefined,
        adminId: undefined,
    },
    admin: {},
    marital_contract: {},
    persons: [],
    properties: [],
    financial_assets: [],
    societies: [],
    movable_assets: [],
    insurances: [],
    gifts: [],
    debts: [],
    wills: [],
    rewards: [],
    incomes: [],
    tax_reductions: [],
    deductible_expenses: [],
    user_career: {},
    spouse_career: {},
}