<template>
    <div class="list-section">
        <h1 class="section-title">
            Donations
            
            <add-btn type="gift"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="gift in $store.getters.getAllGifts"
                :key="gift._id"
                :object="gift"
                type="gift"
            />
        </table>
    </div>
</template>

<script>
export default {

    data() {
        return {}
    },
}
</script>
