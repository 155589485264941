<template>
    <section>
        <div class="btn-simulation" @click="toggleModale">Simuler</div>

        <modale :show="showModale" @toggle="toggleModale" width='80%'>

            <div class="result-menu">
                <div class="windows" :class="{'selected-window': windowActive=='legacy'}" @click="windowActive='legacy'">Succession</div>
                <div class="windows" :class="{'selected-window': windowActive=='taxes'}"  @click="windowActive='taxes'">Impôt sur le revenu</div>
                <div class="windows" :class="{'selected-window': windowActive=='ifi'}"  @click="windowActive='ifi'">Impôt sur la fortune</div>
                <!-- <div class="windows" :class="{'selected-window': windowActive=='investment'}"  @click="windowActive='investment'">Projections</div> -->
            </div>

            <div class="result">
                <LegacyResult v-if="windowActive=='legacy'"/>
                <TaxesResult v-else-if="windowActive=='taxes'"/>
                <WealthTaxes v-else-if="windowActive=='ifi'"/>
                <InvestmentResult v-else-if="windowActive=='investment'"/>
            </div>
        </modale>
    </section>
</template>

<script>
import LegacyResult from './Legacy.vue'
import TaxesResult from './Taxes.vue'
import InvestmentResult from './Investments.vue'
import WealthTaxes from './WealthTaxes.vue'

export default {
    components: { LegacyResult, TaxesResult, InvestmentResult, WealthTaxes },
    data(){
        return {
            showModale: false,
            windowActive: 'legacy',
            windows: {
                legacy: 'Succession',
                taxes: 'Impôt sur le revenu',
                investment: 'Projections'
            },
        }
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
    },
}
</script>

<style scoped>
.result {
    padding: 30px 0;
}

.btn-simulation {
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 15px;
    border-radius: 10px;
    background-color: #fb9e4b;
}
.btn-simulation:hover {
    background-color: rgba(10, 28, 107, 0.7);
    color: white;
}

.result-menu {
    display: flex;
    justify-content: space-around;
}
.windows {
    cursor: pointer;
    text-align: center;
    flex-basis: 50%;
    background-color: #f2f5f7;
    padding: 15px;
}
.selected-window {
    background-color: rgb(1, 1, 76);
    color: #f2f5f7;
}
</style>