import { createStore } from 'vuex'

import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

import ActionModule from './modules/actions/index.js'


const store = createStore({
    state () {
        return state
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
    modules: {
        actions: ActionModule,
    }
})

export default store