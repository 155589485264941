<template>
    <div class="side-template">
        <div class="template-content">
            <person-icon />

            <h2>{{$store.getters.userName}}</h2>

            <h4>Recommandation MS</h4>

            <h5 class="btn-return" @click="openProfile">Revenir à son dossier</h5>

            <button>Télécharger sa fiche</button>

            <div class="separator"></div>

            <ul>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/audit'">Audit patrimonial</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/overview'">Simulation des solutions</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/solutions'">Solutions d'optimisation</router-link></li>
                <li class="selected-menu"><router-link :to="'/client/' + $route.params.clientId + '/recommendation/' + $route.params.recommendationId + '/recommendation'">Récapitulatif</router-link></li>
            </ul>

            <div class="separator"></div>

            <h4>Paramètres</h4>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {}
    },
    methods: {
        openProfile() {
            this.$router.replace('/client/' + this.$route.params.clientId + '/view/')
        },
    }
}
</script>

<style scoped>
.side-template {
    background-color: rgb(30,44,88);
}
.template-content {
    position: fixed;
    top: 100px;
    left: 25px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
}
.separator {
    height: 2px;
    width: 100%;
    background-color: #fb9e4b;
    margin: 50px 0;
    width: 80%;
}
.side-template h2 {
    color: white;
    text-align: center;
}
.side-template h4 {
    cursor: pointer;
}
.btn-return {
    cursor: pointer;
    color: orange;
    text-decoration: underline;
}
.side-template a {
    cursor: pointer;
    color: white;
    font-weight: lighter;
    text-decoration: none;
}
.side-template .router-link-active {
    cursor: pointer;
    color: #fb9e4b;
    font-weight: lighter;
}
.side-template h5:hover {
    font-weight: bold;
}
.side-template li {
    padding: 20px 0;
}

textarea {
    width: 95%;
}

.selected-menu {
    cursor: pointer;
    color: #fb9e4b;
}
</style>