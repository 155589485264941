<template>
    <div>   
        <form>
            <div class="form-section">
                <label>Choisissez une catégorie d'action</label>
                <select v-model="category">
                    <option v-for="(value, key) in action_categories" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="form-section" v-if="category">
                <label>Choisissez une action à réaliser</label>
                <select v-model="action">
                    <option v-for="(value, key) in actionsList[category]" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
        </form>

        <FinancialInvestmentForm
            v-if="category == 'financial_investment' && action != undefined"
            :action="action"
            @closeModale="closeModale"
        />

        <PropertyInvestmentForm
            v-if="category == 'property_investment' && action != undefined"
            :action="action"
            @closeModale="closeModale"
        />

        <PropertySellForm
            v-if="category == 'property_sell' && action != undefined"
            :action="action"
            @closeModale="closeModale"
        />

        <GiftForm
            v-if="category == 'gift' && action != undefined"
            :action="action"
            @closeModale="closeModale"
        />
    </div>
    
</template>

<script>
import FinancialInvestmentForm from './action_forms/FinancialInvestmentForm.vue';
import PropertyInvestmentForm from './action_forms/PropertyInvestmentForm.vue';
import PropertySellForm from './action_forms/PropertySellForm.vue';
import GiftForm from './action_forms/GiftForm.vue';

export default {
    components: {
        FinancialInvestmentForm,
        PropertyInvestmentForm,
        PropertySellForm,
        GiftForm,
    },
    data() {
        return {
            category: undefined,
            action: undefined,
            action_categories: {
                financial_investment: "Investissement financier",
                property_investment: "Investissement immobilier",
                gift : "Dons et donations",
                property_sell: "Vente immobilière",
                financial_sell: "Retrait d'un produit financier",
                other: "Autres actions",
            },
            actionsList: {
                financial_investment: {
                    asv_creation: "Souscription d'un contrat d'assurance vie",
                    asv_payment: "Versement sur un contrat d'assurance vie existant",
                    pea_creation: "Versement sur un PEA",
                    capi_creation: "Versement sur un contrat de capitalisation",
                    csl_creation: "Versement sur un livret d'épargne",
                    cat_creation: "Versement sur un compte à terme",
                    fcpi_creation: "Achat de parts de FCPI",
                    fip_creation: "Achat de parts de FIP",
                    fcpr_creation: "Achat de parts de FCPR",
                },
                property_investment: {
                    main_property_creation: "Achat d'un bien immobilier",
                    location_creation: "Achat d'un bien locatif",
                    scpi_creation: "Achat de parts de SCPI",
                },
                gift : {
                    give_property: "Donation d'un bien immobilier",
                    give_property_np: "Donation d'un bien immobilier en nue-propriété",
                    give_money_790G: "Don familial de liquidités (790G)",
                    give_money_other: "Autre don manuel",   
                },
                property_sell: {
                    sell_main_property: "Vente de la résidence principale",
                    location_sell: "Vente d'un investissement locatif",
                    scpi_sell: "Vente de parts de SCPI",
                },
                financial_sell: {
                    asv_sell: "Retrait d'un contrat d'assurance vie",
                    pea_sell: "Retrait d'un PEA",
                    liquidity_sell: "Retrait de l'épargne bancaire",
                },
                other: {
                    asv_clause_modification: "Modification d'une clause bénéficiaire",
                }
            }
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModale')
        }
    }
}
</script>

<style src="./action_forms/form_styles.css" scoped></style>
