<template>
     <section>
        <p>La part finalement reçue par les héritiers sera finalement la suivante : </p>

        <table>
            <tr>
                <th>Héritier</th>
                <th>Relation</th>
                <th>Droits</th>
                <th>Legs reçus</th>
                <th>Part légale</th>
                <th>Indémnité de réduction</th>
                <th>Total des droits au décès</th>
                <th>En valeur</th>
            </tr>

            <tbody v-for="inheritor in inheritorsList" :key="inheritor._id">
                <tr>
                    <td rowspan=3>{{ inheritor.firstname }} {{ inheritor.lastname }}</td>
                    <td rowspan=3>{{ $store.getters.allRoles[inheritor.role] }}</td>
                    <td>Pleine propriété</td>
                    <td>{{ toEuro(Math.min(inheritor.legs.US, inheritor.legs.NP)) }}</td>
                    <td>{{ toEuro(Math.min(inheritor.devolution_part.US, inheritor.devolution_part.NP)) }}</td>
                    <td>{{ toEuro(Math.min(inheritor.reduction.US, inheritor.reduction.NP)) }}</td>
                    <td>{{ toEuro(Math.min(inheritor.legs.US + inheritor.devolution_part.US + inheritor.reduction.US, inheritor.legs.NP + inheritor.devolution_part.NP + inheritor.reduction.NP)) }}</td>
                    <td rowspan=3>{{ toEuro(inheritor.part_civile) }}</td>
                </tr>

                <tr>
                    <td>Usufruit</td>
                    <td>{{ toEuro(Math.max(inheritor.legs.US - inheritor.legs.NP, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.devolution_part.US - inheritor.devolution_part.NP, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.reduction.US - inheritor.reduction.NP, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.legs.US + inheritor.devolution_part.US + inheritor.reduction.US - inheritor.legs.NP - inheritor.devolution_part.NP - inheritor.reduction.NP, 0)) }}</td>
                </tr>

                <tr>
                    <td>Nue-propriété</td>
                    <td>{{ toEuro(Math.max(inheritor.legs.NP - inheritor.legs.US, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.devolution_part.NP - inheritor.devolution_part.US, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.reduction.NP - inheritor.reduction.US, 0)) }}</td>
                    <td>{{ toEuro(Math.max(inheritor.legs.NP + inheritor.devolution_part.NP + inheritor.reduction.NP - inheritor.legs.US - inheritor.devolution_part.US - inheritor.reduction.US, 0)) }}</td>
                </tr>
            </tbody>
        </table>

        <p>En cas de décès d'un héritier légal, ses propres héritiers se partagent sa part par représentation. </p>

    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
        }
    },
    computed: {
        inheritorsList() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.inheritors.filter(i => i.part_civile > 0)
            } else {
                return this.legacyResult.first_death.inheritors.filter(i => i.part_civile > 0)
            }
        }
    },
}
</script>

<style scoped src="./style.css"></style>