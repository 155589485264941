<template>
    <div>
        <h2 class="category-title">Situation familiale</h2>
        <div class="element">
            <h3>{{ $store.getters.maritalSituations[marital_contract.situation] }}</h3>
            <table>
                <tr v-if="['married', 'pacs'].includes(marital_contract.situation)">
                    <td class="col1">Régime matrimonial</td>
                    <td class="col1">{{ $store.getters.maritalContracts[marital_contract.situation][marital_contract.regmat] }}</td>
                </tr>

                <tr v-if="['separation'].includes(marital_contract.regmat)">
                    <td class="col1">Société d'acquêts</td>
                    <td class="col1">{{ marital_contract.acquest_society }}</td>
                </tr>

                <tr v-if="['aquest_community', 'aquest_movable_community', 'universal_community'].includes(marital_contract.regmat) || marital_contract.acquest_society">
                    <td class="col1">Clause d'attribution intégrale</td>
                    <td class="col1">{{ marital_contract.full_attribution }}</td>
                </tr>

                <tr v-if="['married'].includes(marital_contract.situation)">
                    <td class="col1">Donation au dernier vivant de {{ $store.getters.userName }}</td>
                    <td class="col1">{{ marital_contract.ddv_userforspouse }}</td>
                </tr>

                <tr v-if="['married'].includes(marital_contract.situation)">
                    <td class="col1">Donation au dernier vivant de {{ $store.getters.spouseName }}</td>
                    <td class="col1">{{ marital_contract.ddv_spouseforuser }}</td>
                </tr>
            </table>

            <div class="btns-container">
                <div class="btn btn-modify" @click="toggleModifyMaritalSituation">MODIFIER</div>

                <modale :show="showModifyMaritalSituation" @toggle="toggleModifyMaritalSituation">
                    <MaritalContract :import="marital_contract" @closeModale="toggleModifyMaritalSituation"/>
                </modale>
            </div>
        </div>

        <h2 class="category-title">Utilisateurs</h2>

        <div class="elements-container">
            <div class="element" v-for="person in $store.getters.getParents" :key="person._id">
                <h3>{{ person.firstname }} {{ person.lastname }}</h3>
                <table>
                    <tr>
                        <td class="col1">Date de naissance</td>
                        <td class="col1">{{ dateToString(person.birthdate) }}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-modify" @click="toggleModifyModale(person._id)">MODIFIER</div>
                    <div class="btn btn-delete" @click="deleteItem(person._id)">SUPPRIMER</div>

                    <modale :show="showModifyModale[person._id]" @toggle="toggleModifyModale(person._id)">
                        <Person :import="person" @closeModale="toggleModifyModale(person._id)"/>
                    </modale>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Person from '../../../forms/Person.vue'
import MaritalContract from '../../../forms/Couple.vue'

export default {
    components: {Person, MaritalContract},
    data() {
        return {
            marital_contract: {},
            showModifyMaritalSituation: false,
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm('Êtes vous sur de vouloir supprimer cette personne ?')
            if (valid) {
                this.$store.dispatch('delete_person', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        },
        toggleModifyMaritalSituation() {
            this.showModifyMaritalSituation = !this.showModifyMaritalSituation
            console.log(this.showModifyMaritalSituation)
        }
    },
    beforeUpdate() {
        if (Object.keys(this.marital_contract).length == 0) {
            this.marital_contract = this.$store.getters.getContract
        }
    }
}
</script>

<style src="../style.css" scoped/>