<template>
   <div class="calculator-frame">

        <h2>Calcul de la fiscalité d'une donation</h2>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Lien de parenté avec le donataire</label>
                <select v-model="donee_role">
                    <option v-for="(value, key) in filteredRoles" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Montant de la donation réalisée</label>
                <input type="number" v-model="donation_value">
            </div>

            <div class="datas-section">
                <label>Montant des donations réalisées les 15 dernières années</label>
                <input type="number" v-model="past_donation_value">
            </div>
        </div>

        <div class="separator"></div>

        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Montant imposable avant abattement</th>
                <td>{{ toEuro(donation_value) }}</td>
            </tr>

            <tr>
                <th>Abattement disponible</th>
                <td>{{ toEuro(computationResult[1].reduction_available) }}</td>
            </tr>

            <tr>
                <th>Valeur imposable</th>
                <td>{{ toEuro(computationResult[1].taxable) }}</td>
            </tr>

            <tr>
                <th>Montant des DMTG</th>
                <td>{{ toEuro(computationResult[1].tax) }}</td>
            </tr>

            <tr>
                <th>Abattement restant</th>
                <td>{{ toEuro(computationResult[1].reduction_available - computationResult[1].used_reduction) }}</td>
            </tr>
        </table>

        <h2>Impacts sur le barème</h2>
        
       <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Tranche</th>
                <th>Taux</th>
                <th>Disponible</th>
                <th>Utilisé sur cette donation</th>
                <th>Restant pour les prochaines donations</th>
            </tr>

            <tr v-for="(scale, index) in computationResult[1].used_scales" :key="index">
                <td v-if="scale.max">Jusqu'à {{ toEuro(scale.max) }}</td>
                <td v-else>Au-delà</td>
                <td>{{ scale.rate * 100 }} %</td>
                <td v-if="scale.max">{{ toEuro(scale.available) }}</td>
                <td v-else>Infini</td>
                <td>{{ toEuro(scale.used) }}</td>
                <td>{{ toEuro(scale.available - scale.used) }}</td>
            </tr>
       </table>
   </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            donee_role: 'child',
            donation_value: 100000,
            past_donation_value: 0,
            computationResult: undefined,
        }
    },
    computed: {
        filteredRoles() {
            let result = {}

            Object.keys(this.$store.getters.allRoles).forEach(key => {
                if (key != 'user') {
                    result[key] = this.$store.getters.allRoles[key]
                }
            })

            return result
        },
        requestBody() {
            let body = {
                donations: [
                    {
                        _id: 'pastdonations',
                        type: 'manual',
                        donee: 'donee1',
                        fiscal_value: this.past_donation_value,
                        date: new Date()
                    },
                    {
                        _id: 'currentdonation',
                        type: 'manual',
                        donee: 'donee1',
                        fiscal_value: this.donation_value,
                        date: new Date()
                    }
                ],
                donee: {
                    _id: 'donee1',
                    role: this.donee_role,
                }
            }

            return body
        }
    },
    watch: {
        requestBody: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/donation-taxes/', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style scoped>
.calculation-datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.datas-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.datas-section label {
    font-size: 22px;
    font-weight: bold;
}

.datas-section input, select {
    font-size: 22px;
}

.calculation-result {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

.calculation-result td, th {
    border: 1px solid lightgrey;
    padding: 10px;
}

</style>