<template>
    <div class="clients-array-container">
        <div class="page-title">Tableau de bord</div>
        <div class="btn-frame">
            <div class="btn-import" @click="toggleModale">Importer un client</div>
            <div class="btn-create" @click="createClient">Créer un client</div>
        </div>
       
        <div class="separator"></div>

        <div class="filters">
            <div class="filters-frame">
                <section>
                    <label>Ingénieur patrimonial</label>
                    <select v-model="filters.engineer">
                        <option v-for="engineer in engineers_list" :key="engineer.id" :value="engineer.id">{{ engineer.value }}</option>
                    </select>
                </section>

                <section>
                    <label>Client</label>
                    <input type="text" v-model="filters.clientName">
                </section>
            </div>

            <div class="btns-frame">
                <div class="btn btn-orange" @click="loadClients">Rechercher</div>
                <div class="btn btn-blue" @click="eraseFilters">Effacer les filtres</div>
            </div>
            
        </div>

        <table class="client-table">
            <tr>
                <th class="col-1">Client</th>
                <th class="col-2">Ingénieur patrimonial</th>
                <th class="col-2">Date de création</th>
                <th class="col-3">Action</th>
            </tr>

            <tr v-for="client in client_list" :key="client._id">
                <td class="col-1">{{ client.firstname }} {{ client.lastname }}</td>
                <td class="col-2">{{ client.engineerName }}</td>
                <td class="col-2">{{ dateToString(client.creationDate) }}</td>
                <td class="col-3">
                    <button class="btn-view" @click="viewProfile(client._id)">Voir le profil</button>
                </td>
            </tr>
        </table>

        <modale :show="showModale" @toggle="toggleModale">
            <div class="import-datas-modale">
                <h2>Importation d'un client depuis le back-office LBF</h2>
                <span>Rendez-vous sur le scenario du client : </span>
                <p>Clic droit > Inspecter > Network > Response > Info (dernier élément de la liste)</p>
                <p>Copiez la totalité du texte et collez-le dans l'espace ci-dessous.</p>

                <textarea v-model="lbfDatas"></textarea>

                <section class="duplicate-box" v-if="duplicate_list.length > 0">
                    <p>Souhaitez vous lier ce scenario à un des clients suivants ? </p>
                    <select v-model="selected_duplicate">
                        <option :value="undefined">NON</option>
                        <option v-for="duplicate in duplicate_list" :key="duplicate._id" :value="duplicate._id">{{ duplicate.lbfName }} - Créé le {{ dateToString(duplicate.creationDate) }}</option>
                    </select>

                    <label>Nom du scenario</label>
                    <input type="text" v-model="scenario_label">
                </section>

                <loader-spin v-if="isLoading"></loader-spin>
                <div v-else class="import-btn" @click="ImportDatas">Importer</div>
            </div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            filters: {
                engineer: undefined,
                clientName: "",
            },
            engineers_list: [],
            client_list: [],
            showModale: false,
            isLoading: false,
            lbfDatas: "",
            duplicate_list: [],
            selected_duplicate: undefined,
            scenario_label: "Import LBF"
        }
    },
    watch: {
        async lbfDatas() {
            if (this.lbfDatas != '') {
                const jsonDatas = JSON.parse(this.lbfDatas)
                const user = jsonDatas.personResults.find(person => person.type == 'main')
                const userName = user.firstname + ' ' + user.lastname

                const duplicate_response = await axios.post(this.$store.getters.get_api_url +'clients/duplicate/', {lbfName: userName}, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.duplicate_list = duplicate_response.data
            }
            
        }
    },
    methods: {
        toggleModale() {
            this.showModale= !this.showModale
        },
        createClient() {
            const clientDatas = {
                "id": "f0475f4a-2190-4de1-b559-2f5d111ba577",
                "personResults": [
                    {
                        "id": "bee7867f-e3b8-4d54-8346-27758cb49ec0",
                        "type": "main",
                        "linkType": "main",
                        "civility": "mr",
                        "lastname": "Client",
                        "maidenName": null,
                        "firstname": "Nouveau",
                        "birthdate": "1970-01-01T00:00:00+00:00",
                        "will": null,
                        "isDead": false,
                        "personType": "main",
                        "isHandicaped": false,
                        "has_testament": false,
                        "testament_clause": null,
                        "partner_donation": false
                    }
                ],
                "debtResults": [],
                "donationPropertyResults": [],
                "financialPropertyResults": [],
                "lifeInsuranceResults": [],
                "movablePropertyResults": [],
                "privateShareResults": [],
                "realEstatePropertyResults": [],
                "couple": {
                    "maritalStatus": "single",
                    "matrimonialSystem": "",
                    "fullAttribution": false,
                    "acquestSociety": false,
                    "preciput": false,
                    "inegalRepartition": false,
                    "mainPercentage": 0,
                    "spousePercentage": 0,
                    "partnerDonationUserToSpouse": false,
                    "partnerDonationSpouseToUser": false,
                    "couplePreciputDetails": []
                }
            }

            this.lbfDatas = JSON.stringify(clientDatas)
            this.toggleModale()
            this.ImportDatas()
        },
        async loadEngineers() {
            const engineers_res = await axios.get(this.$store.getters.get_api_url +'admins/all/?active=true', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            let engineer_list = [{ id: undefined, value: '---' }]
            
            engineers_res.data.forEach(engineer => {
                engineer_list.push({
                    id: engineer._id,
                    value: engineer.firstname + ' ' + engineer.lastname
                 })
            });

            this.engineers_list = engineer_list
        },
        viewProfile(id) {
            this.$router.replace('/client/'+ id + '/view/')
        },
        async ImportDatas() {
            this.isLoading = true
            if (this.lbfDatas && this.lbfDatas != "") {
                
                try {
                    await axios.post(this.$store.getters.get_api_url +'clients/import-from-lbf/', {
                        lbfDatas: this.lbfDatas,
                        engineerId: this.$store.getters.getAdminId,
                        clientId: this.selected_duplicate,
                        scenarioLabel: this.scenario_label
                    }, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })

                    this.loadClients()
                    this.toggleModale()
                } catch(err) {
                    console.log(err)
                    alert("Erreur lors de l'import des données")
                    this.isLoading = false
                }
                
            }
            this.isLoading = false
        },
        async loadClients() {
            const client_response = await axios.post(this.$store.getters.get_api_url +'clients/client-search/', this.filters, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.client_list = client_response.data
            this.client_list.sort((a, b) =>  new Date(b.creationDate) - new Date(a.creationDate))
        },
        eraseFilters() {
            this.filters = {
                engineer: undefined,
                clientName: "",
            }
        },
    },
    mounted() {
        this.filters.engineer = this.$store.getters.getAdminId
        this.loadClients()
        this.loadEngineers()
    }
}
</script>

<style scoped>
.clients-array-container {
    position: relative;
    padding: 20px 15%;
}
.page-title {
    font-size: 32px;
    font-weight: bold;
    color: rgb(0, 0, 83);
}
.btn-frame {
    position: absolute;
    top: 10px;
    right: 10%;
    display: flex;
}
.btn-import {
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(255, 136, 0);
    color: white;
    margin: 3px;
}
.btn-create {
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(5, 0, 99);
    color: white;
    margin: 3px;
}

.filters {
    display: flex;
    justify-content: space-between;
}
.filters-frame {
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
}
.filters-frame section {
    color: rgb(5, 5, 92);
    margin: 5px;
    display: flex;
    flex-direction: column;
}
.btns-frame {
    display: flex;
    flex-direction: column;
}
.btns-frame .btn {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 50px;
    text-align: center;
    margin: 5px;
}
.btns-frame .btn:hover {
    border-radius: 0px;
}
.btn-orange {
    background-color: rgb(255, 175, 27);
}
.btn-blue {
    background-color: rgb(5, 0, 99);
    color: white;
}

.client-table {
    margin-top: 20px;
    border-collapse: collapse;
    background-color: white;
    font-size: 17px;
    width: 100%;
}
.col-1 {
    width: 40%;
}
.col-2 {
    width: 20%;
    text-align: center;
}
.col-3 {
    width: 20%;
    text-align: center;
}
.client-table th {
    background-color: rgba(30,44,88, 0.8);
    color: white;
    border: 1px solid lightgrey;
    padding: 10px;
}
.client-table td {
    border: 1px solid lightgrey;
    padding: 10px;
}
.btn-view {
    cursor: pointer;
    font-size: 17px;
    color: rgb(255, 136, 0);
    background-color: white;
    border: none;
}

.import-datas-modale {
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
}
.import-datas-modale textarea {
    width: 100%;
    margin: 10px 0;
    height: 300px;
}

.duplicate-box {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
}
.choices-frame {
    display: flex;
    justify-content: space-evenly;
}
.choice {
    cursor: pointer;
    margin: 10px 0;
    padding: 10px 15px;
    background-color: lightgrey;
    border-radius: 5px;
}
.import-btn {
    cursor: pointer;
    padding: 12px;
    text-align: center;
    font-size: 18px;
    font-weight:bold;
    background-color: orange;
    color: white;
    border-radius: 10px;
    display: inline-block;
    width: 100px;

}
</style>