<template>
    <div>
        <h2 class="category-title">Contrats d'assurance</h2>

        <div class="elements-container">
            <div class="element" v-for="asset in $store.getters.getAllInsurances" :key="asset._id">
                <h3>{{ asset.label || $store.getters.insuranceTypes[asset.type] }}</h3>
                <table>
                    <tr>
                        <td class="col1">Type de contrat</td>
                        <td class="col2">{{ $store.getters.insuranceTypes[asset.type] }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Date de souscription</td>
                        <td class="col2">{{ dateToString(asset.opening_date) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Valeur de rachat</td>
                        <td class="col2">{{ toEuro(asset.value) }}</td>
                    </tr>
                    <tr>
                        <td class="col1">Souscripteur</td>
                        <td class="col2">{{ $store.getters.findPersonName(asset.owner1) }}</td>
                    </tr>
                </table>

                <div class="btns-container">
                    <div class="btn btn-modify" @click="toggleModifyModale(asset._id)">MODIFIER</div>
                    <div class="btn btn-delete" @click="deleteItem(asset._id)">SUPPRIMER</div>

                    <modale :show="showModifyModale[asset._id]" @toggle="toggleModifyModale(asset._id)">
                        <Insurance :import="asset" @closeModale="toggleModifyModale(asset._id)"/>
                    </modale>
                </div>
            </div>

            <div class="add-btn" @click="toggleModale">
                <div>+</div>

                <modale :show="showModale" @toggle="toggleModale">
                    <Insurance @closeModale="toggleModale"/>
                </modale>
            </div>
        </div>
    </div>
</template>

<script>
import Insurance from '../../../forms/Insurance.vue'

export default {
    components: {Insurance},
    data() {
        return {
            showModale: false,
            showModifyModale: {},
        }
    },
    methods: {
        deleteItem(id) {
            const valid = confirm('Êtes vous sur de vouloir supprimer ce contrat ?')
            if (valid) {
                this.$store.dispatch('delete_insurance', id)
            }
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleModifyModale(id) {
            if (this.showModifyModale[id] == undefined) {
                this.showModifyModale[id] = true
            } else {
                this.showModifyModale[id] = !this.showModifyModale[id]
            }
        }
    }
}
</script>

<style src="../style.css" scoped/>