<template>
    <form>
        <div class="radio-group">
            <div v-for="(value, key) in optionsObject" :key="key" class="radio-choice">
                <input
                    type="radio" 
                    :id="'option-' + key" 
                    name="selector"
                    :value="key"
                    v-model="picked"
                    :checked="picked == key"
                >

                <label :for="'option-' + key">
                    {{ value }}
                </label>
            </div>
        </div>
   </form>
</template>

<script>
export default {
    props: ['optionsObject', 'initialValue'],
    data() {
        return {
            picked: undefined,
        }
    },
    watch: {
        picked() {
            this.$emit('valueUpdate', this.picked)
        }
    },
    mounted() {
      this.picked = this.initialValue
    }

}
</script>

<style scoped>
form {
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  width: auto;
  margin: 0;
}

input[type="radio"] {
  position: absolute;
  opacity: 0; /* mise à jour pour le même comportement que visibility: hidden; et display: none; */
  width: 0; /* mise à jour pour le même comportement que display: none; */
  height: 0; /* mise à jour pour le même comportement que display: none; */
}

label {
  color: rgb(242, 139, 35);
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
}

input[type="radio"]:checked + label {
  color: white;
  background: #140177;
}

.radio-choice + .radio-choice input[type="radio"] + label {
  border-left: solid 3px #140177;
}

.radio-group {
  border: solid 3px #140177;
  display: inline-block;
  margin: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.radio-choice {
    display: inline-block
}

/* Importation de la police Roboto depuis Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Roboto');
</style>