<template>
    <div>
        <span class="loader"></span>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  background: #fff;
  filter: blur(4px) contrast(10) hue-rotate(240deg);
  mix-blend-mode: darken;
}
.loader:before{
  content: "";
  grid-area: 1/1;
  margin: 30px 0;
  border-radius: 100px;
  background: #00ffff;
}
.loader:after {
  content: "";
  grid-area: 1/1;
  height: 35px;
  width: 35px;
  margin: auto;
  border-radius: 50%;
  background: #00ffff;
  animation: l5 2s infinite linear;
}
@keyframes l5{
  0%   {transform: translate(30px)}
  25%  {transform: translate(0)}
  50%  {transform: translate(-30px)}
  75%  {transform: translate(0)}
  100% {transform: translate(30px)}
}
</style>