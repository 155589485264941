<template>
     <section v-if="reserveesList.length > 0">
        <p>Afin de reconstituer la masse de calcul des réserves, il faut ajouter aux biens existants la totalité des donations consenties pendant la vie du défunt. Cette masse servira de base de calcul pour assurer le respect des réserves des héritiers réservataires.</p>

        <table>
            <tr>
                <th>Type de donation</th>
                <th>Donateur</th>
                <th>Donataire</th>
                <th>Donation-partage</th>
                <th>Valeur au jour du décès</th>
                <th>Valeur au jour de la donation</th>
                <th>Valeur à réunir fictivement</th>
                <th>Date</th>
            </tr>

            <tr v-for="gift in giftsList" :key="gift._id">
                <td>{{ $store.getters.giftTypes[gift.type] }}</td>
                <td>{{ $store.getters.findPersonName(gift.donor) }}</td>
                <td>{{ $store.getters.findPersonName(gift.donee) }}</td>
                <td>{{ BOOLEAN_TRAD[gift.is_shared] }}</td>
                <td>{{ toEuro(gift.current_value) }}</td>
                <td>{{ toEuro(gift.value) }}</td>
                <td>{{ toEuro(gift.gathered_value) }}</td>
                <td>{{ dateToString(gift.date) }}</td>
            </tr>

            <tr>
                <th>Total à réunir</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{{ toEuro(gatheredGifts) }}</th>
                <th></th>
            </tr>
        </table>

        <p>En réunissant les biens existants avec la valeur totale des donations à réunir fictivement, on obtient une masse de calcul de {{ toEuro(existingGoodsValue) }} + {{ toEuro(gatheredGifts) }} = <b>{{ toEuro(calculationMass) }}</b></p>
        
        <p v-if="reserveesList.length == 1 && nbChildren == 0">En l'absence d'enfant, le conjoint est réservataire pour 1/4 de la masse de calcul, dans notre cas <b>{{ toEuro(reserveesList[0].reserve) }}</b>.</p>
        <p v-else-if="reserveesList.length > 1">En présence de {{ reserveesList.length }} enfants réservataires, la réserve héréditaire gobale est de <b>{{ toEuro(reserveesList.map(r => r.reserve).reduce((sum, current) => sum + current, 0)) }}</b> et la réserve individuelle de chaque enfant est de <b>{{ toEuro(reserveesList[0].reserve) }}</b>.</p>
        <p v-else-if="reserveesList.length == 1">En présence d'un seul enfant réservataire, sa réserve héréditaire est de <b>{{ toEuro(reserveesList[0].reserve) }}</b>.</p>
        <p v-else-if="reserveesList.length == 0">Comme il n'y a pas d'enfant réservataire, il n'y a pas de réserve à respecter et il est possible de disposer du patrimoine librement.</p>

        <p>La quotité disponible ordinaire (QDO) dont il est possible de disposer librement est donc de <b>{{ toEuro(availableQuota) }}</b>.</p>

        <table>
            <tr>
                <th>Héritier réservataire</th>
                <th>Montant reçu par donation</th>
                <th>Montant reçu par legs</th>
                <th>Montant reçu par dévolution légale</th>
                <th>Total reçu</th>
                <th>Reserve</th>
                <th>Réserve non perçue</th>
            </tr>

            <tr v-for="reservee in reserveesList" :key="reservee._id">
                <td>{{ reservee.firstname }} {{ reservee.lastname}}</td>
                <td>{{ toEuro(reservee.total_gift) }}</td>
                <td>{{ toEuro(reservee.legs.NP) }}</td>
                <td>{{ toEuro(reservee.devolution_part.NP) }}</td>
                <td>{{ toEuro(reservee.total_gift + reservee.legs.NP + reservee.devolution_part.NP) }}</td>
                <td>{{ toEuro(reservee.reserve) }}</td>
                <td>{{ toEuro(Math.max(reservee.reserve - reservee.total_gift - reservee.legs.NP - reservee.devolution_part.NP, 0)) }}</td>
            </tr>
        </table>
    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'datasFromOrder', 'deceasedId', 'isSecondDeath'],
    data() {
        return {
            BOOLEAN_TRAD: {
                true: 'OUI',
                false: 'NON'
            },
        }
    },
    computed: {
        existingGoodsValue() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.biens_existants.US
            } else {
                return this.legacyResult.first_death.datas.biens_existants.US
            }
        },
        giftsList() {
            let giftResult = this.datasFromOrder.gifts.filter(g => g.donor == this.deceasedId)

            giftResult.forEach(g => {
                if (g.is_shared) {
                    g.gathered_value = g.value
                } else {
                    g.gathered_value = g.current_value
                }
            })

            return giftResult
        },
        gatheredGifts() {
            return this.giftsList.map(g => g.gathered_value).reduce((sum, current) => sum + current, 0)
        },
        calculationMass() {
            return this.existingGoodsValue + this.gatheredGifts
        },
        reserveesList() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.inheritors.filter(i => i.reserve > 0)
            } else {
                return this.legacyResult.first_death.inheritors.filter(i => i.reserve > 0)
            }
        },
        nbChildren() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.nb_children
            } else {
                return this.legacyResult.first_death.datas.nb_children
            }
        },
        availableQuota() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.legacy_masses.qdo_value
            } else {
                return this.legacyResult.first_death.legacy_masses.qdo_value
            }
        }
    },
}
</script>

<style scoped src="./style.css"></style>