<template>
    <div class="page-container">
        <h1>Vos préconisations</h1>
        <p>Vous trouverez ci-dessous la liste des simulations réalisées pour {{ $store.getters.userName }}.</p>
        
        <div class="add-scenario" @click="createScenario">Ajouter</div>

        <div class="simulations">
            <div class="simulation-component" v-for="scenario in scenarios" :key="scenario._id">
                <div class="simulation-label">{{ scenario.label }}</div>
                <div class="simulation-date">{{ dateToString(scenario.creationDate) }}</div>
                <div class="simulation-modification">
                    <edit-icon @click="goToAdivorTool(scenario._id)"/>
                    <delete-icon @click="deleteScenario(scenario._id)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            recommendation: undefined,
            scenarios: []
        }
    },
    methods: {
        async getClientRecommendation() {
            const clientRecoRes = await axios.get(this.$store.getters.get_api_url + 'clients/find-client-recommendation/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.recommendation = clientRecoRes.data
        },
        async createClientRecommendation() {
            await axios.post(this.$store.getters.get_api_url + 'clients/recommendation/', {
                clientId: this.$route.params.clientId,
                isClientRecommandation: true,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.$router.replace('/client/' + this.$route.params.clientId + '/view/')
        },
        async createScenario() {
            const label = prompt("Choisissez un nom de scenario", "Scenario");
            if (label && label != '') {
                const res = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                const originalScenarioId = res.data._id

                await axios.post(this.$store.getters.get_api_url + 'clients/duplicate-scenario/', {
                    clientId: this.$route.params.clientId,
                    recommendationId: this.recommendation._id,
                    originalScenarioId: originalScenarioId,
                    scenarioLabel: label,
                    
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.loadScenarios()

            } else {
                alert('Veuillez choisir un nom de scenario')
            }
        },
        async loadScenarios() {
            const scenario_response = await axios.get(this.$store.getters.get_api_url + 'clients/scenario/' + this.recommendation._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.scenarios = scenario_response.data
        },
        async deleteScenario(scenarioId) {
            const confirmation = confirm('Supprimer le scenario ?')
            if (confirmation) {
                await axios.delete(this.$store.getters.get_api_url + 'clients/scenario/' + scenarioId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                await this.loadScenarios()
            }
        },
        goToAdivorTool(scenarioId) {
            this.$router.replace('/client/' + this.$route.params.clientId + '/advisor-tool/' + scenarioId)
        }
    },
    async mounted() {
        await this.getClientRecommendation()

        if (!this.recommendation) {
            await this.createClientRecommendation()
            await this.getClientRecommendation()
        }

        await this.loadScenarios()
    }
}
</script>

<style scoped>

.page-container {
    position: relative;
}

.add-scenario {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 15px 30px;
    background-color: orange;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

.add-scenario:hover {
    background-color: rgba(255, 166, 0, 0.679);
}

.simulations {
    display: flex;
    flex-direction: column;
}

.simulation-component {
    margin: 5px;
    display: flex;
    align-items: center;
    background-color: #f8f4eb7d;
    font-size: 20px;
}

.simulation-label {
    border: 3px #f3ebd9 solid;
    border-right: none;
    padding: 30px 15px;
    border-radius: 20px 0 0 20px;
    flex-basis: 80%;
}

.simulation-date {
    border: 3px #f3ebd9 solid;
    padding: 30px 15px;
    flex-basis: 10%;
}

.simulation-modification {
    border: 3px #f3ebd9 solid;
    border-left: none;
    padding: 30px 15px;
    border-radius: 0 20px 20px 0;
    flex-basis: 10%;
    text-align: center;
}

</style>