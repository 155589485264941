<template>
    <div class="page-container">
        <h3>Liste des rappels</h3>
        <div class="add-btn" @click="toggleForm">Ajouter</div>

        <div class="research-frame">
            <input type="text" v-model="researchString">
        </div>

        <div class="section" v-for="section in sections" :key="section._id">
            <h4>{{ section.title }}</h4>

            <div class="items-list">
                <ListItem
                    v-for="reminder in reminders.filter(r => r.sectionId == section._id)" :key="reminder._id"
                    :item="reminder"
                    type="reminder"
                    @toggleForm="loadReminders"
                />
            </div>
        </div>

        <modale :show="showForm" @toggle="toggleForm">
            <ReminderForm @closeModale="toggleForm"/>
        </modale>

    </div>
</template>

<script>
import axios from 'axios'

import ListItem from '../components/ListItem.vue'
import ReminderForm from '../components/ReminderForm.vue'

export default {
    components: {
        ListItem,
        ReminderForm,
    },
    data() {
        return {
            researchString: "",
            showForm: false,
            reminders: [],
            sections: [],
        }
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm
            this.loadReminders()
        },
        async loadSections() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/sections/reminder', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.sections = result.data
        },
        async loadReminders() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/reminders/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.reminders = result.data
        },
        async searchReminders() {
            if (this.researchString == "") {
                this.loadReminders()
            } else {
                const result = await axios.post(this.$store.getters.get_api_url +'referential/reminders-research/', {
                    research: this.researchString
                } , {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.reminders = result.data
            }
        }
    },
    watch: {
        researchString() {
            this.searchReminders()
        }
    },
    mounted() {
        this.loadSections()
        this.loadReminders()
    }
}
</script>

<style scoped src="../Style.css"></style>