<template>
    <div>
        <div class="form-section">
            <label>Nombre d'années avant la retraite</label>
            <input type="number" v-model="duration">
        </div>

        <div class="form-section">
            <label>Taux marginal d'imposition</label>
            <input type="number" v-model="tmi">
        </div>

        <div class="separator"></div>

        <div class="projection-frame">
            <div class="projections">
                <h2>Projections financières</h2>

                <table>
                    <tr>
                        <th>Type de compte</th>
                        <th>Valeur actuelle</th>
                        <th>Versements mensuels</th>
                        <th>Total versé</th>
                        <th>Réduction d'impôts</th>
                        <th>Valeur finale (neutre)</th>
                        <th>Simulation</th>
                    </tr>
                    <tr v-for="projection in investmentsResult.insurances" :key="projection._id">
                        <td>{{ $store.getters.insuranceTypes[getInsurance(projection._id)?.type] }}</td>
                        <td>{{ toEuro(getInsurance(projection._id)?.value) }}</td>
                        <td>{{ toEuro(getInsurance(projection._id)?.payment_monthly) }}</td>
                        <td>{{ toEuro(projection.datas?.payment_projection[duration]) }}</td>
                        <td> 
                            <span v-if="['perin', 'perp', 'madelin'].includes(getInsurance(projection._id)?.type)">
                                {{ toEuro(projection.datas?.payment_projection[duration] * (tmi / 100)) }}
                            </span> 
                        </td>
                        <td>{{ toEuro(projection.neutral?.future_value.value) }}</td>
                        <td> <input type="checkbox" @click="updateInsuranceToDisplay(projection._id)" checked> </td>
                    </tr>
                </table>
            </div>
            
            <div class="chart-wrapper">
                <line-chart
                    v-if="showChart"
                    :chartId="'financial-projections'"
                    :labels="labels"
                    :datasets="financialDataSet"
                    :startZero="true"
                />
            </div>
        </div>

        <div class="separator"></div>

        <div class="projection-frame">
            <div class="projections">
                <h2>Projections immobilières</h2>

                <table>
                    <tr>
                        <th>Type de bien</th>
                        <th>Valeur actuelle</th>
                        <th>Versements mensuels</th>
                        <th>Total versé</th>
                        <th>Valeur finale (neutre)</th>
                        <th>Simulation</th>
                    </tr>
                    <tr v-for="projection in investmentsResult.properties" :key="projection._id">
                        <td>{{ $store.getters.propertyTypes[getProperty(projection._id)?.category][getProperty(projection._id)?.type] }}</td>
                        <td>{{ toEuro(getProperty(projection._id)?.value) }}</td>
                        <td>{{ toEuro(getProperty(projection._id)?.payment_monthly) }}</td>
                        <td>{{ toEuro(projection.datas?.payment_projection[duration]) }}</td>
                        <td>{{ toEuro(projection.neutral?.future_value.value) }}</td>
                        <td> <input type="checkbox" @click="updatePropertyToDisplay(projection._id)" checked> </td>
                    </tr>
                </table>
            </div>
            
            <div class="chart-wrapper">
                <line-chart
                    v-if="showChart"
                    :chartId="'real-estate-projections'"
                    :labels="labels"
                    :datasets="realEstateDataSet"
                    :startZero="true"
                />
            </div>
        </div>

        <div class="separator"></div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showChart: false,
            investmentsResult: [],
            labels: [],
            duration: 15,
            tmi: 11,
            insuranceToDisplay: {},
            propertyToDisplay: {},
        }
    },
    methods: {
        updateInsuranceToDisplay(id) {
            if (this.insuranceToDisplay[id]) {
                this.insuranceToDisplay[id] = false

            } else {
                this.insuranceToDisplay[id] = true
            }
            this.loadInvestmentsResults()
        },
        updatePropertyToDisplay(id) {
            if (this.propertyToDisplay[id]) {
                this.propertyToDisplay[id] = false

            } else {
                this.propertyToDisplay[id] = true
            }
            this.loadInvestmentsResults()
        },
        async loadInvestmentsResults() {
            this.showChart = false
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/investments', this.$store.getters.investmentDatas(this.duration), {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.investmentsResult = res.data
            this.labels = this.investmentsResult.labels
            
            this.showChart = true
        },
        getInsurance(_id) {
            return this.$store.getters.getAllInsurances.find(i => i._id == _id)
        },
        getProperty(_id) {
            return this.$store.getters.getAllProperties.find(i => i._id == _id)
        }
    },
    watch: {
        duration(){
            this.loadInvestmentsResults()
        }
    },
    computed: {
        financialDataSet() {
            const insurances = this.investmentsResult.insurances.filter(insurance => this.insuranceToDisplay[insurance._id])

            let sumPerf = {
                pessimistic : insurances[0]?.pessimistic?.future_value.global_projection,
                neutral : insurances[0]?.neutral?.future_value.global_projection,
                optimistic: insurances[0]?.optimistic?.future_value.global_projection,
                payment: insurances[0]?.datas.payment_projection,
            }

            for (let i = 0; i <= this.duration; i++) {
                for (let c = 1; c < insurances.length; c++) {
                    sumPerf.pessimistic[i] += insurances[c].pessimistic?.future_value.global_projection[i]
                    sumPerf.neutral[i] += insurances[c].neutral?.future_value.global_projection[i]
                    sumPerf.optimistic[i] += insurances[c].optimistic?.future_value.global_projection[i]
                    sumPerf.payment[i] += insurances[c].datas.payment_projection[i]
                }
            }
            let dataSets = [
                {
                    label: 'Pessimiste',
                    data: sumPerf.pessimistic,
                    borderColor: 'rgb(240, 139, 139)',
                    pointBackgroundColor: "red",
                    pointRadius: "2",
                },
                {
                    label: 'Neutre',
                    data: sumPerf.neutral,
                    borderColor: 'rgb(227, 178, 88)',
                    pointBackgroundColor: "orange",
                    pointRadius: "2",
                },
                {
                    label: 'Optimiste',
                    data: sumPerf.optimistic,
                    borderColor: 'rgb(151, 245, 151)',
                    pointBackgroundColor: "green",
                    pointRadius: "2",
                },
                {
                    label: 'Versements',
                    data: sumPerf.payment,
                    borderColor: 'rgb(160, 187, 196)',
                    backgroundColor: 'rgba(146, 217, 241, 0.803)',
                    pointBackgroundColor: "gray",
                    pointRadius: "2",
                },
            ]

            return dataSets
        },
        realEstateDataSet() {
            let properties = this.investmentsResult.properties.filter(property => this.propertyToDisplay[property._id])
            properties = JSON.parse(JSON.stringify(properties))

            let sumPerf = {
                pessimistic : properties[0]?.pessimistic?.future_value.projection,
                neutral : properties[0]?.neutral?.future_value.projection,
                optimistic: properties[0]?.optimistic?.future_value.projection,
                payment: properties[0]?.datas.payment_projection,
            }

            for (let i = 0; i <= this.duration; i++) {
                for (let c = 1; c < properties.length; c++) {
                    sumPerf.pessimistic[i] += properties[c].pessimistic?.future_value.projection[i]
                    sumPerf.neutral[i] += properties[c].neutral?.future_value.projection[i]
                    sumPerf.optimistic[i] += properties[c].optimistic?.future_value.projection[i]
                    sumPerf.payment[i] += properties[c].datas.payment_projection[i]
                }
            }
            let dataSets = [
                {
                    label: 'Pessimiste',
                    data: sumPerf.pessimistic,
                    borderColor: 'rgb(240, 139, 139)',
                    pointBackgroundColor: "red",
                    pointRadius: "2",
                },
                {
                    label: 'Neutre',
                    data: sumPerf.neutral,
                    borderColor: 'rgb(227, 178, 88)',
                    pointBackgroundColor: "orange",
                    pointRadius: "2",
                },
                {
                    label: 'Optimiste',
                    data: sumPerf.optimistic,
                    borderColor: 'rgb(151, 245, 151)',
                    pointBackgroundColor: "green",
                    pointRadius: "2",
                },
                {
                    label: 'Versements',
                    data: sumPerf.payment,
                    borderColor: 'rgb(160, 187, 196)',
                    backgroundColor: 'rgba(146, 217, 241, 0.803)',
                    pointBackgroundColor: "gray",
                    pointRadius: "2",
                },
            ]

            return dataSets
        },
    },
    mounted() {
        this.$store.getters.getAllInsurances.forEach(insurance => {
            this.insuranceToDisplay[insurance._id] = true
        });

        this.$store.getters.getAllProperties.forEach(property => {
            this.propertyToDisplay[property._id] = true
        });

        this.loadInvestmentsResults()
    }
}
</script>

<style scoped>
.form-section {
    display: inline-block;
}

.separator {
    margin: 15px 30%;
    height: 3px;
    background-color: rgb(255, 166, 0);
    width: 40%;
}

.projection-frame {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: flex-start;
}

.projections {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.projections h2 {
    color: rgb(1, 1, 118)
}
.projections table {
    width: 90%;
    border-collapse: collapse;
    text-align: center;
}
.projections table th {
    border: 1px solid lightgrey;
    background-color: rgb(2, 2, 99);
    color: white;
}
.projections table td {
    border: 1px solid lightgrey;
}

.chart-wrapper {
    flex-basis: 50%;
}

</style>