<template>
    <div class="rh-table">
        <div class="category-title">{{ title }}</div>
        <p class="description">{{ description }}</p>

        <table>
            <tr>
                <th>Libellé</th>
                <th>Collaborateur</th>
                <th>Temps passé</th>
                <th>Date</th>
                <th>Actions</th>
            </tr>

            <tr v-for="demand in demand_list" :key="demand._id">
                <td>{{ demand.label }}</td>
                <td>{{ demand.employee?.firstname }} {{ demand.employee?.lastname }}</td>
                <td>{{ demand?.nb_half_day / 2 }} jours</td>
                <td>{{ dateToString(demand?.date) }}</td>
                <td class="actions-td">
                    <div class="confirm-btn action-btn" v-if="!demand.is_confirmed && !is_waiting" @click="confirmData(demand._id)">Confirmer</div>
                    <div class="cancel-btn action-btn" v-if="!demand.is_confirmed && !is_waiting" @click="cancelData(demand._id)">Refuser</div>
                    <div class="await-btn action-btn" v-else-if="is_waiting">En attente</div>
                    <div class="action-btn" :class="{ 'cancel-btn': demand.status == 'cancelled', 'confirm-btn': demand.status == 'confirmed' }" v-else>{{ trad_status[demand.status] }} par {{ demand.manager?.firstname }} {{ demand.manager?.lastname }}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        title : {
            type: String,
            required: true
        },
        demand_list : {
            type: Array,
            required: true
        },
        description : {
            type: String,
            required: false
        },
        is_waiting : {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            trad_status: {
                cancelled: 'Refusé',
                confirmed: 'Validé'
            },
        }
    },
    methods: {
        async confirmData(demandId) {
            await axios.put(this.$store.getters.get_api_url + 'rh-management/confirm-demand/' + demandId, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.$emit('confirmation')
        },
        async cancelData(demandId) {
            await axios.put(this.$store.getters.get_api_url + 'rh-management/cancel-demand/' + demandId, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.$emit('confirmation')
        },
        auth_confirmation(employee_position) {
            const position = this.$store.getters.getAdminPosition
            if (position == 'director' || position == 'president') {
                return true
            } else if (position == 'engineer2' && employee_position == 'engineer') {
                return true
            } else if (position == 'engineer3' && ['engineer2', 'engineer'].includes(employee_position)) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style scoped>

.rh-table {
    flex-basis: 48%;
    padding: 20px 0;
    position: relative;
}
.category-title {
    font-size: 28px;
    font-weight: bold;
    color: rgb(0, 0, 83);
}
.separator {
    height: 1px;
    margin: 10px 0;
    background-color: lightgrey;
}

table {
    width: 100%;
    margin: 10px auto;
    border-collapse: collapse;
}
td, th {
    border: 1px solid lightgrey;
}
th {
    padding: 15px;
    background-color: rgb(0, 0, 73);
    color: white;
}

td {
    padding: 10px;
    background-color: white;
}

.actions-td {
    text-align: center;
}

.action-btn {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin: 0 3px;
}

.confirm-btn {
    background-color: lightgreen;
}
.cancel-btn {
    background-color: lightcoral;
}
.await-btn {
    background-color: lightgrey;
}


</style>