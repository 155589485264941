<template>
    <form class="add-form">
        <div class="form-section">
            <label for="owner">Testateur</label>
            <select id="owner" v-model="body.owner">
                <option v-for="(value, index) in $store.getters.getMainPersons" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="type">Type de testament</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.willTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <!-- Testament universel -->
        <div class="will-type-detail" v-if="body.type == 'universal'">

            <label for="right">Droit légué</label>
            <select v-model="body.univeral_detail.right">
                <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value}}</option>
            </select>

            <div class="legee-section">
                <h2>Légataires universels <span class="add-rank" @click="body.univeral_detail.ranks.push([])">Ajouter un rang</span></h2>
                
                <!-- Rangs -->
                <div class="rank" v-for="(rank, index) in body.univeral_detail.ranks" :key="index">
                    <!-- Bénéfifiaires -->
                    <div class="rank-label">
                        <p>Rang {{ index + 1 }} </p>
                        <div class="delete-rank-btn" @click="deleteUniversalRank(index)">-</div>
                    </div>
                    
                    <multi-select
                        :elements="beneficiariesList"
                        :values="rank"
                        @closed="(result) => updateUniversalRank(result, index)"
                    />
                </div>
            </div>
        </div>
        
        <!-- Testament partage -->
        <div class="will-type-detail" v-else-if="body.type == 'legs'">
            <h2>Liste des legs <span class="add-legs" @click="addLegs">Ajouter un legs</span></h2>
            
            <div class="shared-asset-detail" v-for="(legs, legsIndex) in body.legs" :key="legsIndex">

                <div class="delete-shared-btn grow-effect" @click="deletelegs(legsIndex)">X</div>

                <!-- Rangs -->
                <div class="rank" v-for="(rank, rankIndex) in legs.ranks" :key="rankIndex">
                    <!-- Bénéfifiaires -->
                    <div class="rank-label">
                        <p>Rang {{ rankIndex + 1 }} </p>
                        <div class="delete-rank-btn" @click="deleteLegsRank(rankIndex, legsIndex)">-</div>
                    </div>
                    
                    <multi-select
                        :elements="beneficiariesList"
                        :values="rank"
                        @closed="(result)=>{legs.ranks[rankIndex] = result}"
                    />
                </div>
                <div class="add-rank" @click="legs.ranks.push([])">Ajouter un rang</div>

                <!-- Bien partagé -->
                <label>Actif légué</label>
                <select v-model="legs.assetId">
                    <option v-for="asset in assetList" :key="asset._id" :value="asset._id"> {{ asset.type }} - {{ toEuro(asset.value) }}</option>
                </select>

                <!-- Droit légué -->
                <label>Droit légué</label>
                <select id="rights" v-model="legs.right">
                    <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>
           
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                owner: undefined,
                type: undefined,
                univeral_detail: {
                    right: undefined,
                    ranks: [ [] ],
                },
                legs: [
                    {
                        right: "pp",
                        value: 0,
                        ranks: [ [] ],
                        aps: false,
                        assetId: undefined,
                    }
                ],
                isIpCreation: false,
            },
        }
    },
    methods: {
        submit() {
            if (this.isNew) {
                const routeArray = this.$route.fullPath.split('/')
                if (routeArray[3] != 'profile') {
                    this.body.isIpCreation = true
                }
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de testament"

            } else if (this.body.owner == undefined) {
                this.errorMsg = "Précisez le testateur"

            } else if (this.$store.getters.hasWill(this.body.owner) && this.isNew) {
                this.errorMsg = "Cet utilisateur a déjà un testament"

            } else if (this.body.type == 'universal' && this.body.univeral_detail.ranks.length == 0) {
                this.errorMsg = "Renseignez au moins un légataire universel"

            } else if (this.body.type == 'universal' && !this.body.univeral_detail.right) {
                this.errorMsg = "Renseignez le droit légué"

            } else if (this.body.type == 'sharing' && this.body.sharing_detail.length == 0) {
                this.errorMsg = "Renseignez au moins un bien à partager"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_will', this.body)

                } else {
                    this.$store.dispatch('modify_will', this.body)
                }
                this.$emit('closeModale')
            }
        },
        addLegs() {
            this.body.legs.push({
                asset: {
                    assetId: undefined,
                    right: 'pp'
                },
                value: 0,
                ranks: [
                    []
                ],
                aps: false
            })
        },
        deletelegs(index) {
            this.body.legs.splice(index, 1)
        },
        deleteUniversalRank(index) {
            this.body.univeral_detail.ranks.splice(index, 1)
        },
        deleteLegsRank(rankIndex, legsIndex) {
            this.body.legs[legsIndex].ranks.splice(rankIndex, 1)
        },
        updateUniversalRank(result, index) {
            this.body.univeral_detail.ranks[index] = result
        }
    },
    computed: {
        beneficiariesList() {
            if (this.body.owner == this.$store.getters.spouseId) {
                return this.$store.getters.findSpouseBeneficiaries
                
            } else {
                return this.$store.getters.findUserBeneficiaries
            }
        },
        assetList() {
            if (this.body.owner == this.$store.getters.spouseId) {
                return this.$store.getters.getSpouseAssets
                
            } else {
                return this.$store.getters.getUserAssets
            }
        }
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            JSON.parse(JSON.stringify(this.import))

            this.body = this.import
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style scoped>
.will-type-detail {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    padding: 10px;
}
.legee-section {
    margin-top: 10px;
    padding: 10px;
    background-color: rgb(245, 251, 252);
}
.legee-section h2 {
    font-size: 22px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-legs {
    cursor: pointer;
    font-size: 12px;
    font-weight: lighter;
    margin: 3px;
    padding: 3px;
    background-color: orange;
}
.add-rank {
    cursor: pointer;
    font-size: 12px;
    font-weight: lighter;
    margin: 3px;
    padding: 3px;
    background-color: rgb(0, 0, 80);
    color: white;
    display: inline-block;
    text-align: center;
}
.rank-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px white solid;
    border-bottom: 2px white solid;
}
.delete-rank-btn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.shared-asset-detail {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    position: relative;
    background-color: rgb(245, 251, 252);
}
.shared-asset-detail h2 {
    font-size: 20px;
}
.add-shared-property {
    cursor: pointer;
    text-align: center;
    background-color: orange;
    font-size: 20px;
}

.delete-shared-btn {
    width: 25px;
    height: 25px;
    background-color: red;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
}
</style>
