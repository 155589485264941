<template>
    <section>
        <p>Les biens existants sont ceux existant dans le patrimoine du défunt au moment de son décès et qui seront partagés au moment de son décès. Au jour de la succession, ce sont ces biens qui seront transmis aux héritiers selon les droits légaux ou les legs.</p>

        <table>
            <tr>
                <th>Biens existants</th>
                <th>Valeur</th>
            </tr>

            <tr v-for="asset in existingGoodsList" :key="asset._id">
                <td>{{ formatType(asset.type) }}</td>
                <td>{{ toEuro(asset.value) }}</td>
            </tr>

            <tr v-if="totalDebts > 0">
                <td>Passif</td>
                <td>- {{ toEuro(totalDebts) }}</td>
            </tr>

            <tr v-if="isSecondDeath">
                <td>Héritage en pleine propriété (1er décès)</td>
                <td>{{ toEuro(this.legacyResult.inheritance_spouse.inheritance) }}</td>
            </tr>

            <tr v-if="isSecondDeath">
                <td>Assurance vie en pleine propriété (1er décès)</td>
                <td>{{ toEuro(this.legacyResult.inheritance_spouse.life_insurance) }}</td>
            </tr>

            <tr>
                <td>Frais de notaire</td>
                <td>- {{ toEuro(notarialFees) }}</td>
            </tr>

            <tr>
                <th>Total</th>
                <th>{{ toEuro(existingGoodsValue) }}</th>
            </tr>
        </table>
    </section> 
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
            ORIGIN_TRAD: {
                own: 'Bien propre',
                common: 'Communauté'
            },
            BOOLEAN_TRAD: {
                true: 'OUI',
                false: 'NON'
            },
        }
    },
    computed: {
        existingGoodsList() {
            if (this.isSecondDeath) {
                return this.legacyResult.liquidation?.spouse_existing_goods_list
            } else {
                return this.legacyResult.liquidation?.user_existing_goods_list
            }
        },
        existingGoodsValue() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.biens_existants.US
            } else {
                return this.legacyResult.first_death.datas.biens_existants.US
            }
        },
        totalDebts() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.totalDebts
            } else {
                return this.legacyResult.first_death.datas.totalDebts
            }
        },
        notarialFees() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.notarial_fees
            } else {
                return this.legacyResult.first_death.datas.notarial_fees
            }
        },
    },
    methods: {
        formatType(type) {
            const realEstateType = this.$store.getters.propertyTypesList[type]
            const assetType = this.$store.getters.assetTypesList[type]
            const societyType = this.$store.getters.societyTypes[type]
            const movableType = this.$store.getters.movableTypes[type]
            const debtType = this.$store.getters.debtTypes[type]

            if (realEstateType) {
                return realEstateType
            } else if (assetType) {
                return assetType
            } else if (societyType) {
                return societyType
            } else if (movableType) {
                return movableType
            } else if (debtType) {
                return debtType
            } else {
                return 'Inconnu'
            }
        },
    }
}
</script>

<style scoped src="./style.css"></style>