<template>
    <div class="file-container">
        <div class="summary">
            <h2>Sommaire</h2>
        </div>

        <div class="content">
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mainSectionId: undefined
        }
    },
    mounted() {
        this.mainSectionId = this.$route.params.fileId
    }
}
</script>

<style scoped>
.file-container {
    display: flex;
    height: 100%;
}

.summary {
    flex-basis: 40%;
    padding: 10px;
    background-color: rgb(0, 0, 89);
    height: 88vh;
    color: white;
}

h2 {
    font-family: 'Montserrat Alternates Semibold';
    font-size: 36px;
    text-align: center;
}

.content {
    flex-basis: 60%;
    height: 88vh;
    overflow: scroll;
}
</style>