<template>
    <span>
        <svg :width="dimensionDefault" :height="dimensionDefault" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.1712 3.35781L15.6418 0.964109C15.3331 0.671645 14.9198 0.507416 14.4828 0.507416C14.0458 0.507416 13.6326 0.669396 13.3238 0.964109L10.5997 3.54004C10.2909 3.8325 10.1175 4.2262 10.1175 4.64015C10.1175 5.05635 10.2885 5.44555 10.5997 5.74026L12.7277 7.75826C12.2429 8.82819 11.5464 9.80032 10.6757 10.6222C9.80404 11.4523 8.78516 12.107 7.65466 12.5704L5.52665 10.5524C5.2179 10.2599 4.80465 10.0957 4.36764 10.0957C4.1521 10.095 3.93856 10.135 3.73954 10.2134C3.54052 10.2918 3.36003 10.4071 3.20864 10.5524L0.482127 13.1283C0.173376 13.4208 0 13.8145 0 14.2285C0 14.6446 0.171001 15.0338 0.482127 15.3286L3.00914 17.7223C3.53639 18.2217 4.26314 18.5074 5.0089 18.5074C5.16327 18.5074 5.3129 18.4962 5.4649 18.4714C8.60941 17.981 11.7302 16.3949 14.2501 14.0102C16.7676 11.621 18.4396 8.6649 18.9621 5.68402C19.1117 4.83812 18.8125 3.96748 18.1712 3.35781ZM17.2782 5.4163C16.8151 8.06872 15.3093 10.7144 13.0412 12.8629C10.773 15.0114 7.98241 16.4377 5.18227 16.8764C4.83077 16.9326 4.46977 16.8201 4.21327 16.5794L1.73138 14.2285L4.36289 11.7335L7.20816 14.4332L7.22953 14.4534L7.74253 14.2734C9.29799 13.7317 10.7105 12.8761 11.8822 11.7659C13.0538 10.6556 13.9566 9.31734 14.5279 7.84375L14.7179 7.35781L11.8489 4.6424L14.4804 2.14746L16.9623 4.49842C17.2188 4.74139 17.3376 5.08334 17.2782 5.4163Z" fill="currentColor"></path></svg>    
    </span>
</template>

<script>
export default {
    props: ['dimensions'],
    computed: {
        dimensionDefault() {
            if (this.dimensions) {
                return this.dimension
            } else {
                return 25
            }
        }
    }
}
</script>