<template>
    <div class="list-section">
        <h1 class="section-title">
            Charges déductibles
            
            <add-btn type="deductible_expense"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="deductible_expense in $store.getters.getAllDeductibleExpense"
                :key="deductible_expense._id"
                :object="deductible_expense"
                type="deductible_expense"
            />
        </table>
    </div>
</template>

<script>

export default {

    data() {
        return {}
    }
}
</script>
