<template>
    <div class="client-frame">

        <section class="contact-frame">
            <h2 class="inframe-title" @click="toggleContactModale">Informations du cabinet</h2>

            <div class="contact-display">
                <div class="contact-column contact-column-left">
                    <div class="row">
                        <img src="../images/society-icon.png">
                        <p>{{ society.name }}</p>
                    </div>

                    <div class="row">
                        <img src="../images/location-icon.png">
                        <p>{{ society.adress }}, {{ society.zipcode }} {{ society.city }}</p>
                    </div>

                    <select class="client-status" v-model="society.status">
                        <option v-for="(status, key) in status_list" :key="key" :value="key">{{ status }}</option>
                    </select>
                </div>

                <div class="contact-column contact-column-right">
                    <div class="row">
                        <img src="../images/person-icon.png">
                        <p>{{ director.firstname }} {{ director.lastname }}</p>
                    </div>

                    <div class="row">
                        <img src="../images/phone-icon.png">
                        <p>{{ director.phone1 }} / {{ director.phone2 }}</p>
                    </div>

                    <div class="row">
                        <img src="../images/mail-icon.png">
                        <p>{{ director.email1 }} / {{ director.email2 }}</p>
                    </div>
                </div>
            </div>

            <modale :show="showContactModale" @toggle="toggleContactModale" width="60%">
                <form class="society-infos">
                    <div class="form-sect">
                        <label for="name">Nom du cabinet</label>
                        <input id="name" type="text" v-model="society.name">
                    </div>

                    <div class="form-sect">
                        <label for="director-firstname">Prénom du dirigeant</label>
                        <input id="director-firstname" type="text" v-model="director.firstname">
                    </div>

                    <div class="form-sect">
                        <label for="director-lastname">Nom du dirigeant</label>
                        <input id="director-lastname" type="text" v-model="director.lastname">
                    </div>

                    <div class="form-sect">
                        <label for="phone1">Téléphone 1</label>
                        <input id="phone1" type="text" v-model="director.phone1">
                    </div>

                    <div class="form-sect">
                        <label for="phone2">Téléphone 2</label>
                        <input id="phone2" type="text" v-model="director.phone2">
                    </div>

                    <div class="form-sect">
                        <label for="email1">Email 1</label>
                        <input id="email1" type="email" v-model="director.email1">
                    </div>

                    <div class="form-sect">
                        <label for="email2">Email 2</label>
                        <input id="email2" type="email" v-model="director.email2">
                    </div>

                    <div class="form-sect">
                        <label for="adress">Adresse</label>
                        <input id="adress" type="text" v-model="society.adress">
                    </div>

                    <div class="form-sect">
                        <label for="zipcode">Code postal</label>
                        <input id="zipcode" type="text" v-model="society.zipcode">
                    </div>

                    <div class="form-sect">
                        <label for="city">Ville</label>
                        <input id="city" type="text" v-model="society.city">
                    </div>
                </form>
            </modale>
        </section>

        <div class="delete-advisor" @click="deleteSociety">Supprimer</div>
    
        <section>
            <h2 class="inframe-title">Activité commerciale</h2>

            <div class="btn-add-event" @click="toggleModale">Ajouter un évènement</div>
            <modale :show="showModale" width="60%" @toggle="toggleModale">
                <form class="new-event-form" @submit.prevent="saveNewEvent">
                    <select v-model="newEvent.type">
                        <option v-for="(type, key) in $store.getters.eventTypes" :key="key" :value="key">{{ type }}</option>
                    </select>

                    <label>Titre</label>
                    <input type="text" v-model="newEvent.title">

                    <text-editor
                        id="add-text" 
                        v-model="newEvent.text"
                        height="500"
                    />

                    <label>Date</label>
                    <input type="date" v-model="newEvent.date">
                </form>

                <div class="save-btn" @click="saveNewEvent">Enregistrer</div>
            </modale>
        </section>

        <div class="business-frame">
                <div class="business-list">
                    <table>
                        <tr v-for="event in ordered_events" :key="event._id" @click="selectEvent(event)">
                            <td>
                                <img class="event-icon" src="../images/phone-icon.png" v-if="event.type == 'call'">
                                <img class="event-icon" src="../images/mail-icon.png" v-else-if="event.type == 'email'">
                                <img class="event-icon" src="../images/location-icon.png" v-else-if="event.type == 'meeting'">
                                <img class="event-icon" src="../images/visio-icon.png" v-else-if="event.type == 'visio'">
                                <img class="event-icon" src="../images/event-icon.png" v-else-if="event.type == 'event'">
                                <img class="event-icon" src="../images/action-icon.png" v-else-if="event.type == 'action'">
                            </td>

                            <td>{{ event.title }}</td>
                            <td>{{ dateToString(event.date) }}</td>
                            <td @click="deleteEvent(event._id)"><img class="delete-img" src="../images/delete-icon.png"></td>
                        </tr>
                    </table>
                </div>

                <div class="business-detail">
                    <EventDetail 
                        class="business-detail"
                        :event="selectedEvent"
                    />
                </div>
            </div>

        <h2>Informations commerciales</h2>
        <div class="colum-container">

            <div class="column-datas">
                <h4>Fonctionnement actuel</h4>
                <form class="datas-forms">
                    <div class="datas-sect">
                        <label>Big Expert ou equivalent</label>
                        <input type="checkbox" v-model="society.useConcurrent">
                    </div>

                    <div class="datas-sect">
                        <label>Facturation d'honoraires</label>
                        <input type="checkbox" v-model="society.hasFees">
                    </div>

                    <div class="datas-sect">
                        <label>Status</label>
                        <select class="client-status" v-model="society.jobStatus">
                            <option v-for="(status, key) in jobStatusList" :key="key" :value="key">{{ status }}</option>
                        </select>
                    </div>

                    <div class="datas-sect">
                        <label>Association professionnelle</label>
                        <select class="client-status" v-model="society.asso">
                            <option v-for="(asso, key) in asso_list" :key="key" :value="key">{{ asso }}</option>
                        </select>
                    </div>

                    <div class="datas-sect">
                        <label>Origine</label>
                        <select class="client-status" v-model="society.source">
                            <option v-for="(source, key) in sources_list" :key="key" :value="key">{{ source }}</option>
                        </select>
                    </div>
                </form>
            </div>

            <div class="column-datas">
                <h4>Arguments qui intéressent ce client : </h4>

                <form class="datas-forms">
                    <div class="datas-sect">
                        <label>Gagner du temps</label>
                        <input type="checkbox" v-model="society.timeSaving">
                    </div>

                    <div class="datas-sect">
                        <label>Tarification</label>
                        <input type="checkbox" v-model="society.interestedBuyPrice">
                    </div>

                    <div class="datas-sect">
                        <label>Sortir d'un service concurrent</label>
                        <input type="checkbox" v-model="society.leaveCompetitor">
                    </div>

                    <div class="datas-sect">
                        <label>Support technique</label>
                        <input type="checkbox" v-model="society.technicalSupport">
                    </div>

                    <div class="datas-sect">
                        <label>Co-construction</label>
                        <input type="checkbox" v-model="society.coConstruction">
                    </div>
                </form>
            </div>

            <div class="column-datas">
                <h4>Commandes réalisées : </h4>

                <table>
                    <tr v-for="reco in recommendations" :key="reco._id">
                        <td>{{ reco.clientName }}</td>
                        <td>{{ dateToString(reco.date) }}</td>
                    </tr>
                </table>

                <div class="btn-add-reco" @click="toggleAddReco">+</div>

                <modale :show="showAddReco" @toggle="toggleAddReco" width="40%">
                    <form @submit.prevent="">
                        <div class="form-sect">
                            <label>Nom des clients</label>
                            <input type="text" v-model="newReco.clientName">
                        </div>

                        <div class="form-sect">
                            <label>Date de commande</label>
                            <input type="date" v-model="newReco.date">
                        </div>

                        <button @click="saveNewReco">Enregistrer</button>
                    </form>
                </modale>
            </div>
        </div>

        <button @click="saveModifications">Enregistrer</button>
    </div>
</template>

<script>
import axios from 'axios'

import EventDetail from '../elements/EventDetail.vue'

export default {
    components: { EventDetail },
    data() {
        return {
            status_list: {
                0: 'Cabinet non créé',
                1: 'Non contacté',
                2: '1er contact',
                3: 'Commande imminente',
                4: 'Cient',
                5: 'Client récurrent',
                6: 'Non interéssé',
            },
            jobStatusList: {
                broaker: 'Courtier en assurance',
                cif : 'Conseiller en Investissement Financier',
                accountant: 'Expert comptable',
                notary: 'Notaire',
                loan_broaker: 'Courtier en prêt',
                immo_agent: 'Agent immobilier',
            },
            asso_list: {
                '--': undefined,
                cncgp: 'CNCGP',
                anacofi: 'ANACOFI',
                cncef: 'CNCEF',
                ccgp: 'La compagnie des CGP'
            },
            sources_list: {
                vieplus: 'Vie Plus',
                cgpe: "CGP Entrepreneur",
                relation: 'Bouche à oreille',
                social_media: 'Réseaux sociaux',
                calls: "Prospection à froid",
                friend: "Amis et famille",
                internet: "Site internet",
            },
            events: [],
            selectedEvent: {},
            director: {},
            society: {},
            showModale: false,
            showContactModale: false,
            showAddReco: false,
            newEvent: {
                directorId : undefined,
                type       : "call",
                title      : "",
                text       : "",
                date       : new Date(),
            },
            recommendations: [],
            newReco: {
                societyId: undefined,
                clientName: '',
                date: new Date()
            },
        }
    },
    computed: {
        ordered_events() {
            let events = this.events
            return events.sort((a, b) => new Date(b.date) - new Date(a.date))
        }
    },
    methods: {
        selectEvent(event) {
            this.selectedEvent = event
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        toggleContactModale() {
            this.showContactModale = !this.showContactModale
        },
        toggleAddReco() {
            this.showAddReco = !this.showAddReco
        },
        async saveNewEvent() {
            this.newEvent.directorId = this.director._id

            await axios.post(this.$store.getters.get_api_url + 'crm/event/', this.newEvent, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.selectedEvent = this.newEvent
            this.getEvents()
            this.toggleModale()
        },
        async deleteEvent(id) {
            if (confirm('Supprimer cet évènement ? ')) {
                await axios.delete(this.$store.getters.get_api_url + 'crm/event/' + id, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.getEvents()
            }
        },
        async saveNewReco() {
            this.newReco.societyId = this.society._id

            await axios.post(this.$store.getters.get_api_url + 'crm/recommendation/', this.newReco, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.getRecommendations()
            this.toggleAddReco()
        },
        async getEvents() {
            const eventsResponse = await axios.post(this.$store.getters.get_api_url +'crm/all-events/?directorId=' + this.director._id, {}, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.events = eventsResponse.data
        },
        async getRecommendations() {
            const recoResponse = await axios.get(this.$store.getters.get_api_url +'crm/recommendation/' + this.society._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.recommendations = recoResponse.data
        },
        async saveModifications() {
            await axios.put(this.$store.getters.get_api_url + 'crm/society/' + this.society._id, this.society, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            await axios.put(this.$store.getters.get_api_url + 'crm/director/' + this.director._id, this.director, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        },
        async deleteSociety() {
            if (confirm('Êtes vous sur de vouloir supprimer ce cabinet ? ')) {
                await axios.delete(this.$store.getters.get_api_url + 'crm/society/' + this.society._id, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.$router.replace({path: '/conseillers'})
            }
        },
    },
    async mounted() {
        const societyResponse = await axios.get(this.$store.getters.get_api_url +'crm/society/' + this.$route.params.id, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })
        this.society = societyResponse.data

        const directorResponse = await axios.get(this.$store.getters.get_api_url +'crm/director/' + this.$route.params.id, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })
        this.director = directorResponse.data
        
        this.getEvents()
        this.getRecommendations()

        this.selectedEvent = this.events[0] || {}
    }
}
</script>

<style scoped>
.client-frame {
    position: relative;
    padding: 40px;
}

.inframe-title {
    background-color: rgba(10,28,107,.7);
    color: white;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 15px;
    border-radius: 15px 0 15px 0;
}

.client-frame section {
    position: relative;
    border-radius: 15px;
    margin: 20px 0;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 4px rgb(0 0 0 / 5%)
}

.contact-frame {
    display: inline-block;
    min-width: 50%;
}

.contact-display {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.contact-column {
    flex-basis: 50%;
}

.contact-column .row {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.contact-column .row img{
    height: 30px;
    margin-right: 10px
}

.contact-column-left {
    border-right: 3px solid rgba(10,28,107,.7);
}

.delete-advisor {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgb(195, 2, 2);
    color: white;
    padding: 15px;
}
.delete-advisor:hover {
    background-color: red;
}

.btn-add-event {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    background-color: orange;
    color: white;
}

.society-infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.form-sect {
    flex-basis: 48%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-sect label, input {
    flex-basis: 50%;
    font-size: 14px;
    padding: 5px;
}

.business-frame {
    display: flex;
    justify-content: space-between;
}

.business-frame div, table {
    background-color: white;
    box-shadow: 0 3px 4px rgb(0 0 0 / 5%)
}

.business-list {
    border-radius: 10px;
    padding: 10px 0;
    flex-basis: 30%;
}

.business-list table {
    border-collapse: collapse;
    width: 100%;
}

.business-list table td {
    cursor: pointer;
    border: 1px solid rgb(244, 241, 241);
    font-size: 14px;
    text-align: center;
}
.business-list table tr:hover {
    background-color: rgb(244, 244, 244);
}

.event-icon {
    height: 30px;
}

.delete-img {
    height: 20px;
    align-self: center;
}

.business-detail {
    border-radius: 10px;
    flex-basis: 65%;
    min-height: 300px;
}

.datas-forms {
    display: flex;
    justify-content: space-evenly;
}

.new-event-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.new-event-form label, input {
    flex-basis: 45%;
    margin: 10px 0;
    padding: 5px;
}
.new-event-form textarea, select {
    flex-basis: 100%;
    margin: 10px 0;
    padding: 5px;
}

.new-event-form textarea {
    min-height: 400px;
}

.colum-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.column-datas {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    min-width: 400px;
        box-shadow: 0 3px 4px rgb(0 0 0 / 5%)
}

.datas-forms {
    display: flex;
    flex-direction: column;
}

.datas-sect {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.btn-add-reco {
    cursor: pointer;
    position: absolute;
    right: 10px;
    border-radius: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: white;
}
.btn-add-reco:hover {
    background-color: rgb(0, 0, 94);
}

.save-btn {
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    background-color: orange;
    padding: 10px;
    width: 100px;
    margin: 10px auto;
}
</style>