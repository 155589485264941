<template>
    <span>
        <svg :width="dimensionDefault" :height="dimensionDefault" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.25 0.507416H0.75C0.335156 0.507416 0 0.832416 0 1.23469V15.7801C0 16.1824 0.335156 16.5074 0.75 16.5074H20.25C20.6648 16.5074 21 16.1824 21 15.7801V1.23469C21 0.832416 20.6648 0.507416 20.25 0.507416ZM19.3125 3.0256V14.8711H1.6875V3.0256L1.04063 2.53696L1.96172 1.38923L2.96484 2.14605H18.0375L19.0406 1.38923L19.9617 2.53696L19.3125 3.0256ZM18.0375 2.14378L10.5 7.8256L2.9625 2.14378L1.95938 1.38696L1.03828 2.53469L1.68516 3.02333L9.69141 9.05969C9.92165 9.23314 10.2049 9.3273 10.4965 9.3273C10.7881 9.3273 11.0713 9.23314 11.3016 9.05969L19.3125 3.0256L19.9594 2.53696L19.0383 1.38923L18.0375 2.14378Z" fill="currentColor"></path></svg>
    </span>
</template>

<script>
export default {
    props: ['dimensions'],
    computed: {
        dimensionDefault() {
            if (this.dimensions) {
                return this.dimension
            } else {
                return 21
            }
        }
    }
}
</script>