<template>
    <div class="view-page">

        <div class="page-title">
            <person-icon></person-icon>
            <h1>{{user.firstname}} {{user.lastname}}</h1>
        </div>
        
        <div class="contact">
            <h1>Contact</h1>

            <div>
                <location-icon></location-icon>
                <span>15 rue Violet, 75015 Paris</span>
            </div>

            <div>
                <email-icon></email-icon>
                <span>basile.diot@gmail.com</span>
            </div>

            <div>
                <phone-icon></phone-icon>
                <span>06 95 15 19 63</span>
            </div>
        </div>

        <div class="actions">
            <div class="action-btn btn-infos" @click="openProfile">Accéder au profil</div>
            <div class="action-btn btn-delete" @click="deleteClient">Supprimer le client</div>
        </div>
        
        <div class="scenarios">
            <h1>Recommandations</h1>

            <table>
                <thead>
                    <td class="radius-up-left">Nom du scenario</td>
                    <td>Date de création</td>
                    <td class="radius-up-right">Actions</td>
                </thead>

                <tr v-for="reco in recommandationsIP" :key="reco._id">
                    <td> {{ recommendationsTypes[reco.type] }} </td>
                    <td> {{ dateToString(new Date()) }} </td>
                    <td class="scenario-btns">
                        <edit-icon style="cursor: pointer" @click="openScenarioOverview(reco._id)"></edit-icon>
                    </td>
                </tr>
            </table>
        </div>

        <div class="scenarios">
            <h1>Préconisations du conseiller</h1>

            <table>
                <thead>
                    <td class="radius-up-left">Nom du scenario</td>
                    <td>Date de création</td>
                    <td class="radius-up-right">Actions</td>
                </thead>

                <tr v-for="reco in recommandationsAdvisor" :key="reco._id">
                    <td> {{ recommendationsTypes[reco.type] }} </td>
                    <td> {{ dateToString(new Date()) }} </td>
                    <td class="scenario-btns">
                        <edit-icon style="cursor: pointer" @click="openScenarioOverview(reco._id)"></edit-icon>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            persons: [],
            originalScenarioId: undefined,
            recommendations: [],
            recommendationsTypes: {
                legacy: "Recommandation transmission",
                retirement: "Recommandation retraite",
                taxes_optimisation: "Recommandation fiscalité",
                wealth_development: "Recommandation développement du patrimoine",
                new_incomes: "Recommandation revenus complémentaires",
            }
        }
    },
    methods: {
        openProfile() {
            this.$router.replace('/client/' + this.$route.params.clientId + '/profile/' + this.originalScenarioId + '/couple')
        },
        async deleteClient() {
            const valid = confirm('Supprimer ce client ?')
            if (valid) {
                await axios.delete(this.$store.getters.get_api_url + 'clients/client/' + this.$route.params.clientId, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.$router.replace('/client')
            }
        },
        openScenarioOverview(recoId) {
            this.$router.replace('/client/' + this.$route.params.clientId + '/recommendation/' + recoId + '/overview/')
        },
        async getRecommendations() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/recommendation/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.recommendations = res.data
        },
    },
    computed: {
        user() {
            let result = this.persons.find(person => person.role == 'user')

            if (result) {
                return result

            } else {
                return {}

            }
        },
        recommandationsIP() {
            return this.recommendations.filter(r => !r.isClientRecommandation)
        },
        recommandationsAdvisor() {
            return this.recommendations.filter(r => r.isClientRecommandation)
        },
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'clients/scenario-original/' + this.$route.params.clientId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.originalScenarioId = res.data._id

        this.getRecommendations()
        
        const persons_response = await axios.get(this.$store.getters.get_api_url + 'clients/person/' + this.originalScenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.persons = persons_response.data
    }
}
</script>

<style scoped>
.view-page {
    padding: 20px 5%;
}  
.page-title {
    color: rgb(30,44,88);
    display: flex;
    align-items: center;
}
.page-title h1 {
    margin: 0 10px;
    padding: 15px 0;
    border-bottom: solid 1px lightgrey;
}

.contact {
    margin: 20px 0;
    padding: 70px 10px 20px 10px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    display: inline-block;
    border-radius: 20px;
}
.contact h1 {
    position: absolute;
    padding: 10px 20px;
    margin: 0;
    font-size: 25px;
    top: 0;
    left: 0;
    background-color: rgb(2, 2, 91);
    color: white;
    border-radius: 20px 0 20px 0;
}
.contact div {
    padding: 10px;
    display: flex;
    align-items: center;
}
.contact div span {
    margin-left: 10px;
}

.actions {
    width: 12%;
    display: flex;
    display: inline-block;
    flex-direction: column;
}
.action-btn {
    cursor: pointer;
    display: inline-block;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    min-width: 200px;
    text-align: center;
}
.btn-bilan {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: rgba(7, 2, 99, 0.989);
    color: white;
}
.btn-bilan:hover {
    background-color: rgba(7, 0, 135, 0.989);
    color: white;
}

.btn-download {
    cursor: pointer;
    background-color: rgba(255, 166, 0, 0.989);
    width: 100px;
    margin: 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
    align-self: center;
}
.btn-download:hover {
    background-color: rgba(7, 0, 135, 0.989);
    color: white;
    border-radius: 20px;
}
.btn-infos {
    background-color: rgba(255, 166, 0, 0.989);
}
.btn-infos:hover {
    background-color: rgba(238, 178, 65, 0.989);
}
.btn-delete {
    background-color: rgb(166, 0, 0);
    color: white;
}
.btn-delete:hover {
    background-color: rgb(221, 32, 32);
}

.scenarios {
    display: flex;
    flex-direction: column;
    position: relative;
}
.scenarios h1 {
    color: rgb(1, 1, 99);
    border-bottom: solid 1px lightgrey;
}
.btn-add-scenario {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 10px;
    background-color:rgb(255, 170, 0);
    padding: 10px;
    text-align: center;
    border-radius: 5px;
}
.btn-add-scenario:hover {
    background-color:rgb(1, 1, 106);
    color: white;
}
.scenarios table {
    border-collapse: collapse;
    background-color: white;
    font-size: 17px;
}

.scenarios thead td {
    background-color: rgb(30,44,88);
    color: white;
    padding: 10px;
    text-align: center;
    border: solid 1px lightgrey;
}
.scenarios tr td {
    color: rgb(93, 93, 93);
    padding: 10px;
    border: solid 1px lightgrey;
}
.radius-up-left {
    border-radius: 10px 0 0 0;
}
.radius-up-right {
    border-radius: 0 10px 0 0;
}
.scenario-btns {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-access {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: rgb(255, 170, 0);
    color: white;
}
.btn-access:hover {
    background-color: rgb(1, 1, 85);
}
</style>