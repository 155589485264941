<template>
     <section>
        <select v-model="selectedInheritorId">
            <option v-for="i in inheritors" :value="i._id" :key="i._id">{{ i.firstname }} {{ i.lastname }} - {{ $store.getters.allRoles[i.role] }}</option>
        </select>

        <div v-if="inheritor?.role == 'spouse' && ['married', 'pacs'].includes( $store.getters.getContract.situation )">
            <p>En tant que {{ $store.getters.allRoles[inheritor?.role] }}, {{ inheritor?.firstname }} {{ inheritor?.lastname }} n'est soumis à aucun impôt de succession.</p>
        </div>
        
        <div v-else>
            <p>La part imposable reçue par {{ inheritor?.firstname }} {{ inheritor?.lastname }} est de <b>{{ toEuro(inheritor?.part_fiscale) }}</b>.</p>
            
            <section v-if="inheritor?.gift_history.length > 1">
                <p>Il convient de tenir compte des donations reçues par {{ inheritor?.firstname }} dans le cadre du rappel fiscal des donations :</p>

                <div class="history" v-for="gh in giftHistory" :key="gh._id">
                    <p>{{ $store.getters.giftTypes[gh.type] }} de {{ toEuro(gh.value) }} du {{ dateToString(gh.date) }}</p>
                    <table>
                        <tr>
                            <th>Tranche du barème de DMTG</th>
                            <th>Disponible</th>
                            <th>Utilisé</th>
                        </tr>

                        <tr v-if="gh.dutreil_reduction > 0">
                            <th>Abattement Dutreil</th>
                            <td></td>
                            <td>{{ toEuro(gh.dutreil_reduction) }}</td>
                        </tr>

                        <tr v-if="gh.reduction_available_790G > 0">
                            <th>Abattement 790G du CGI</th>
                            <td>{{ toEuro(gh.reduction_available_790G) }}</td>
                            <td>{{ toEuro(gh.used_reduction_790G) }}</td>
                        </tr>

                        <tr>
                            <th>Abattement légal</th>
                            <td>{{ toEuro(gh.reduction_available) }}</td>
                            <td>{{ toEuro(gh.used_reduction) }}</td>
                        </tr>

                        <tr v-for="(scale, index) in gh.used_scales" :key="index">
                            <th>Tranche à {{ scale.rate * 100 }}%</th>
                            <td>{{ toEuro(scale.available) }}</td>
                            <td>{{ toEuro(scale.used) }}</td>
                        </tr>
                    </table>
                </div>
            </section>

            <p>Les DMTG à payer par {{ inheritor?.firstname }} {{ inheritor?.lastname }} seront de <b>{{ toEuro(inheritor?.tax) }}</b>, le calcul sera le suivant :</p>

            <table>
                <tr>
                    <th>Tranche du barème de DMTG</th>
                    <th>Disponible</th>
                    <th>Utilisé</th>
                </tr>

                <tr>
                    <th>Abattement légal</th>
                    <td>{{ toEuro(legacyTaxes?.reduction_available) }}</td>
                    <td>{{ toEuro(legacyTaxes?.used_reduction) }}</td>
                </tr>

                <tr v-for="(scale, index) in legacyTaxes?.used_scales" :key="index">
                    <th>Tranche à {{ scale.rate * 100 }}%</th>
                    <td>{{ toEuro(scale.available) }}</td>
                    <td>{{ toEuro(scale.used) }}</td>
                </tr>
            </table>
        </div>

    </section>
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
            selectedInheritorId: undefined,
        }
    },
    computed: {
        inheritors() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.inheritors
            } else {
                return this.legacyResult.first_death.inheritors
            }
        },
        inheritor() {
            return this.inheritors.find(i => i._id == this.selectedInheritorId)
        },
        giftHistory() {
            return this.inheritor?.gift_history.filter(gh => gh._id.substring(0, 6) != 'legacy')
        },
        legacyTaxes() {
            return this.inheritor?.gift_history.find(gh => gh._id.substring(0, 6) == 'legacy')
        }
    },
    mounted() {
        this.selectedInheritorId = this.inheritors[0]?._id
    }
}
</script>

<style scoped src="./style.css"></style>