<template>
    <div v-if="show_alert">    
        <teleport to="body">
            <div class="alert-frame" :class="{'error-style': isError, 'validation-style': !isError}">
                <div class="icon">
                    <div class="error-icon" v-if="isError">X</div>
                    <div class="valid-icon" v-else></div>
                </div>
                <div>{{ msg }}</div>
            </div>
        </teleport>
    </div>
</template>

<script>
export default {
    props: ['msg', 'isError'],
    data() {
        return {
            show_alert: false,
        }
    },
    watch: {
        msg() {
            if (this.msg != "") {
                this.show_alert = true
                setTimeout(()=> {
                    this.show_alert = false
                    if (this.isError) {
                        this.$store.commit("changeErrorMsg", "")
                    } else {
                        this.$store.commit("changeValidationMsg", "")
                    }
                },3000)
            }
        }
    }
}
</script>

<style>
.alert-frame {
    z-index: 10000;
    min-width: 250px;
    padding: 20px;
    position: fixed;
    top: 100px;
    right: 20px;
    text-align: right;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
}
.validation-style {
    border: 3px solid green;
    background-color: rgb(219, 249, 219);
}
.error-style {
    border: 3px solid rgb(154, 2, 2);
    background-color: rgb(255, 147, 147);
}
.error-icon {
    color: rgb(154, 2, 2);
    margin-right: 15px;
    height: 22px;
    width: 15px;
    font-size: 28px;
    font-weight: bold;
}

.valid-icon {
    margin-right: 15px;
    height: 22px;
    width: 15px;
    border-bottom: 5px solid green;
    border-right: 5px solid green;
    transform: rotate(35deg);
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%); /* Commencer depuis la droite de l'écran */
    opacity: 0; /* Commencer avec un élément complètement transparent */
  }
  to {
    transform: translateX(0); /* Terminer à sa position normale */
    opacity: 1; /* Terminer avec un élément complètement opaque */
  }
}

/* Appliquer l'animation à .alert-frame */
.alert-frame {
  animation: slideInFromRight 0.5s ease-out forwards; /* Nom de l'animation, durée, fonction d'accélération, et 'forwards' pour garder l'état final */
}
</style>