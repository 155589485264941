<template>
    <form class="add-form">
        <div class="form-section">
            <label for="type">Type d'emprunt</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.debtTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="owner">Emprunteur</label>
            <select id="owner" v-model="owner">
                <option v-for="value in subscribers_list" :key="value._id" :value="value._id">{{ value.name }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Montant emprunté</label>
            <input type="number" v-model="body.value">
        </div>

        <div class="form-section">
            <label for="start">Date de début</label>
            <input id="start" type="date" v-model="body.start_date">
        </div>

        <div class="form-section">
            <label for="end">Date de fin</label>
            <input id="end" type="date" v-model="body.end_date">
        </div>

        <div class="form-section">
            <label for="rate">Taux (%)</label>
            <input id="rate" type="number" v-model="body.rate" step=0.01>
        </div>

        <div class="form-section" v-if="body.type == 'property_collateral_loan'">
            <label for="property">Contrat nanti</label>
            <select id="property" v-model="body.contract_as_collateral">
                <option v-for="insurance in $store.getters.getAllLifeInsurances" :key="insurance._id" :value="insurance._id">{{ insurance.label || $store.getters.insuranceTypes[insurance.type] }} - {{ $store.getters.findPersonName(insurance.owner1) }} - {{ toEuro(insurance.value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="rate">Taux d'assurance (%)</label>
            <input id="rate" type="number" v-model="body.insurance_rate" step=0.01>
        </div>

        <div class="form-section">
            <label for="refund">Remboursement</label>
            <select id="refund" v-model="body.refund_type">
                <option v-for="(value, key) in $store.getters.refundTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="payment">Mensualité</label>
            <input id="payment" type="text" :value="monthlyPayment" disabled>
        </div>

        <div class="form-section">
            <label for="payment">Capital restant dû</label>
            <input id="payment" type="text" :value="toEuro(remainingRefund)" disabled>
        </div>

        <div class="form-section" v-if="body.type == 'property_loan' || body.type == 'property_collateral_loan'">
            <label for="property">Bien financé </label>
            <select id="property" v-model="body.property_id">
                <option v-for="property in $store.getters.getAllProperties" :key="property._id" :value="property._id">{{ $store.getters.propertyTypesList[property.type] }} - {{ toEuro(property.value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="payment">Assurance 1</label>
            <input id="payment" type="number" v-model="body.insurance_owner1" :disabled="['insured_loan', 'property_loan'].includes(body.type) && !body.owner2">
        </div>

        <div class="form-section">
            <label for="payment">Assurance 2</label>
            <input id="payment" type="number" v-model="body.insurance_owner2">
        </div>
        
        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            owner: undefined,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: undefined,
                value: 0,
                remaining_value: 0,
                owner1: undefined,
                owner2: undefined,
                refund_type: "amortization",
                insurance_owner1: 0,
                insurance_owner2: 0,
                start_date: new Date(),
                end_date: undefined,
                rate: 4,
                insurance_rate: 0,
                property_id: undefined,
                contract_as_collateral: undefined,
                isIpCreation : false
            },
            loanResult: {}
        }
    },
    watch: {
        'body.type': {
            handler(_newValue, oldValue) {
                if (oldValue != undefined) { // Le watcher ne s'applique pas lors du chargement de l'élément pendant le mount
                    if (this.body.type == 'property_loan') {
                        if (this.body.owner2) {
                            this.body.insurance_owner1 = 50
                            this.body.insurance_owner2 = 50
                        } else {
                            this.body.insurance_owner1 = 100
                        }
                        
                    } else {
                        this.body.insurance_owner1 = 0
                    }
                }
            }
        },
        owner() {
            if (this.owner == 'common') {
                this.body.owner1 = this.$store.getters.userId
                this.body.owner2 = this.$store.getters.spouseId
            } else {
                this.body.owner1 = this.owner
                this.body.owner2 = null
            }
        },
        body: {
            deep: true,
            handler() {
                this.computeLoan()
            }
        },
        loanResult() {
            this.body.remaining_value = this.remainingRefund
        }
    },
    computed: {
        current_refund() {
            const month_spent = this.nbMonth(this.body.start_date, new Date())

            const amortization_table = this.loanResult?.amortization_table

            const current_refund = amortization_table?.[month_spent]

            return current_refund
        },
        monthlyPayment() {
            return this.toEuro(this.current_refund?.monthly_payment)
        },
        remainingRefund() {
            return this.current_refund?.crd_before
        },
        subscribers_list() {
            let result = []
            result.push({ _id: this.$store.getters.userId, name: this.$store.getters.userName })

            if (this.$store.getters.hasSpouse) {
                result.push({ _id: this.$store.getters.spouseId, name: this.$store.getters.spouseName })
                result.push({ _id: 'common', name: 'Commun' })
            }

            this.$store.getters.getAllSocieties.forEach(society => {
                result.push({ _id: society._id, name: society.type.toUpperCase() + ' - ' + society.label })
            });

            return result
        }
    },
    methods: {
        async computeLoan() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-simulation', {
                ...this.body,
                rate: this.body.rate / 100,
                insurance_rate: this.body.insurance_rate / 100
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.loanResult = res.data
        },
        submit() {
            if (this.isNew) {
                const routeArray = this.$route.fullPath.split('/')
                if (routeArray[3] != 'profile') {
                    this.body.isIpCreation = true
                }
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de dette"
            } else if (this.body.value == 0) {
                this.errorMsg = "Renseignez une valeur supérieure à 0€"
            } else if (this.body.owner1 == undefined) {
                this.errorMsg = "Précisez le souscripteur"

            } else if (this.body.start_date == undefined) {
                this.errorMsg = "Précisez la date de souscription"
                
            } else if (this.body.end_date == undefined) {
                this.errorMsg = "Précisez la date de fin"
                
            } else if (this.body.rate == undefined) {
                this.errorMsg = "Précisez le taux d'intérêts"
                
            } else if (this.body.type == 'property_collateral_loan' && this.body.contract_as_collateral == undefined) {
                this.errorMsg = "Précisez le contrat nanti pour garantir ce prêt"
                
            } else {
                if (this.isNew) {
                    this.body.payment = this.payment
                    this.errorMsg = ""
                    this.body.addToStore = true
                    this.$store.dispatch('create_debt', this.body)

                } else {
                    this.$store.dispatch('action_modify_debt', this.body)
                }

                this.$emit('closeModale')
                
            }
        },
    },
    mounted() {
        if (this.import) {
            this.isNew = false         
            this.body = JSON.parse(JSON.stringify(this.import))

            this.body.start_date = this.body.start_date?.slice(0, 10)
            this.body.end_date = this.body.end_date?.slice(0, 10)

            if (this.body.owner1 && this.body.owner2) {
                this.owner = 'common'
            } else {
                this.owner = this.body.owner1
            }

            this.computeLoan()
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>
