<template>
    <span>
        <svg :width="dimensionDefault" :height="dimensionDefault" viewBox="0 0 25 24" fill="none"><path d="M12.5 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" fill="currentColor"></path><path d="M11.92 21.814a.998.998 0 001.16 0c.304-.215 7.45-5.374 7.42-11.814 0-4.411-3.589-8-8-8-4.41 0-8 3.589-8 7.995-.029 6.445 7.116 11.604 7.42 11.819zM12.5 4c3.31 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.61-1.308-6.02-5.294-6-9.735 0-3.309 2.691-6 6-6z" fill="currentColor"></path></svg>
    </span>
</template>

<script>
export default {
    props: ['dimensions'],
    computed: {
        dimensionDefault() {
            if (this.dimensions) {
                return this.dimension
            } else {
                return 25
            }
        }
    }
}
</script>