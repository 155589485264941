<template>
    <label class="switch">
            <input type="checkbox" v-model="bool" @click="sendValue">
            <span class="slider round"></span>
        </label>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            bool: false
        }
    },
    methods: {
        sendValue() {
            this.bool = !this.bool
            this.$emit('toggle', this.bool)
        }
    },
    beforeUpdate() {
        this.bool = this.value
    },
    mounted() {
        this.bool = this.value
    }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CCC;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgb(255, 136, 0); 
}

input:focus + .slider {
    box-shadow: 0 0 1px rgb(255, 136, 0); 
}

input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>