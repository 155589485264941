<template>
    <div class="liquidity-frame">
        <div class="indicator-frame">
            <div class="flex-container" @click="toggle">
                <span>Liquidités à réinvestir : </span>
                <div class="liquidity-value">{{ toEuro(liquidities) }}</div>
            </div>

            <table class="action-list" v-if="showTable">
                <tr v-for="(action, index) in $store.getters.getActionList" :key="index">
                    <td>{{ $store.getters.codeTranslations[action.code] }}</td>
                    <td>{{ toEuro(action.liquidities) }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showTable: false
        }
    },
    methods: {
        toggle() {
            this.showTable = !this.showTable
        }
    },
    computed: {
        liquidities() {
            return this.$store.getters.getActionList.filter(a => !['DELETE_CCA_ASSETS', 'CREATION_CCA_ASSETS', 'INVESTMENT_CCA_ASSETS', 'SELL_CCA_ASSETS'].includes(a.code)).map(a => a.liquidities).reduce((sum, current) => sum + current, 0)
        }
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url + 'referential/actions/'+ this.$route.params.scenarioId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.$store.commit('modifyActionList', res.data)
        this.actions = res.data
    }
}
</script>

<style>
.liquidity-frame {
    padding: 20px 5%;
    display: flex;
    justify-content: right;
}
.indicator-frame {
    position: relative;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.flex-container {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.flex-container span {
    font-weight: bold;
    color: rgb(0, 0, 126);
    margin: 0 10px;
}
.liquidity-value {
    padding: 10px;
    min-width: 100px;
    background-color: rgb(243, 243, 243);
    text-align: right;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.action-list {
    font-size: 12px;
    margin-top: 20px;
    border-collapse: collapse;
}
.action-list td {
    padding: 5px;
    border: 1px solid rgb(243, 245, 247);
}
.action-list tr td:nth-child(2) {
    text-align: right;
}
</style>