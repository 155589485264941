<template>
    <draggable class="dragArea list-group w-full" v-model="list" :group="groupName">
        <transition-group>
            <slot></slot>
        </transition-group>
    </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    components: {
        draggable: VueDraggableNext,
    },
    props: ['importedList', 'groupName'],
    data() {
        return {
            list: []
        }
    },
    watch: {
        importedList() {
            this.list = this.importedList
        }
    }
}
</script>

<style scoped>

</style>