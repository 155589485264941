<template>
    <div class="input-value">
        <select v-model="option">
            <option value="+">+</option>
            <option value="-">-</option>
        </select>

        <input type="number" v-model="value">
    </div>
</template>

<script>
export default {
    data() {
        return {
            option: '+',
            value: undefined,
        }
    },
    watch: {
        value() {
            let value = this.value
            if (this.option == '-') {
                value = value * -1
            }
            
            this.$emit('valueUpdate', value)
        }
    }
}
</script>

<style scoped>
.input-value {
    display: flex;
    flex: 1 1 auto; 
}

.input-value select {
    flex-basis: 20px;
    border-radius: 0;
    flex: 0 0 auto;
}
.input-value input {
    border-radius: 0;
    text-align: right;
    flex: 1 1 auto;
}
</style>