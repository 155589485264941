<template>
     <section>
        <p>Lorsque tous les biens du défunt n'ont pas été légués, la dévolution légale s'applique. C'est elle qui détermine qui sont les héritiers d'une personne en fonction de la composition de sa famille.</p>
        <p>Les donations faites à un héritier présomptif (qui a vocation a reçevoir l'héritage au moment de la donation selon la dévolution légale), sont présumées faites en avancement de part successorale lorsqu'elles n'ont pas été expressement stipulées "hors part". On considère qu'une donation faite en avance de part est une simple avance sur héritage et que le donateur n'a pas la volonté d'avantager le donataire par rapport aux autres héritiers présomptif. Ces donations sont rapportables à la succession pour leur valeur au jour du décès (comme si les biens donnés étaient restés dans le patrimoine du donateur).</p>
        <p>Il convient donc de reconstituer une masse successorale :</p>

        <ul>
            <li>Masse successorale = Biens existants + donations en avancement de part successorale - Legs </li>
            <li>Masse successorale = {{ toEuro(existingGoodsValue) }} + {{ toEuro(reportableGiftsValue) }} - {{ toEuro(legsValue) }}</li>
            <li>Masse successorale = <b>{{ toEuro(reportMass) }}</b></li>
        </ul>

        <table>
            <tr>
                <th>Dévolution légale</th>
                <th>Relation</th>
                <th>Part d'héritage prévue par la loi</th>
                <th>Valeur de la part</th>
                <th>Montant reçu en avancement de part</th>
                <th>Héritage au décès</th>
            </tr>

            <tr v-for="inheritor in devolutionParts" :key="inheritor._id">
                <td>{{ inheritor.full_name }}</td>
                <td>{{ inheritor.relation }}</td>
                <td>{{ Math.round(inheritor.devolution_part * 1000) / 10 }} %</td>
                <td>{{ toEuro(inheritor.devolution_part * reportMass) }}</td>
                <td>{{ toEuro(inheritor.aps_received) }}</td>
                <td>{{ toEuro(inheritor.part_value.NP) }}</td>
            </tr>
        </table>
     </section>
</template>

<script>
export default {
    props: ['legacyResult', 'isSecondDeath'],
    data() {
        return {
            BOOLEAN_TRAD: {
                true: 'OUI',
                false: 'NON',
            },
        }
    },
    computed: {
        existingGoodsValue() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.datas.biens_existants.US
            } else {
                return this.legacyResult.first_death.datas.biens_existants.US
            }
        },
        reportableGiftsValue() {
            if (this.isSecondDeath) {
                return this.legacyResult.second_death.legacy_masses.reportable_gifts
            } else {
                return this.legacyResult.first_death.legacy_masses.reportable_gifts
            }
        },
        legsValue() {
            if (this.isSecondDeath) {
                return Math.max(this.legacyResult.second_death.will_result.bequest_granted.US, this.legacyResult.second_death.will_result.bequest_granted.NP)
            } else {
                return Math.max(this.legacyResult.first_death.will_result.bequest_granted.US, this.legacyResult.first_death.will_result.bequest_granted.NP)
            }
        },
        reportMass() {
            if (this.isSecondDeath) {
                return Math.min(this.legacyResult.second_death.legacy_masses.report_mass.US, this.legacyResult.second_death.legacy_masses.report_mass.NP)
            } else {
                return Math.min(this.legacyResult.first_death.legacy_masses.report_mass.US, this.legacyResult.first_death.legacy_masses.report_mass.NP)
            }
        },
        devolutionParts() {
            let result = []
            if (this.isSecondDeath) {
                const devolution_parts = this.legacyResult.second_death.devolution.devolution_parts
                Object.keys(devolution_parts).forEach(inheritorId => {
                    const inheritor = this.legacyResult.second_death.devolution.inheritors.find(i => i._id == inheritorId)
                    result.push({
                        _id: inheritorId,
                        full_name: this.$store.getters.findPersonName(inheritorId),
                        devolution_part: devolution_parts[inheritorId],
                        part_value: inheritor.devolution_part,
                        aps_received: inheritor.aps_gift,
                        relation: this.$store.getters.allRoles[inheritor.role],
                    })
                });
                
            } else {
                const devolution_parts = this.legacyResult.first_death.devolution.devolution_parts
                Object.keys(devolution_parts).forEach(inheritorId => {
                    const inheritor = this.legacyResult.first_death.devolution.inheritors.find(i => i._id == inheritorId)
                    result.push({
                        _id: inheritorId,
                        full_name: this.$store.getters.findPersonName(inheritorId),
                        devolution_part: devolution_parts[inheritorId],
                        part_value: inheritor.devolution_part,
                        aps_received: inheritor.aps_gift,
                        relation: this.$store.getters.allRoles[inheritor.role],
                    })
                });
            }

            return result
        }
    },
}
</script>

<style scoped src="./style.css"></style>